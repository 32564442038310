import _ from 'lodash';
import moment from 'moment';
export function handleCRUDAction(state, action) {
  var newState = {};

  if (action.error !== undefined) {
    newState = _.assign({}, state, {
      error: action.error
    });
    return newState;
  }

  newState = _.assign({}, state, {
    success: true
  });
  return newState;
}
export function getQueryString() {
  var _this$state = this.state,
      skip = _this$state.skip,
      limit = _this$state.limit;
  var query = {
    skip: skip,
    limit: limit,
    sort: this.state.is_desc ? "-".concat(this.state.order_by) : this.state.order_by
  };
  return query;
}
export function handleNext() {
  if (this.props.exports.length < this.state.limit) {
    return;
  }

  var skip = this.state.skip + this.state.limit;
  this.setState({
    skip: skip
  }, this.getData);
}
export function handlePrev() {
  if (!this.state.skip) {
    return;
  }

  var skip = this.state.skip - this.state.limit;
  this.setState({
    skip: skip
  }, this.getData);
}
export function hideModal() {
  var modal = document.querySelector('.modal-sort');
  modal.classList.remove('show');
  document.querySelector('body').classList.remove('unscrollable');
}
export function sortByField(field, isModalSort) {
  if (isModalSort) hideModal();
  this.setState({
    skip: 0,
    order_by: field,
    is_desc: this.state.order_by === field ? !this.state.is_desc : true
  }, this.getData);
}
export function handleChangePageSize(pageSize) {
  this.setState({
    limit: parseInt(pageSize, 10),
    skip: 0
  }, this.getData);
}
export var checkDuplicated = function checkDuplicated(items, key) {
  var itemValues = items.map(function (value) {
    return value[key];
  });
  return itemValues.some(function (item, index) {
    return itemValues.indexOf(item) !== index;
  });
};
export var updateServiceLinesToEstimate = function updateServiceLinesToEstimate(stateEstimate, actionEstimate) {
  actionEstimate.vehicles.forEach(function (element) {
    var currentVehicle = stateEstimate.vehicles.filter(function (vehicle) {
      return vehicle._id === element._id;
    });

    if (currentVehicle && currentVehicle.length > 0) {
      element.serviceLines = currentVehicle[0].serviceLines;
    }
  });
  actionEstimate.taxPartsAmount = stateEstimate.taxPartsAmount;
  actionEstimate.taxLaborAmount = stateEstimate.taxLaborAmount;
  actionEstimate.discountAmount = stateEstimate.discountAmount;
  return actionEstimate;
};
export function downloadLink(fileName, link) {
  var a = document.createElement('a');
  a.style = 'display: none';
  a.href = link;
  document.body.appendChild(a);
  a.setAttribute('download', fileName);
  a.setAttribute('target', '_blank');
  a.click();
  document.body.removeChild(a);
}
export function calculateTimer(services, stages, lastStage) {
  var CURRENT_DATE = new Date();
  var totalTimeMS = 0;
  services.forEach(function (service) {
    var _service$timeKeeping, _service$timeKeeping2;

    var totalMS = ((_service$timeKeeping = service.timeKeeping) === null || _service$timeKeeping === void 0 ? void 0 : _service$timeKeeping.totalMS) || 0;

    if ((_service$timeKeeping2 = service.timeKeeping) === null || _service$timeKeeping2 === void 0 ? void 0 : _service$timeKeeping2.status) {
      var _service$timeKeeping3, _service$timeKeeping4;

      totalMS += moment(CURRENT_DATE).diff(moment(((_service$timeKeeping3 = service.timeKeeping) === null || _service$timeKeeping3 === void 0 ? void 0 : _service$timeKeeping3.end) || ((_service$timeKeeping4 = service.timeKeeping) === null || _service$timeKeeping4 === void 0 ? void 0 : _service$timeKeeping4.start)));
    } else {
      totalMS += 0;
    }

    totalTimeMS += totalMS;
  });
  var totalStageMS = stages.reduce(function (total, stage) {
    var _stage$timeKeeping, _stage$stage;

    var totalMS = ((_stage$timeKeeping = stage.timeKeeping) === null || _stage$timeKeeping === void 0 ? void 0 : _stage$timeKeeping.totalMS) || 0;
    var isLastStage = lastStage === (stage === null || stage === void 0 ? void 0 : (_stage$stage = stage.stage) === null || _stage$stage === void 0 ? void 0 : _stage$stage._id);

    if (stage.active && !isLastStage) {
      var _stage$timeKeeping2;

      return total + totalMS + moment(CURRENT_DATE).diff(moment((_stage$timeKeeping2 = stage.timeKeeping) === null || _stage$timeKeeping2 === void 0 ? void 0 : _stage$timeKeeping2.start));
    }

    if (isLastStage) {
      return total + 0;
    }

    return total + totalMS;
  }, 0);
  return {
    services: services,
    totalTimeMS: totalTimeMS,
    totalStageMS: totalStageMS
  };
}
export function updateTimerStatus(services) {
  return {
    services: services
  };
}