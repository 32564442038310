import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import HttpRequest from "../utils/HttpRequest";
import * as routesUtils from "../utils/RoutesUtils";
import { API } from "../core/app.routes";
export function addLeadsEstimate(leadID) {
  var path = routesUtils.getApiPath(API.LEADS_ESTIMATE, {
    leadID: leadID
  });
  return HttpRequest.post(path);
}
export function getEstimates(params) {
  var path = routesUtils.getApiPath(API.ESTIMATES);
  return HttpRequest.post(path, params);
}
export function getEstimate(params) {
  var path = routesUtils.getApiPath(API.ESTIMATE_DETAIL, params);
  return HttpRequest.get(path);
}
export function sendEstimateMail(params) {
  var path = routesUtils.getApiPath(API.SEND_ESTIMATE_EMAIL, params);
  return HttpRequest.post(path, params);
}
export function getEmails(params) {
  var path = routesUtils.getApiPath(API.EMAIL, params);
  return HttpRequest.get(path);
}
export function exportEstimates(data) {
  var path = routesUtils.getApiPath(API.EXPORT_ESTIMATES);
  return HttpRequest.post("".concat(path, "?format=").concat(data.type), {
    estimates: data.estimates
  });
}
export function exportEstimatesCSV(body) {
  var params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var path = routesUtils.getApiPath(API.EXPORT_ESTIMATES, params);
  return HttpRequest.post(path, body);
}
export function exportEstimatesVehiclesCSV(body) {
  var params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var path = routesUtils.getApiPath(API.EXPORT_ESTIMATES, params);
  return HttpRequest.post(path, body);
}
export function eracmakezip(body) {
  var path = routesUtils.getApiPath(API.ERAC_MAKE_ZIP);
  return HttpRequest.post(path, body);
}
export function eraccheckstatus(body) {
  var path = routesUtils.getApiPath(API.ERAC_CHECK_STATUS);
  return HttpRequest.post(path, body);
}
export function downloadPDF(estimateID) {
  var params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var path = routesUtils.getApiPath(API.CALL_PRINTER_ESTIMATE, _objectSpread(_objectSpread({}, {
    estimateID: estimateID
  }), params));
  return HttpRequest.get("".concat(path));
}
export function downloadSupplementAndPartPDF(estimateID, isSupplement) {
  var params = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  var path = routesUtils.getApiPath(isSupplement ? API.CALL_PRINTER_SUPPLEMENT_ESTIMATE : API.CALL_PRINTER_PART_ESTIMATE, _objectSpread(_objectSpread({}, {
    estimateID: estimateID
  }), params));
  return HttpRequest.get("".concat(path));
}
export function getJobId(id) {
  var path = routesUtils.getApiPath(API.GET_JOB);
  return HttpRequest.get(path + id);
}
export function editEstimate(estimate) {
  var path = routesUtils.getApiPath(API.ESTIMATE_DETAIL, {
    estimateID: estimate._id
  });
  return HttpRequest.put(path, estimate);
}
export function getIncomingEstimate(params) {
  var path = routesUtils.getApiPath(API.INCOMING_ESTIMATE, params);
  return HttpRequest.get(path);
}
export function pdfPolling(path) {
  return HttpRequest.get(path);
}
export function getEstimateDetail(estimateId) {
  var path = routesUtils.getApiPath(API.ESTIMATE_DETAIL, {
    estimateID: estimateId
  });
  return HttpRequest.get(path);
}
export function updateVehicleEstimate(estimateId, vehicleId, vehicle) {
  var path = routesUtils.getApiPath(API.UPDATE_VEHICLE_ESTIMATE, {
    estimateID: estimateId,
    vehicleID: vehicleId
  });
  return HttpRequest.put(path, vehicle);
}
export function updateInvoice(invoice) {
  var path = routesUtils.getApiPath(API.ESTIMATES);
  return HttpRequest.put(path, invoice);
}