import HttpRequest from "../utils/HttpRequest";
import * as routesUtils from "../utils/RoutesUtils";
import { API } from "../core/app.routes";
export function postWorkOrders(params) {
  var path = routesUtils.getApiPath(API.WORK_ORDER_GET_ALL);
  return HttpRequest.post(path, params);
}
export function getPDF(params) {
  var path = routesUtils.getApiPath(API.WORK_ORDER_GET_PDF, params);
  return HttpRequest.get("".concat(path, "?background=true"));
}
export function sendWorkOrderMail(params) {
  var path = routesUtils.getApiPath(API.WORK_ORDER_SEND_EMAIL, params);
  return HttpRequest.post(path, params);
}
export function createWorkOrderFromEstimate(estimate) {
  var path = routesUtils.getApiPath(API.WORK_ORDER);
  return HttpRequest.post(path, {
    estimate: {
      _id: estimate._id
    }
  });
}
export function updateWorkOrder(workOrder) {
  var path = routesUtils.getApiPath(API.WORK_ORDER);
  return HttpRequest.post(path, workOrder);
}
export function editWorkOrder(workOrder) {
  var path = routesUtils.getApiPath(API.WORK_ORDER);
  return HttpRequest.put("".concat(path, "/").concat(workOrder._id), workOrder);
}
export function getWorkorderDetail(workorderID) {
  var path = routesUtils.getApiPath(API.WORK_ORDER_GET_DETAIL, {
    workorder_id: workorderID
  });
  return HttpRequest.get(path);
}