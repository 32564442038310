var uiKit = {};

uiKit.showModal = function (selector) {
  var showBackdrop = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
  var modal = document.querySelector(selector);
  modal.classList.add('show');

  if (showBackdrop === true) {
    var backdrop = document.querySelector('.backdrop');
    backdrop.classList.add('show');
  }
};

uiKit.hideModal = function (selector) {
  var modal = document.querySelector(selector);
  modal.classList.remove('show');
  var backdrop = document.querySelector('.backdrop');
  backdrop.classList.remove('show');
};

uiKit.hideModals = function () {
  var modals = document.querySelectorAll('.modal');
  modals.forEach(function (modal) {
    modal.classList.remove('show');
  });
  var backdrop = document.querySelector('.backdrop');
  backdrop.classList.remove('show');
};

window.uiKit = uiKit;
export default uiKit;