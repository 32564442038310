import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import _, { zip } from 'lodash';
import * as types from "../core/actionTypes"; // import { getFormattedDateTime } from '../utils/dates';

import * as userServices from "../services/userServices";
import { catchError } from "../core/error.handler";
import toast from "../components/libraries/toast";
import { PERMISSION, USER_STATUS, USER_ROLES } from "../core/constants";
export var mapFromUsersDto = function mapFromUsersDto(users, session) {
  return _.map(users, function (user) {
    return mapFromUserDto(user, session);
  });
};
export var mapFromUserDto = function mapFromUserDto(user, session) {
  user.name = user.name || ''; // ---- create short name

  var nameWords = _.split(user.name, ' ');

  var nameWordsLen = nameWords.length;

  if (nameWordsLen > 1) {
    user.shortname = _.toUpper("".concat(nameWords[0].charAt(0)).concat(nameWords[nameWordsLen - 1].charAt(0)));
  } else {
    user.shortname = nameWordsLen ? _.toUpper("".concat(nameWords[0].slice(0, 2))) : '';
  } // ---- end create short name


  user.role = isSubcontract(user, session) ? USER_ROLES.SUBCONTRACTOR : user.admin ? USER_ROLES.ADMIN : USER_ROLES.TECH;
  user.isSubcontract = isSubcontract(user, session);
  user.status = isActive(user, session) ? USER_STATUS.ACTIVE : USER_STATUS.INACTIVE;

  if (!user.isSubcontract) {
    user.access = _.capitalize(_.findKey(PERMISSION, function (item) {
      return item === _.get(user, 'permission.value');
    }).replace('_', ' '));
  }

  user.cp = user.comparative ? 'On' : 'Off';
  user.exports = _.get(user, 'permission.value') === PERMISSION.PREMIER || user.admin ? 'On' : 'Off';
  return user;
};
export var isSubcontract = function isSubcontract(user, session) {
  var _session$company = session.company,
      company = _session$company === void 0 ? {} : _session$company;

  if (!user || company._id === user.company || !user.subcontracts || user.subcontracts.length === 0) {
    return false;
  }

  var index = user.subcontracts.findIndex(function (subcon) {
    return subcon.company === company._id;
  });
  return index > -1;
};

var isActive = function isActive(user, session) {
  if (user.role !== USER_ROLES.SUBCONTRACTOR) {
    return user.active;
  }

  var _session$company2 = session.company,
      company = _session$company2 === void 0 ? {} : _session$company2;
  var index = user.subcontracts.findIndex(function (subcon) {
    return subcon.company === company._id;
  });
  return !!user.subcontracts[index].active;
};

var mapToUserDto = function mapToUserDto(user) {
  user.active = user.status === USER_STATUS.ACTIVE;
  user.location && (user.location.useCompany = user.useCompany);

  if (user.useCompany) {
    user.location = _objectSpread(_objectSpread({}, user.location), {
      useCompany: true
    }); // user.location = { useCompany: true };
  } else {
    user.location = {
      useCompany: false,
      phone: user.phone,
      fax: user.fax,
      shopAddress: {
        country: user.country,
        street: user.street,
        city: user.city,
        state: user.stateUser,
        zip: user.zip
      },
      mailingAddress: {
        country: user.countryMail,
        street: user.streetMail,
        city: user.cityMail,
        state: user.stateUserMail,
        zip: user.zipMail
      }
    };
  }

  return user;
}; // ------------- actions ----------------//


export var getUsers = function getUsers(query) {
  return function (dispatch) {
    dispatch({
      type: types.ACTION_REQUEST
    });
    return userServices.getUsers(query).then(function (result) {
      var users = mapFromUsersDto(result.data.users, result.session);
      var currentUser = result.session.user;
      dispatch({
        type: types.GET_CURRENT_USER_SUCCESS,
        currentUser: currentUser
      });
      dispatch({
        type: types.GET_USERS_SUCCESS,
        users: users
      });
      return result;
    })["catch"](function (error) {
      catchError(error, dispatch, types.REQUEST_USER_FAILURE);
    });
  };
};
export var getAssignableUsers = function getAssignableUsers(query) {
  return function (dispatch) {
    dispatch({
      type: types.ACTION_REQUEST
    });
    return userServices.getAssignableUsers(query).then(function (result) {
      var users = mapFromUsersDto(result.data.users, result.session); // Filter out non active subcontractors

      var assignableUsers = users.filter(function (person) {
        if (person.company !== result.session.company._id) {
          return person.subcontracts[0].active;
        }

        return person.active;
      });
      dispatch({
        type: types.GET_ASSIGNABLE_USERS_SUCCESS,
        assignableUsers: assignableUsers
      });
      return result;
    })["catch"](function (error) {
      catchError(error, dispatch, types.REQUEST_ASSIGNABLE_USERS_FAILURE);
    });
  };
};
export var getUserDetail = function getUserDetail(userId) {
  return function (dispatch, getState) {
    dispatch({
      type: types.ACTION_REQUEST
    });
    return userServices.getUserDetail(userId).then(function (result) {
      var userFound = _.find(getState().user.users, function (item) {
        return item._id === userId;
      });

      var user = mapFromUserDto(_.assign({}, result.data.user, {
        isSubcontract: userFound.isSubcontract
      }), result.session);
      dispatch({
        type: types.GET_USER_DETAIL_SUCCESS,
        user: user
      });
    })["catch"](function (error) {
      catchError(error, dispatch, types.REQUEST_USER_FAILURE);
    });
  };
};
export var editUser = function editUser(user, company) {
  return function (dispatch) {
    dispatch({
      type: types.ACTION_REQUEST
    });
    return userServices.editUser({
      user: user,
      company: company
    }).then(function (newUser) {
      dispatch({
        type: types.UPDATE_USER_SUCCESS
      });
      toast.success('Successfully Saved!');
      return newUser;
    })["catch"](function (error) {
      return catchError(error, dispatch, types.REQUEST_USER_FAILURE);
    });
  };
};
export var updateUser = function updateUser(user) {
  return function (dispatch) {
    return userServices.updateUser(user).then(function (result) {
      dispatch({
        type: types.UPDATE_USER_SUCCESS
      });
      return result;
    })["catch"](function (error) {
      catchError(error, dispatch, types.REQUEST_USER_FAILURE);
    });
  };
};
export var getUsersSync = function getUsersSync(params) {
  return function (dispatch) {
    dispatch({
      type: types.ACTION_REQUEST
    });
    return userServices.getUsersSync(params).then(function (result) {
      dispatch({
        type: types.GET_USERS_SUCCESS,
        users: result.data.users
      });
      return result.data.users;
    })["catch"](function (error) {
      catchError(error, dispatch, types.REQUEST_USER_FAILURE);
      return Promise.resolve([]);
    });
  };
};
export var resetEditSuccess = function resetEditSuccess() {
  return {
    type: types.REST_EDIT_SUCCESS
  };
};
export var addSubcontractor = function addSubcontractor(email) {
  return function (dispatch) {
    dispatch({
      type: types.ACTION_REQUEST
    });
    return userServices.addSubcontractor(email).then(function (result) {
      dispatch({
        type: types.ADD_USER_SUCCESS
      });
      toast.success('Subcontractor has been invited to join your company. They will be added after they accept the invitation.');
      return result;
    })["catch"](function (error) {
      catchError(error, dispatch, types.ADD_USER_FAILURE);
    });
  };
};
export var addNewUser = function addNewUser(user) {
  return function (dispatch) {
    dispatch({
      type: types.ACTION_REQUEST
    });
    return userServices.addNewUser(user).then(function () {
      toast.success('User has been invited to join your company. They will be added after they accept the invitation.');
      return dispatch({
        type: types.ADD_USER_SUCCESS
      });
    })["catch"](function (error) {
      return catchError(error, dispatch, types.ADD_USER_FAILURE);
    });
  };
};
export var reverify = function reverify(userId) {
  return function (dispatch) {
    dispatch({
      type: types.ACTION_REQUEST
    });
    return userServices.reverify(userId).then(function () {
      return dispatch({
        type: types.REVERIFY_USER_SUCCESS
      });
    })["catch"](function (error) {
      return catchError(error, dispatch, types.REVERIFY_USER_FAILURE);
    });
  };
};