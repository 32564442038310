import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import _ from 'lodash';
import * as types from "../core/actionTypes";
import * as exportServices from "../services/exportServices";
import { catchError } from "../core/error.handler";
import { formatToLocalTime } from "../utils/dates";
export var downloadLink = function downloadLink(fileName, link) {
  var a = document.createElement('a');
  a.style = 'display: none';
  a.href = link;
  document.body.appendChild(a);
  a.setAttribute('download', fileName);
  a.setAttribute('target', '_blank');
  a.click();
  document.body.removeChild(a);
};
export var mapFromExportsDto = function mapFromExportsDto(exports) {
  return _.map(exports, function (item) {
    var newItem = _objectSpread({}, item);

    newItem.dateCreatedLocal = formatToLocalTime(item.dateCreated);
    newItem.estimate = item.estimate.number || -1;
    newItem.estimateID = item.estimate._id;
    newItem.userName = item.user.name;
    return newItem;
  });
};
export var getExports = function getExports(params) {
  return function (dispatch) {
    dispatch({
      type: types.ACTION_REQUEST
    });
    exportServices.getExports(params).then(function (result) {
      var exports = mapFromExportsDto(result.data.estifies);
      dispatch({
        type: types.GET_EXPORTS_SUCCESS,
        exports: exports
      });
    })["catch"](function (error) {
      return catchError(error, dispatch, types.GET_EXPORTS_FAILURE);
    });
  };
};
export var purchaseCredits = function purchaseCredits(quantity) {
  return function (dispatch) {
    dispatch({
      type: types.ACTION_REQUEST
    });
    exportServices.purchaseCredits({
      quantity: quantity
    }).then(function (result) {
      var company = result.session.company;
      dispatch({
        type: types.PURCHASE_CREDITS_SUCCESS,
        company: company
      });
    })["catch"](function (error) {
      return catchError(error, dispatch, types.PURCHASE_CREDITS_FAILURE);
    });
  };
};