import HttpRequest from "../utils/HttpRequest";
import * as routesUtils from "../utils/RoutesUtils";
import { API } from "../core/app.routes";
export function login(email, password) {
  var path = routesUtils.getApiPath(API.LOGIN);
  return HttpRequest.post(path, {
    email: email,
    password: password
  });
}
export function getUsers(params) {
  var path = routesUtils.getApiPath(API.USERS_PAGINATION, params);
  return HttpRequest.get(path);
}
export function getUsersSync(params) {
  var path = routesUtils.getApiPath(API.USERS_PAGINATION, params);
  return HttpRequest.get(path);
}
export function getAssignableUsers(params) {
  var path = routesUtils.getApiPath(API.USERS, params);
  return HttpRequest.get(path);
}
export function getUsersPagination(params) {
  var path = routesUtils.getApiPath(API.USERS_PAGINATION, params);
  return HttpRequest.get(path);
}
export function getUserDetail(userId) {
  var path = routesUtils.getApiPath(API.USER_DETAIL, {
    userId: userId
  });
  return HttpRequest.get(path);
}
export function editUser(object) {
  var company = object.company,
      user = object.user;
  var subcontracts = user.subcontracts;
  var path;

  if (user.role === 'Subcontractor') {
    var subcontract = subcontracts.find(function (sub) {
      return sub.company === company;
    });
    if (!subcontract) return Promise.reject(new Error('Users are not Subcontractors'));
    path = routesUtils.getApiPath(API.SUBCONTRACTOR_DETAIL, {
      userId: user._id,
      subID: subcontract._id
    });
    return HttpRequest.put(path, subcontract);
  }

  path = routesUtils.getApiPath(API.ADMIN_USER_DETAIL, {
    userId: user._id
  });
  return HttpRequest.put(path, user);
}
export function updateUser(user) {
  var path = routesUtils.getApiPath(API.USERS_PAGINATION);
  return HttpRequest.put(path, user);
}
export function signup(data, coupon) {
  var path = routesUtils.getApiPath(API.SIGNUP);

  if (coupon) {
    path = "".concat(path, "?coupon=").concat(coupon);
  }

  return HttpRequest.postOriginRoute(path, data);
}
export function checkEmail(email) {
  var path = routesUtils.getApiPath(API.CHECK_EMAIL);
  return HttpRequest.postOriginRoute(path, {
    email: email
  });
}
export function addSubcontractor(email) {
  var path = routesUtils.getApiPath(API.SUBCONTRACTS);
  return HttpRequest.post(path, {
    email: email
  });
}
export function addNewUser(user) {
  var path = routesUtils.getApiPath(API.USERS);
  return HttpRequest.post(path, user);
}
export function reverify(userId) {
  var path = routesUtils.getApiPath(API.USER_REVERIFY, {
    userId: userId
  });
  return HttpRequest.post(path);
}