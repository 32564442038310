export var SKIP = {};
export var LIMIT = 8;
export var FILTER_WORKFLOW_DEFAULT = {
  filter: {
    currency: [localStorage.currency || 'usd']
  },
  view: 'workflow-board',
  limit: LIMIT,
  skip: 0,
  sort: '-dateCreated'
};
export var MAX_OF_STAGES = 20;
export var MODAL_CONFIRM = {
  CANNOT_MOVE_VEHICLE: 'Cannot Move Vehicle',
  CANNOT_MOVE_VEHICLE_IN_WO_BACK_EST: 'You cannot move a vehicle in the Work Order stage back to the Estimate stage.',
  CANNOT_MOVE_VEHICLE_IN_INVS_BACK_WO: 'You cannot move a vehicle in the Invoice stage back to the Work Order stage.',
  CANNOT_MOVE_VEHICLE_IN_INVS_BACK_EST: 'You cannot move a vehicle in the Invoice stage back to the Estimate stage.',
  CANNOT_MOVE_EST: 'You cannot move this vehicle to the Estimate stage without converting it to an Estimate in the app.',
  CANNOT_MOVE_WO: 'You cannot move this vehicle to the Work Order stage without converting it to a Work Order in the app.',
  CANNOT_MOVE_INVS: 'You cannot move this vehicle to the Invoice stage without converting it to an Invoice in the app.',
  CANNOT_MOVE_DRAFT_STAGE: 'You cannot move a vehicle to the draft stage.'
};
export var WORKFLOW_ACTION_TYPE = {
  MOVE_VEHICLE: 'MOVE_VEHICLE',
  TIMER: 'TIMER',
  CHECKLIST: 'CHECKLIST'
};
export var WORKFLOW_DROP_TYPE = {
  VEHICLE: 'VEHICLE',
  COLUMN: 'COLUMN'
};