var constraints = {
  equality: function equality(field) {
    return {
      equality: field
    };
  },
  mustEqual: function mustEqual(val, message) {
    return {
      inclusion: {
        within: [val],
        message: message
      }
    };
  },
  inclusion: function inclusion(vals, message) {
    return {
      inclusion: {
        within: vals,
        message: message
      }
    };
  },
  exclusion: function exclusion(vals, message) {
    return {
      exclusion: {
        within: vals,
        message: message
      }
    };
  },
  required: {
    presence: {
      allowEmpty: false,
      message: 'This field is required'
    }
  },
  requiredWithMessage: function requiredWithMessage(message) {
    return {
      presence: {
        message: message
      }
    };
  },
  format: function format(pattern, message) {
    return {
      format: {
        pattern: pattern,
        message: message
      }
    };
  },
  email: {
    email: true
  },
  emailWithMessage: function emailWithMessage(message) {
    return {
      email: {
        message: message
      }
    };
  },
  length: function length() {
    var min = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 3;
    var max = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 255;
    var message = arguments.length > 2 ? arguments[2] : undefined;
    return {
      length: {
        minimum: min,
        maximum: max,
        message: message
      }
    };
  },
  isInteger: function isInteger() {
    var min = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
    return {
      numericality: {
        onlyInteger: true,
        greaterThanOrEqualTo: min
      }
    };
  },
  isNumeric: function isNumeric() {
    var min = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
    var message = arguments.length > 1 ? arguments[1] : undefined;
    return {
      numericality: {
        greaterThanOrEqualTo: min,
        message: message
      }
    };
  },
  datetime: {
    datetime: true
  },
  dateWithMessage: function dateWithMessage(message) {
    return {
      datetime: {
        message: message
      }
    };
  },
  minmax: function minmax(min, max) {
    return {
      numericality: {
        greaterThanOrEqualTo: min,
        lessThanOrEqualTo: max
      }
    };
  },
  greaterThanAndLessThan: function greaterThanAndLessThan(min, max) {
    return {
      numericality: {
        greaterThan: min,
        lessThan: max
      }
    };
  },
  greaterThan: function greaterThan(val) {
    return {
      numericality: {
        greaterThan: val
      }
    };
  },
  greaterThanOrEqualTo: function greaterThanOrEqualTo(val) {
    return {
      numericality: {
        greaterThanOrEqualTo: val
      }
    };
  },
  lessThan: function lessThan(val) {
    return {
      numericality: {
        lessThan: val
      }
    };
  },
  notAllowValue: function notAllowValue(val) {
    return {
      exclusion: {
        within: [val]
      }
    };
  },
  equalTo: function equalTo(val) {
    return {
      numericality: {
        equalTo: val
      }
    };
  },
  isValidDate: function isValidDate(earliest, latest, message) {
    var constraint = {
      datetime: {}
    };

    if (earliest) {
      constraint.datetime.earliest = earliest;
    }

    if (latest) {
      constraint.datetime.latest = latest;
    }

    if (message) {
      constraint.datetime.message = message;
    }

    return constraint;
  }
};
export default constraints;