import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import _typeof from "@babel/runtime/helpers/typeof";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

/* eslint-disable import/prefer-default-export */
// import { isEmpty } from 'lodash';
import * as types from "../core/actionTypes";
import * as estimateServices from "../services/estimateServices";
import * as workOrderServices from "../services/workOrderServices";
import * as invoiceServices from "../services/invoiceServices";
import { mapFromEstimatesDto } from "./estimateActions";
import { catchError } from "../core/error.handler";
import { WORKFLOW_DOCTYPE } from "../core/constants";
import { formatToLocalTimeShort } from "../utils/dates";
import toast from "../components/libraries/toast";
import { WORKFLOW_ACTION_TYPE } from "../core/workflow.constant";
import moment from 'moment';

var mapFromInvoicesDto = function mapFromInvoicesDto(invoices) {
  return invoices.map(function (invoice) {
    var newInvoice = _objectSpread({}, invoice);

    newInvoice.dateCreatedLocal = formatToLocalTimeShort(invoice.dateCreated);
    return newInvoice;
  });
};

export var isEmpty = function isEmpty(value) {
  return value === undefined || value === null || _typeof(value) === 'object' && Object.keys(value).length === 0 || typeof value === 'string' && value.trim().length === 0;
};
export var getEstimatesForStage = function getEstimatesForStage(query) {
  return function (dispatch) {
    dispatch({
      type: types.STAGE_REQUEST
    });
    return estimateServices.getEstimates(query).then(function (result) {
      var estimates = mapFromEstimatesDto(result.data.estimates);
      var vehicles = [];
      estimates.forEach(function (estimate) {
        if (estimate.vehicles) {
          estimate.vehicles.forEach(function (vehicle) {
            vehicles.push(_objectSpread(_objectSpread({}, vehicle), {}, {
              createdBy: estimate.createdBy,
              client: estimate.client,
              docID: estimate._id,
              docType: WORKFLOW_DOCTYPE.ESTIMATE,
              number: estimate.number,
              hasEvent: !isEmpty(estimate === null || estimate === void 0 ? void 0 : estimate.event)
            }));
          });
        }
      });
      dispatch({
        type: types.STAGE_SET_VEHICLES,
        payload: {
          list: vehicles
        }
      });
      dispatch({
        type: types.STAGE_GET_ESTIMATE_SUCCESS,
        payload: {
          list: estimates
        }
      });
      return estimates;
    })["catch"](function (error) {
      catchError(error, dispatch, types.GET_ESTIMATES_FAILURE);
    });
  };
};
export var getWorkOrdersForStage = function getWorkOrdersForStage(query) {
  return function (dispatch) {
    dispatch({
      type: types.STAGE_REQUEST
    });
    return workOrderServices.postWorkOrders(query).then(function (result) {
      var workOrders = result.data.workOrders;
      var vehicles = []; // eslint-disable-next-line no-unused-expressions

      workOrders === null || workOrders === void 0 ? void 0 : workOrders.forEach(function (workOrder) {
        if (workOrder.vehicle) {
          vehicles.push(_objectSpread(_objectSpread({}, workOrder.vehicle), {}, {
            assignedTo: workOrder.assignedTo,
            client: workOrder.client,
            docID: workOrder._id,
            docType: WORKFLOW_DOCTYPE.WORKORDER,
            number: workOrder.number,
            hasEvent: !isEmpty(workOrder === null || workOrder === void 0 ? void 0 : workOrder.event)
          }));
        }
      });
      dispatch({
        type: types.STAGE_SET_VEHICLES,
        payload: {
          list: vehicles
        }
      });
      dispatch({
        type: types.STAGE_GET_WORK_ORDER_SUCCESS,
        payload: {
          list: workOrders
        }
      });
      return workOrders;
    })["catch"](function (error) {
      catchError(error, dispatch, types.WORK_ORDER_GET_ALL_FAILURE);
    });
  };
};
export var getInvoicesForStage = function getInvoicesForStage(query) {
  return function (dispatch) {
    dispatch({
      type: types.STAGE_REQUEST
    });
    return invoiceServices.getInvoices(query).then(function (result) {
      var invoices = mapFromInvoicesDto(result.data.invoices);
      var vehicles = []; // eslint-disable-next-line no-unused-expressions

      invoices === null || invoices === void 0 ? void 0 : invoices.forEach(function (invoice) {
        if (invoice.vehicles) {
          invoice.vehicles.map(function (vehicle) {
            vehicles.push(_objectSpread(_objectSpread({}, vehicle), {}, {
              client: invoice.client,
              docID: invoice._id,
              docType: WORKFLOW_DOCTYPE.INVOICE,
              number: invoice.number,
              invoiceClose: !invoice.status,
              hasEvent: !isEmpty(invoice === null || invoice === void 0 ? void 0 : invoice.event)
            }));
          });
        }
      });
      dispatch({
        type: types.STAGE_SET_VEHICLES,
        payload: {
          list: vehicles
        }
      });
      dispatch({
        type: types.STAGE_GET_INVOICE_SUCCESS,
        payload: {
          list: invoices
        }
      });
      return invoices;
    })["catch"](function (error) {
      catchError(error, dispatch, types.WORK_ORDER_GET_ALL_FAILURE);
    });
  };
};
export var editEstimateForStage = function editEstimateForStage(estimate, vehicleId, actionType) {
  return function (dispatch) {
    dispatch({
      type: types.STAGE_REQUEST
    });

    var createdBy = estimate.createdBy,
        estimateWithoutCreatedBy = _objectWithoutProperties(estimate, ["createdBy"]);

    return estimateServices.editEstimate(estimateWithoutCreatedBy).then(function (result) {
      var responseEstimate = result.data.estimate;
      var vehicle = responseEstimate.vehicles.find(function (vh) {
        return vh._id === vehicleId;
      });
      vehicle.createdBy = responseEstimate.createdBy;
      vehicle.client = responseEstimate.client;
      vehicle.docID = responseEstimate._id;
      vehicle.number = responseEstimate.number;
      vehicle.docType = WORKFLOW_DOCTYPE.ESTIMATE;
      vehicle.hasEvent = !isEmpty(responseEstimate === null || responseEstimate === void 0 ? void 0 : responseEstimate.event);
      dispatch({
        type: types.STAGE_UPDATE_VEHICLE_SUCCESS,
        payload: {
          vehicle: vehicle
        }
      });
      dispatch({
        type: types.STAGE_SET_VEHICLE_SUCCESS,
        payload: {
          vehicle: vehicle
        }
      });
      dispatch({
        type: types.STAGE_UPDATE_ESTIMATE_SUCCESS,
        payload: {
          estimate: responseEstimate
        }
      });
      var services = vehicle.tracking.services;
      var stages = vehicle.tracking.stages;

      if (services && stages) {
        dispatch({
          type: types.TIMER_UPDATE_SERVICE_RUNNING,
          payload: {
            services: services,
            stages: stages
          }
        });
      }

      switch (actionType) {
        case WORKFLOW_ACTION_TYPE.MOVE_VEHICLE:
          toast.success({
            title: 'Stage updated successfully'
          });
          break;

        case WORKFLOW_ACTION_TYPE.TIMER:
          toast.success({
            title: 'Timer updated successfully'
          });
          break;

        case WORKFLOW_ACTION_TYPE.CHECKLIST:
          toast.success({
            title: 'Checklist updated successfully'
          });
          break;

        default:
          break;
      }

      ;
      return result;
    });
  };
};
export var editWorkOrderForStage = function editWorkOrderForStage(workOrder, actionType) {
  return function (dispatch) {
    dispatch({
      type: types.STAGE_REQUEST
    });
    return workOrderServices.editWorkOrder(workOrder).then(function (result) {
      var vehicle = result.data.workOrder.vehicle;
      vehicle.assignedTo = workOrder.assignedTo;
      vehicle.client = workOrder.client;
      vehicle.docID = workOrder._id;
      vehicle.number = workOrder.number;
      vehicle.docType = WORKFLOW_DOCTYPE.WORKORDER;
      vehicle.hasEvent = !isEmpty(workOrder === null || workOrder === void 0 ? void 0 : workOrder.event);
      dispatch({
        type: types.STAGE_UPDATE_VEHICLE_SUCCESS,
        payload: {
          vehicle: vehicle
        }
      });
      dispatch({
        type: types.STAGE_SET_VEHICLE_SUCCESS,
        payload: {
          vehicle: vehicle
        }
      });
      dispatch({
        type: types.STAGE_UPDATE_WORK_ODER_SUCCESS,
        payload: {
          workOrder: workOrder
        }
      });
      var services = vehicle.tracking.services;
      var stages = vehicle.tracking.stages;

      if (services && stages) {
        dispatch({
          type: types.TIMER_UPDATE_SERVICE_RUNNING,
          payload: {
            services: services,
            stages: stages
          }
        });
      }

      switch (actionType) {
        case WORKFLOW_ACTION_TYPE.MOVE_VEHICLE:
          toast.success({
            title: 'Stage updated successfully'
          });
          break;

        case WORKFLOW_ACTION_TYPE.TIMER:
          toast.success({
            title: 'Timer updated successfully'
          });
          break;

        case WORKFLOW_ACTION_TYPE.CHECKLIST:
          toast.success({
            title: 'Checklist updated successfully'
          });
          break;

        default:
          break;
      }

      ;
      return result;
    });
  };
};
export var editInvoiceForStage = function editInvoiceForStage(invoice, vehicleId, actionType) {
  return function (dispatch) {
    dispatch({
      type: types.STAGE_REQUEST
    });

    var createdBy = invoice.createdBy,
        invoiceWithoutCreatedBy = _objectWithoutProperties(invoice, ["createdBy"]);

    return invoiceServices.editInvoice(invoiceWithoutCreatedBy).then(function (result) {
      var responseInvoice = result.data.invoice;
      var vehicle = responseInvoice.vehicles.find(function (vh) {
        return vh._id === vehicleId;
      });
      vehicle.client = responseInvoice.client;
      vehicle.docID = responseInvoice._id;
      vehicle.number = responseInvoice.number;
      vehicle.docType = WORKFLOW_DOCTYPE.INVOICE;
      vehicle.hasEvent = !isEmpty(responseInvoice === null || responseInvoice === void 0 ? void 0 : responseInvoice.event);
      vehicle.invoiceClose = !responseInvoice.status;
      dispatch({
        type: types.STAGE_UPDATE_VEHICLE_SUCCESS,
        payload: {
          vehicle: vehicle
        }
      });
      dispatch({
        type: types.STAGE_SET_VEHICLE_SUCCESS,
        payload: {
          vehicle: vehicle
        }
      });
      dispatch({
        type: types.STAGE_UPDATE_INVOICE_SUCCESS,
        payload: {
          invoice: responseInvoice
        }
      });
      var services = vehicle.tracking.services;
      var stages = vehicle.tracking.stages;

      if (services && stages) {
        dispatch({
          type: types.TIMER_UPDATE_SERVICE_RUNNING,
          payload: {
            services: services,
            stages: stages
          }
        });
      }

      switch (actionType) {
        case WORKFLOW_ACTION_TYPE.MOVE_VEHICLE:
          toast.success({
            title: 'Stage updated successfully'
          });
          break;

        case WORKFLOW_ACTION_TYPE.TIMER:
          toast.success({
            title: 'Timer updated successfully'
          });
          break;

        case WORKFLOW_ACTION_TYPE.CHECKLIST:
          toast.success({
            title: 'Checklist updated successfully'
          });
          break;

        default:
          break;
      }

      ;
      return result;
    });
  };
};
export var getVehicle = function getVehicle(vehicle) {
  return function (dispatch) {
    dispatch({
      type: types.STAGE_SET_VEHICLE_SUCCESS,
      payload: {
        vehicle: vehicle
      }
    });
  };
};
export var resetStage = function resetStage() {
  return function (dispatch) {
    dispatch({
      type: types.RESET_STAGE
    });
  };
};