import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useEffect, useState } from 'react';
var BREAKPOINT = {
  SM: 'SM',
  MD: 'MD',
  LG: 'LG',
  XL: 'XL'
};

var checkSize = function checkSize(width) {
  if (width <= 576) return BREAKPOINT.SM;
  if (width <= 768) return BREAKPOINT.MD;
  if (width <= 992) return BREAKPOINT.LG;
  return BREAKPOINT.XL;
};

var useBreakpoint = function useBreakpoint() {
  var _useState = useState(checkSize(window.innerWidth)),
      _useState2 = _slicedToArray(_useState, 2),
      breakpoint = _useState2[0],
      setSize = _useState2[1];

  var windowResize = function windowResize() {
    setSize(checkSize(window.innerWidth));
  };

  useEffect(function () {
    window.addEventListener('resize', function () {
      windowResize();
    }, false);
    return function () {
      window.removeEventListener('resize', function () {
        windowResize();
      }, false);
    };
  }, []);
  return {
    breakpoint: breakpoint,
    BREAKPOINT: BREAKPOINT
  };
};

export default useBreakpoint;