import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import _ from 'lodash';
import * as types from "../core/actionTypes";
import initialState from "./initialState";

var groups = function groups() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState.groupsReducer;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  var defaultState = _.cloneDeep(initialState.groupsReducer);

  switch (action.type) {
    case types.GET_GROUPS_SUCCESS:
      {
        return _objectSpread(_objectSpread({}, state), {
          groups: action.groups
        });
      }

    case types.GET_GROUP_SUCCESS:
      {
        return _objectSpread(_objectSpread({}, state), {
          group: action.group
        });
      }

    case types.ADD_GROUP_SUCCESS:
      {
        return _objectSpread(_objectSpread(_objectSpread({}, state), {
          group: action.group
        }), {
          success: true
        });
      }

    case types.EDIT_GROUP_ADD_CLIENTS_SUCCESS:
    case types.REMOVE_CLIENT_IN_GROUP_SUCCESS:
      {
        return _objectSpread(_objectSpread(_objectSpread({}, state), {
          group: action.group
        }), {
          success: true
        });
      }

    case types.EDIT_GROUP_SUCCESS:
      {
        return _objectSpread(_objectSpread(_objectSpread({}, state), {
          groups: action.groups
        }), {
          success: true
        });
      }

    case types.DELETE_GROUP_SUCCESS:
      {
        return _objectSpread(_objectSpread(_objectSpread({}, state), {
          groups: action.groups
        }), {
          success: true
        });
      }

    case types.RESET_ERROR:
      {
        return _objectSpread(_objectSpread({}, state), {
          error: {}
        });
      }

    case types.RESET_STATE:
      {
        var stateClone = _objectSpread({}, state);

        _.forEach(action.fields, function (field) {
          stateClone[field] = defaultState[field];
        });

        return stateClone;
      }

    case types.GET_GROUPS_FAILURE:
    case types.ADD_GROUP_FAILURE:
    case types.EDIT_GROUP_FAILURE:
    case types.EDIT_GROUP_ADD_CLIENTS_FAILURE:
    case types.REMOVE_CLIENT_IN_GROUP_FAILURE:
    case types.EDIT_GROUP_EDIT_CLIENT_FAILURE:
    case types.EDIT_GROUP_EDIT_CLIENT_ADD_USERS_FAILURE:
    case types.EDIT_GROUP_EDIT_CLIENT_EDIT_USER_FAILURE:
    case types.DELETE_GROUP_FAILURE:
      {
        return _objectSpread(_objectSpread({}, state), {
          error: action.error
        });
      }

    case types.LOGOUT:
      {
        return defaultState;
      }

    default:
      return state;
  }
};

export default groups;