import HttpRequest from "../utils/HttpRequest";
import * as routesUtils from "../utils/RoutesUtils";
import { API } from "../core/app.routes";
export function getGroups(params) {
  var path = routesUtils.getApiPath(API.GROUPS, params);
  return HttpRequest.get(path);
}
export function getGroup(params) {
  var path = routesUtils.getApiPath(API.EDIT_GROUP, params);
  return HttpRequest.get(path);
}
export function editGroup(group) {
  var path = routesUtils.getApiPath(API.EDIT_GROUP, {
    groupID: group._id
  });
  return HttpRequest.put(path, group);
}
export function removeClientInGroup(params) {
  var path = routesUtils.getApiPath(API.EDIT_GROUP_EDIT_CLIENT, params);
  return HttpRequest["delete"](path, {});
}
export function editGroupAddClients(group) {
  var path = routesUtils.getApiPath(API.EDIT_GROUP_ADD_CLIENTS, {
    groupID: group._id
  });
  delete group._id;
  return HttpRequest.put(path, group);
}
export function editGroupEditClient(groupID, client) {
  var path = routesUtils.getApiPath(API.EDIT_GROUP_EDIT_CLIENT, {
    groupID: groupID,
    clientID: client.client._id
  });
  return HttpRequest.put(path, client);
}
export function editGroupEditClientAddUsers(group, client, user) {
  var path = routesUtils.getApiPath(API.EDIT_GROUP_EDIT_CLIENT_ADD_USERS, {
    groupID: group._id,
    clientID: client.client._id
  });
  return HttpRequest.put(path, user);
}
export function editGroupEditClientEditUser(group, clientID, user) {
  var path = routesUtils.getApiPath(API.EDIT_GROUP_EDIT_CLIENT_EDIT_USER, {
    groupID: group._id,
    clientID: clientID,
    userID: user.user._id
  });
  return HttpRequest.put(path, user);
}
export function addGroup(group) {
  var path = routesUtils.getApiPath(API.GROUPS);
  return HttpRequest.post(path, group);
}
export function deleteGroup(groupID) {
  var path = routesUtils.getApiPath(API.EDIT_GROUP, {
    groupID: groupID
  });
  return HttpRequest["delete"](path);
}