import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import _ from 'lodash';
import * as types from "../core/actionTypes";
import initialState from "./initialState";
import { handleCRUDAction } from "../core/common";
export default function cogsReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState.cogsReducer;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  state = _objectSpread(_objectSpread({}, state), {}, {
    editSuccess: false,
    addSuccess: false,
    error: null
  });
  var newState;

  switch (action.type) {
    case types.COGS_GET_REQUEST:
      {
        var cogs = _objectSpread(_objectSpread({}, state), {}, {
          loading: true
        });

        return cogs;
      }

    case types.COGS_GET_SUCCESS:
      {
        newState = handleCRUDAction(state, action);

        if (newState.success && action.payload !== null) {
          newState.cogs = action.payload;
        }

        return newState;
      }

    case types.COGS_GET_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: false,
        error: action.error
      });

    case types.LOGOUT:
      return initialState.cogsReducer;

    default:
      return _objectSpread(_objectSpread({}, state), {}, {
        addSuccess: false,
        editSuccess: false,
        error: null
      });
  }
}