import HttpRequest from "../utils/HttpRequest";
import * as routesUtils from "../utils/RoutesUtils";
import { API } from "../core/app.routes";
export function prepareMatrix(matrixInfo) {
  var path = routesUtils.getApiPath(API.MATRICES);
  return HttpRequest.post(path, matrixInfo);
}
export function getMatrices(paramString) {
  var path = routesUtils.getApiPath(API.MATRICES);
  return HttpRequest.get("".concat(path).concat(paramString));
}
export function deleteMatrix(matrixID) {
  var path = routesUtils.getApiPath(API.EDIT_MATRICES, {
    matrixID: matrixID
  });
  return HttpRequest["delete"](path);
}
export function editMatrix(matrix) {
  var path = routesUtils.getApiPath(API.EDIT_MATRICES, {
    matrixID: matrix._id
  });
  return HttpRequest.put(path, matrix);
}