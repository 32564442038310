import HttpRequest from "../utils/HttpRequest";
import * as routesUtils from "../utils/RoutesUtils";
import { API } from "../core/app.routes";
export function getClients(params) {
  var path = routesUtils.getApiPath(API.CLIENTS, params);
  return HttpRequest.get(path);
}
export function getCientDetail(clientId) {
  var path = routesUtils.getApiPath(API.CLIENT_DETAIL, {
    clientId: clientId
  });
  return HttpRequest.get(path);
}
export function editClient(client) {
  var path = routesUtils.getApiPath(API.CLIENT_DETAIL, {
    clientId: client._id
  });
  return HttpRequest.put(path, client);
}
export function enableClient(params) {
  var path = routesUtils.getApiPath(API.ENABLE, {
    client_id: params
  });
  return HttpRequest.post(path);
}
export function disableClient(params) {
  var path = routesUtils.getApiPath(API.DISABLE, {
    client_id: params
  });
  return HttpRequest.post(path);
}
export function addClient(client) {
  var path = routesUtils.getApiPath(API.CLIENTS);
  return HttpRequest.post(path, client);
}
export function createSignedRequest(id) {
  var path = routesUtils.getApiPath(API.CLIENT_S3, {
    clientId: id
  });
  return HttpRequest.post(path, {});
}
export function getReminders(params) {
  var path = routesUtils.getApiPath(API.GET_REMINDERS, params);
  return HttpRequest.get(path);
}
export function getReminder(params) {
  var path = routesUtils.getApiPath(API.GET_REMINDERS, params);
  return HttpRequest.get(path);
}
export function createReminder(body) {
  var path = routesUtils.getApiPath(API.CREATE_REMINDER);
  return HttpRequest.post(path, body);
}
export function updateReminder(params, body) {
  var path = routesUtils.getApiPath(API.UPDATE_REMINDER, params);
  return HttpRequest.put(path, body);
}
export function deleteReminder(params) {
  var path = routesUtils.getApiPath(API.DELETE_REMINDER, params);
  return HttpRequest["delete"](path, {});
}
export function getRegardingVehicle(body) {
  var path = routesUtils.getApiPath(API.REGARDING_VEHICLE);
  return HttpRequest.post(path, body);
}