import HttpRequest from "../utils/HttpRequest";
import * as routesUtils from "../utils/RoutesUtils";
import { API } from "../core/app.routes";
export function getPayments(params) {
  var path = routesUtils.getApiPath(API.PAYMENTS, params);
  return HttpRequest.get(path);
}
export function getPayment(params) {
  var path = routesUtils.getApiPath(API.EDIT_PAYMENT, params);
  return HttpRequest.get(path);
}
export function getPaymentSummary() {
  var path = routesUtils.getApiPath(API.PAYMENT_SUMMARY, {});
  return HttpRequest.get(path);
}
export function paymentRefund(params) {
  var path = routesUtils.getApiPath(API.PAYMENT_REFUND, params);
  return HttpRequest.put(path);
}
export function deletePayment(params) {
  var path = routesUtils.getApiPath(API.EDIT_PAYMENT, params);
  return HttpRequest["delete"](path);
}
export function getStripeLoginLink(companyID) {
  var path = routesUtils.getApiPath(API.STRIPE_LOGIN, {
    companyID: companyID
  });
  return HttpRequest.get(path);
}
export function stripeOauth(companyID, code) {
  var path = routesUtils.getApiPath(API.STRIPE_OAUTH, {
    companyID: companyID
  });
  return HttpRequest.post(path, {
    code: code
  });
}
export function createPayment(body) {
  var path = routesUtils.getApiPath(API.PAYMENTS);
  return HttpRequest.post(path, body);
}
export function editPayments(body, params) {
  var path = routesUtils.getApiPath(API.EDIT_PAYMENT, params);
  return HttpRequest.put(path, body);
}
export function sendPaymentReceipt(body, params) {
  var path = routesUtils.getApiPath(API.SEND_PAYMENT_RECEIPT, params);
  return HttpRequest.put(path, body);
}
export function getUploadEvidence(params) {
  var path = routesUtils.getApiPath(API.UPLOAD_PAYMENT_EVIDENCE, params);
  return HttpRequest.get(path);
}
export function orderCardReader(body) {
  var path = routesUtils.getApiPath(API.STRIPE_HARDWARE_ORDER);
  return HttpRequest.post(path, body);
}
export function confirmCardReaderOrder(body) {
  var path = routesUtils.getApiPath(API.STRIPE_HARDWARE_ORDER_CONFIRMATION);
  return HttpRequest.post(path, body);
}
export function getFormIdentityLink() {
  var path = routesUtils.getApiPath(API.STRIPE_IDENTITY);
  return HttpRequest.get(path);
}
export function getStripeConnectSignUp() {
  var path = routesUtils.getApiPath(API.STRIPE_CONNECT_SIGNUP);
  return HttpRequest.post(path);
}