import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import * as types from "../core/actionTypes"; // import {necessaryDataIsProvidedToCalculateSavings, calculateSavings} from '../utils/fuelSavings';

import objectAssign from 'object-assign';
import initialState from "./initialState"; // IMPORTANT: Note that with Redux, state should NEVER be changed.
// State is considered immutable. Instead,
// create a copy of the state passed and set new values on the copy.
// Note that I'm using Object.assign to create a copy of current state
// and update values on the copy.

export default function payLoad() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState.payLoad;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  // let newState;
  var type = action.type;
  var matches = /(.*)_(REQUEST|SUCCESS|FAILURE|EXPIRED)/.exec(type); // not a *_REQUEST / *_SUCCESS /  *_FAILURE actions, so we ignore them

  if (!matches) return state;

  var _matches = _slicedToArray(matches, 3),
      requestName = _matches[1],
      requestState = _matches[2];

  return _objectSpread(_objectSpread({}, state), {}, {
    // Store whether a request is happening at the moment or not
    // e.g. will be true when receiving GET_TODOS_REQUEST
    //      and false when receiving GET_TODOS_SUCCESS / GET_TODOS_FAILURE
    loading: requestState === 'REQUEST'
  });
}