import { get } from 'lodash';
export var enterprise = function enterprise(planID) {
  return planID.includes('enterprise');
};
export var annual = function annual(planID) {
  return planID.includes('annual');
};
export var active = function active(subscription) {
  return !!subscription.status;
};
export var trial = function trial(subscription) {
  return subscription.status === 'trialing';
};
export var canceled = function canceled(subscription) {
  return !subscription.subscription || subscription.status === 'Canceled';
};
export var pause = function pause(planID, subscription) {
  return planID.includes('pause') && subscription.status !== 'Canceled';
};
export var pauseBilling = function pauseBilling(planID) {
  return planID === 'pause_billing';
};
export var cancelAtPeriodEnd = function cancelAtPeriodEnd(subscription) {
  return !!get(subscription, 'subscription.cancel_at_period_end', '');
};
export var caradas = function caradas(planID) {
  return planID.includes('caradas');
};