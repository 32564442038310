import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { GET_QB_COST_OF_GOODS_SUCCESS, GET_QB_COST_OF_GOODS_FAILURE, GET_EXPENSE_ACCOUNTS_SUCCESS, GET_EXPENSE_ACCOUNTS_FAILURE, GET_CLASSES_SUCCESS, GET_CLASSES_FAILURE, ACTION_REQUEST, DISCONNECT_QB_SUCCESS, DISCONNECT_QB_FAILURE, SYNC_QB_SUCCESS, SYNC_QB_FAILURE, HIDE_DUPLICATE_WARNING_MODAL, GET_QB_TAX_CODES_SUCCESS, GET_QB_TAX_CODES_FAILURE, GET_QB_COMPANY_INFO_SUCCESS, GET_QB_COMPANY_INFO_FAILURE } from "../core/actionTypes";
import { catchError, createErrorObject } from "../core/error.handler";
import getQuickBookSettings, { API_CALL_GET_COST_OF_GOODS, API_CALL_GET_EXPENSE, API_CALL_GET_CLASSES, API_CALL_GET_TAX_CODES, API_CALL_GET_COMPANY_INFO } from "../services/QuickBook/getQuickBookSettings";
import * as mainServices from "../services/mainServices";
export var getCompanyInfo = function getCompanyInfo() {
  return function (dispatch, getState) {
    var company = _objectSpread({}, getState().main.company);

    dispatch({
      type: ACTION_REQUEST
    });
    return getQuickBookSettings(API_CALL_GET_COMPANY_INFO, {
      companyID: company._id
    }).then(function (result) {
      if (!(result.data && result.data.QueryResponse)) {
        throw createErrorObject('Error. Error in getting Quickbooks Company Info', 400);
      }

      if (!result.data.QueryResponse.CompanyInfo) return [{
        title: '',
        value: ''
      }];
      dispatch({
        type: GET_QB_COMPANY_INFO_SUCCESS,
        payload: result.data.QueryResponse.CompanyInfo
      });
      return result.data.QueryResponse.Account;
    })["catch"](function (error) {
      catchError(error, dispatch, GET_QB_COMPANY_INFO_FAILURE); // throw error;
    });
  };
};
export var getTaxCodes = function getTaxCodes() {
  return function (dispatch, getState) {
    var company = _objectSpread({}, getState().main.company);

    dispatch({
      type: ACTION_REQUEST
    });
    return getQuickBookSettings(API_CALL_GET_TAX_CODES, {
      companyID: company._id
    }).then(function (result) {
      if (!(result.data && result.data.QueryResponse)) {
        throw createErrorObject('Error. Error in getting Quickbooks Tax Rates', 400);
      }

      if (!result.data.QueryResponse.TaxCode) return [{
        title: '',
        value: ''
      }];
      dispatch({
        type: GET_QB_TAX_CODES_SUCCESS,
        payload: result.data.QueryResponse.TaxCode
      });
      return result.data.QueryResponse.Account;
    })["catch"](function (error) {
      catchError(error, dispatch, GET_QB_TAX_CODES_FAILURE); // throw error;
    });
  };
};
export var getCostOfGoods = function getCostOfGoods() {
  return function (dispatch, getState) {
    var company = _objectSpread({}, getState().main.company);

    dispatch({
      type: ACTION_REQUEST
    });
    return getQuickBookSettings(API_CALL_GET_COST_OF_GOODS, {
      companyID: company._id
    }).then(function (result) {
      if (!(result.data && result.data.QueryResponse)) {
        throw createErrorObject('Error. Error in getting Quickbooks Cost of Goods Accounts', 400);
      }

      if (!result.data.QueryResponse.Account) return [{
        title: '',
        value: ''
      }];
      dispatch({
        type: GET_QB_COST_OF_GOODS_SUCCESS,
        payload: result.data.QueryResponse.Account
      });
      return result.data.QueryResponse.Account;
    })["catch"](function (error) {
      catchError(error, dispatch, GET_QB_COST_OF_GOODS_FAILURE); // throw error;
    });
  };
};
export var getExpenseAccounts = function getExpenseAccounts() {
  return function (dispatch, getState) {
    var company = _objectSpread({}, getState().main.company);

    dispatch({
      type: ACTION_REQUEST
    });
    return getQuickBookSettings(API_CALL_GET_EXPENSE, {
      companyID: company._id
    }).then(function (result) {
      if (!(result.data && result.data.QueryResponse)) {
        throw createErrorObject('Error. Error in getting Quickbooks Cost of Goods Accounts', 400);
      }

      if (!result.data.QueryResponse.Account) return [{
        title: '',
        value: ''
      }];
      dispatch({
        type: GET_EXPENSE_ACCOUNTS_SUCCESS,
        payload: result.data.QueryResponse.Account
      });
      return result.data.QueryResponse.Account;
    })["catch"](function (error) {
      catchError(error, dispatch, GET_EXPENSE_ACCOUNTS_FAILURE); // throw error;
    });
  };
};
export var getClasses = function getClasses() {
  return function (dispatch, getState) {
    var company = _objectSpread({}, getState().main.company);

    dispatch({
      type: ACTION_REQUEST
    });
    return getQuickBookSettings(API_CALL_GET_CLASSES, {
      companyID: company._id
    }).then(function (result) {
      if (!(result.data && result.data.QueryResponse)) {
        throw createErrorObject('Error. Error in getting Quickbooks Classes', 400);
      }

      if (!result.data.QueryResponse.Account) return [{
        title: '',
        value: ''
      }];
      dispatch({
        type: GET_CLASSES_SUCCESS,
        payload: result.data.QueryResponse.Account
      });
      return result.data.QueryResponse.Account;
    })["catch"](function (error) {
      catchError(error, dispatch, GET_CLASSES_FAILURE); // throw error;
    });
  };
}; // TODO: after connecting, we should call all getQuickBookEntity calls, or maybe make this page specific

export var connectToQuickBooks = function connectToQuickBooks() {
  return function (dispatch, getState) {
    var company = _objectSpread({}, getState().main.company);

    var qbWindow = window.open('', '', 'width=400, height=600');

    if (!qbWindow || qbWindow.closed || typeof qbWindow.closed === 'undefined') {
      throw createErrorObject("Please disable your popup blocker", 400);
    }

    return mainServices.connectToQuickBooks(company._id).then(function (result) {
      qbWindow.location.href = result.data;
      return new Promise(function (resolve) {
        var href;
        var detectsUrl = setInterval(function () {
          try {
            if (!qbWindow.location.href) throw new Error();
            href = qbWindow.location.href;
          } catch (e) {
            href = '';
          }

          if (href.includes('realmId=') || href.includes('error=') || qbWindow.closed) {
            clearInterval(detectsUrl);
            qbWindow.close();
            resolve(href);
          }
        }, 200);
      });
    }).then(function (authUri) {
      if (!authUri || authUri.includes('error=')) throw createErrorObject("Error. Couldn't connect to quickbooks", 400); // if user cancels, don't do anything

      return mainServices.oath2Callback({
        url: authUri
      }, company._id);
    }).then(function (oathResult) {
      dispatch({
        type: SYNC_QB_SUCCESS,
        company: oathResult.data.company
      });
      return oathResult;
    })["catch"](function (error) {
      catchError(error, dispatch, SYNC_QB_FAILURE);
    });
  };
};
export var disconnectFromQuickBooks = function disconnectFromQuickBooks() {
  return function (dispatch, getState) {
    var company = _objectSpread({}, getState().main.company);

    dispatch({
      type: ACTION_REQUEST
    });
    return mainServices.disconnectFromQuickBooks(company._id).then(function (result) {
      dispatch({
        type: DISCONNECT_QB_SUCCESS,
        connectedQB: false,
        company: result.data.company
      });
      return result;
    })["catch"](function (error) {
      catchError(error, dispatch, DISCONNECT_QB_FAILURE);
    });
  };
};
export var hideQBWarningModal = function hideQBWarningModal() {
  return function (dispatch) {
    dispatch({
      type: HIDE_DUPLICATE_WARNING_MODAL
    });
  };
};