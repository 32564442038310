export function resizeImage(canvas, src, dst, type, quality, cb) {
  var tmp = new Image(),
      context,
      cW,
      cH;
  type = type || 'image/png';
  quality = quality || 1.0;
  cW = src.naturalWidth;
  cH = src.naturalHeight;
  tmp.src = src.src;

  tmp.onload = function () {
    cW /= 2;
    cH /= 2;
    canvas.width = cW;
    canvas.height = cH;
    context = canvas.getContext('2d');
    context.drawImage(tmp, 0, 0, cW, cH);
    dst.src = canvas.toDataURL(type, quality); // height or width is less than parameters (500x500)

    if (cW <= src.width && cH <= src.height) {
      // determine which is closest to 500
      if (src.width - cW < src.height - cH) {
        cH = canvas.height = 500 / cW * cH;
        cW = canvas.width = 500;
        context = canvas.getContext('2d');
        context.drawImage(tmp, 0, 0, cW, cH);
        dst.src = canvas.toDataURL(type, quality);
        return cb();
      }

      cW = canvas.width = 500 / cH * cW;
      cH = canvas.height = 500;
      context = canvas.getContext('2d');
      context.drawImage(tmp, 0, 0, cW, cH);
      dst.src = canvas.toDataURL(type, quality);
      return cb();
    }

    tmp.src = dst.src;
  };
}
export function dataURLToBlob(dataURL) {
  var BASE64_MARKER = ';base64,';

  if (dataURL.indexOf(BASE64_MARKER) === -1) {
    var _parts = dataURL.split(',');

    var _contentType = _parts[0].split(':')[1];

    var _raw = decodeURIComponent(_parts[1]);

    return new Blob([_raw], {
      type: _contentType
    });
  }

  var parts = dataURL.split(BASE64_MARKER);
  var contentType = parts[0].split(':')[1];
  var raw = window.atob(parts[1]);
  var rawLength = raw.length;
  var uInt8Array = new Uint8Array(rawLength);

  for (var i = 0; i < rawLength; ++i) {
    uInt8Array[i] = raw.charCodeAt(i);
  }

  return new Blob([uInt8Array], {
    type: contentType
  });
}