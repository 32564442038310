import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import initialState from "./initialState";
import * as types from "../core/actionTypes";
import { handleCRUDAction } from "../core/common";
export default function matrixReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState.matrixReducer;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  var defaultState = _.cloneDeep(initialState.matrixReducer);

  var newState;

  switch (action.type) {
    case types.GET_MATRICES_SUCCESS:
      {
        newState = handleCRUDAction(state, action);

        if (newState.success) {
          newState.success = false;
          newState.matrices = action.matrices;
        }

        return newState;
      }

    case types.DELETE_MATRIX_SUCCESS:
      newState = handleCRUDAction(state, action);

      if (newState.success) {
        newState.success = false;
        newState.matrices = action.matrices;
      }

      return newState;

    case types.UPLOAD_HAIL_MATRIX_FAILURE:
    case types.UPLOAD_HAIL_MATRIX_SUCCESS:
      {
        newState = handleCRUDAction(state, action);

        if (newState.success) {
          newState.connectedQB = true;
        }

        return newState;
      }

    case types.LOGOUT:
      {
        return defaultState;
      }

    default:
      return _objectSpread(_objectSpread({}, state), {}, {
        success: false,
        error: null
      });
  }
}