import HttpRequest from "../utils/HttpRequest";
import * as routesUtils from "../utils/RoutesUtils";
import { API } from "../core/app.routes";
export function searchReports(data) {
  var path = routesUtils.getApiPath(API.REPORTS);
  return HttpRequest.post(path, data);
}
export function pdfReport(data) {
  var path = routesUtils.getApiPath(API.GET_PDF_REPORTS);
  return HttpRequest.post("".concat(path, "?background=true"), data);
}
export function sendEmail(data) {
  var path = routesUtils.getApiPath(API.SEND_REPORT_EMAIL);
  return HttpRequest.post(path, data);
}
export function exportReportsCSV(data) {
  var path = routesUtils.getApiPath(API.REPORTS);
  return HttpRequest.postOriginRoute(path, data);
} // export function exportTimelineCSV(data) {
// 	const path = routesUtils.getApiPath(API.REPORTS);
// 	HttpRequest.postOriginRoute(path, data, (error, postResponse) => {
// 		if (error) {
// 			showToastr.error(error.data.err);
// 			document.querySelector('.backdrop').classList.remove('show');
// 			document.body.classList.remove('unscrollable');
// 		}
// 		if (postResponse.data) {
// 			let path = routesUtils.getApiPath(API.GET_JOB);
// 			let attempts = 0;
// 			// Job polling every 3 seconds asking for CSV
// 			const polling = setInterval(() => {
// 				attempts++; 
// 				HttpRequest.getSync(`${path}${postResponse.data.job}`).then((getResponse) => {
// 					// Stop polling after 10 attemps
// 					if (attempts > 10) {
// 						clearInterval(polling);
// 						throw new Error();
// 						// return showToastr.error('Error! Please try again');
// 					}
// 					// Document is ready when server sets result.data.job.status == false
// 					if (getResponse.data.job.status === false) {
// 						clearInterval(polling);
// 						if (!getResponse.data.job.data) return showToastr.error('No report data');
// 						return downloadLink('Report.CSV', getResponse.data.job.data.report);
// 					}
// 				}).then(() => {
// 					document.querySelector('.backdrop').classList.remove('show');
// 					document.body.classList.remove('unscrollable');
// 					showToastr.success('CSV Download');
// 				}).catch(() => {
// 					clearInterval(polling);
// 					document.querySelector('.backdrop').classList.remove('show');
// 					document.body.classList.remove('unscrollable');
// 					return showToastr.error('Error! Please try again');
// 				});
// 			}, 3000);
// 		} else {
// 			showToastr.error('Please try again');
// 			document.querySelector('.backdrop').classList.remove('show');
// 			document.body.classList.remove('unscrollable');
// 			return false;
// 		}
// 	});
// }

function downloadLink(fileName, link) {
  var a = document.createElement('a');
  a.style = 'display: none';
  a.href = link;
  document.body.appendChild(a);
  a.setAttribute('download', fileName);
  a.click();
  document.body.removeChild(a);
}