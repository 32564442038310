import HttpRequest from "../utils/HttpRequest";
import * as routesUtils from "../utils/RoutesUtils";
import { API } from "../core/app.routes";
export function getWorkflow() {
  var path = routesUtils.getApiPath(API.WORK_FLOW);
  return HttpRequest.get("".concat(path));
}
export function editWorkflow(workflow) {
  var path = routesUtils.getApiPath(API.WORK_FLOW);
  return HttpRequest.put(path, workflow);
}
export function deleteWorkflow() {
  var path = routesUtils.getApiPath(API.WORK_FLOW);
  return HttpRequest["delete"](path);
}
export function createWorkflow(workflow) {
  var path = routesUtils.getApiPath(API.WORK_FLOW);
  return HttpRequest.post(path, workflow);
}
export function createStage(stage) {
  var path = routesUtils.getApiPath(API.STAGE);
  return HttpRequest.post(path, stage);
}
export function updateStage(stage) {
  var path = routesUtils.getApiPath(API.STAGE);
  return HttpRequest.put(path + '/' + stage._id, stage);
}