import _ from 'lodash';
import * as types from "../core/actionTypes"; // import {necessaryDataIsProvidedToCalculateSavings, calculateSavings} from '../utils/fuelSavings';

import initialState from "./initialState"; // IMPORTANT: Note that with Redux, state should NEVER be changed.
// State is considered immutable. Instead,
// create a copy of the state passed and set new values on the copy.
// Note that I'm using Object.assign to create a copy of current state
// and update values on the copy.

export default function payableReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState.payableReducer;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  var defaultState = _.cloneDeep(initialState.payableReducer);

  switch (action.type) {
    case types.RESET_STATE:
      {
        var stateClone = _.assign({}, state);

        _.forEach(action.fields, function (field) {
          stateClone[field] = defaultState[field];
        });

        return stateClone;
      }

    case types.LOGOUT:
      {
        return defaultState;
      }

    default:
      return state;
  }
}