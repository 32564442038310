import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import _ from 'lodash';
import * as types from "../core/actionTypes";
import initialState from "./initialState";
import { handleCRUDAction } from "../core/common";

var workflowReducer = function workflowReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState.workflowReducer;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  var stateWL = _objectSpread(_objectSpread({}, state), {}, {
    error: null
  });

  switch (action.type) {
    case types.WORKFLOW_GET_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        workflow: initialState.workflowReducer
      });

    case types.WORKFLOW_GET_SUCCESS:
    case types.WORKFLOW_CREATE_SUCCESS:
    case types.WORKFLOW_UPDATE_SUCCESS:
      {
        var newState = handleCRUDAction(stateWL, action);
        var workflow = action.workflow;

        if (newState.success) {
          if (workflow) {
            var stages = workflow.stages;
            stages = stages.map(function (stg) {
              if (stg._id._id) {
                return _objectSpread(_objectSpread({}, stg._id), {}, {
                  placement: stg.placement
                });
              }

              return stg;
            });
            newState.workflow = _objectSpread(_objectSpread({}, workflow), {}, {
              stages: stages
            });
          } else {
            newState.workflow = initialState.workflowReducer.workflow;
          }
        } else {
          newState.workflow = initialState.workflowReducer.workflow;
        }

        return newState;
      }

    case types.WORKFLOW_GET_FAILURE:
    case types.WORKFLOW_CREATE_FAILURE:
    case types.WORKFLOW_DELETE_FAILURE:
    case types.WORKFLOW_UPDATE_FAILURE:
      return _objectSpread(_objectSpread({}, state), {
        error: action.error
      });

    case types.WORKFLOW_HIDE_MODAL:
      return _objectSpread(_objectSpread({}, state), {}, {
        isShowModalNewestData: false
      });

    case types.WORKFLOW_SHOW_MODAL_MESSAGE_TYPE:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          messageType: action.messageType
        });
      }

    case types.WORKFLOW_SHOW_MODAL:
      return _objectSpread(_objectSpread({}, state), {}, {
        isShowModalNewestData: true
      });

    case types.WORKFLOW_SYNC_DB_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        workflow: _objectSpread(_objectSpread({}, state.workflow), {}, {
          __v: action.payload
        })
      });

    default:
      return _objectSpread(_objectSpread({}, state), {}, {
        error: null
      });
  }
};

export default workflowReducer;