import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

var _MODULES;

import { useContext } from 'react';
import { ReactReduxContext } from 'react-redux';
import { TIER } from "../../core/constants";
export var MODULES = (_MODULES = {
  CreaditCardProcessing: 'CreaditCardProcessing',
  Calendar: 'Calendar',
  BeforeAfterPhotos: 'Before/AfterPhotos',
  TextAlerts: 'TextAlerts',
  BasicReporting: 'BasicReporting',
  QuickbookOnline: 'QuickbookOnline',
  TechPay: 'TechPay',
  PrePostScanStorage: 'Pre/postScanStorage',
  OEMPartsOdering: 'OEMPartsOdering',
  EstifyExport: 'EstifyExport',
  PaintCodes: 'PaintCodes',
  WheelTorqueSpecs: 'WheelTorqueSpecs',
  EmailHistory: 'EmailHistory',
  ClientPortal: 'ClientPortal',
  Statements: 'Statements',
  DiscountsOnEstimates: 'DiscountsOnEstimates',
  CSVExporting: 'CSVExporting',
  DepositsOnEstimates: 'DepositsOnEstimates',
  CustomPdrMatrices: 'CustomPdrMatrices',
  Subcontracting: 'Subcontracting',
  ConsumerFinancing: 'ConsumerFinancing',
  Checklists: 'Checklists',
  ServiceChecklists: 'ServiceChecklists',
  DealerPackageManagement: 'DealerPackageManagement',
  BeforeAfterVideos: 'BeforeAfterVideos',
  ERACExports: 'ERACExports',
  BatchImport: 'BatchImport',
  GroupsFeature: 'GroupsFeature',
  TimeTracking: 'TimeTracking',
  WorkflowManagement: 'WorkflowManagement',
  COGS: 'COGS'
}, _defineProperty(_MODULES, "TimeTracking", 'TimeTracking'), _defineProperty(_MODULES, "RequiredField", 'RequiredField'), _defineProperty(_MODULES, "QuickbookPremiumFeatures", 'QuickbookPremiumFeatures'), _defineProperty(_MODULES, "QuickbookDesktop", 'QuickbookDesktop'), _MODULES);
var gettingStartedModule = [MODULES.CreaditCardProcessing, MODULES.Calendar, MODULES.BeforeAfterPhotos, MODULES.TextAlerts, MODULES.BasicReporting];
var standardModule = [].concat(gettingStartedModule, [MODULES.QuickbookOnline, MODULES.TechPay, MODULES.PrePostScanStorage, MODULES.OEMPartsOdering, MODULES.EstifyExport, MODULES.PaintCodes, MODULES.WheelTorqueSpecs, MODULES.EmailHistory, MODULES.ClientPortal, MODULES.Statements, MODULES.DiscountsOnEstimates, MODULES.CSVExporting, MODULES.CustomPdrMatrices, MODULES.Subcontracting, MODULES.ConsumerFinancing]);
var proModule = [].concat(_toConsumableArray(standardModule), [MODULES.DealerPackageManagement, MODULES.BeforeAfterVideos, MODULES.ERACExports, MODULES.BatchImport, MODULES.GroupsFeature, MODULES.TimeTracking, MODULES.WorkflowManagement, MODULES.COGS, MODULES.RequiredField, MODULES.Checklists, MODULES.ServiceChecklists, MODULES.QuickbookPremiumFeatures, MODULES.QuickbookDesktop, MODULES.TimeTracking]);
var tierModule = {
  gettingStarted: gettingStartedModule,
  standard: standardModule,
  pro: proModule,
  enterprise: proModule
};
export var accessModule = function accessModule(company) {
  var _company$stripe;

  var modules = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  var tier = company === null || company === void 0 ? void 0 : (_company$stripe = company.stripe) === null || _company$stripe === void 0 ? void 0 : _company$stripe.tier;
  return tierModule[tier].some(function (module) {
    return modules.includes(module);
  });
};

var useCompany = function useCompany() {
  var _company$stripe2;

  var _useContext = useContext(ReactReduxContext),
      store = _useContext.store;

  var _store$getState = store.getState(),
      _store$getState$main = _store$getState.main,
      company = _store$getState$main.company,
      subsciption = _store$getState$main.subsciption;

  var tier = company === null || company === void 0 ? void 0 : (_company$stripe2 = company.stripe) === null || _company$stripe2 === void 0 ? void 0 : _company$stripe2.tier;

  var accessModuleTier = function accessModuleTier(modules) {
    return tierModule[tier].some(function (module) {
      return modules.includes(module);
    });
  };

  return {
    company: company,
    isPackagePro: tier === TIER.PRO,
    isPackageStandard: tier === TIER.STANDARD,
    isGettingStarted: tier === TIER.GETTING_STARTED,
    accessModule: accessModuleTier
  };
};

export default useCompany;