import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import _ from 'lodash';
import axios from 'axios';
import * as groupServices from "../services/groupsService";
import * as mainServices from "../services/mainServices";
import * as types from "../core/actionTypes";
import { catchError } from "../core/error.handler";
export var getGroups = function getGroups(params) {
  return function (dispatch) {
    dispatch({
      type: types.ACTION_REQUEST
    });
    return groupServices.getGroups(params).then(function (result) {
      var groups = {};
      result.data.groups.forEach(function (group) {
        groups[group._id] = group;
      });
      dispatch({
        type: types.GET_GROUPS_SUCCESS,
        groups: groups
      });
      return result.data.groups;
    })["catch"](function (error) {
      return catchError(error, dispatch, types.GET_GROUPS_FAILURE);
    });
  };
};
export var getGroup = function getGroup(params) {
  return function (dispatch) {
    dispatch({
      type: types.ACTION_REQUEST
    });
    return groupServices.getGroup(params).then(function (result) {
      var group = result.data.group;
      dispatch({
        type: types.GET_GROUP_SUCCESS,
        group: group
      });
      return result.data;
    })["catch"](function (error) {
      return catchError(error, dispatch, types.GET_GROUPS_FAILURE);
    });
  };
};
export var addGroup = function addGroup(group) {
  return function (dispatch) {
    dispatch({
      type: types.ACTION_REQUEST
    });
    var clients = group.clients;
    if (clients) delete group.clients;
    return groupServices.addGroup(group).then(function (result) {
      return result.data.group;
    }).then(function (addedGroup) {
      dispatch({
        type: types.ADD_GROUP_SUCCESS,
        group: addedGroup
      });

      if (clients && clients.length > 0 && addedGroup) {
        return groupServices.editGroupAddClients(_objectSpread({}, _objectSpread({
          _id: addedGroup._id
        }, {
          clients: clients
        }))).then(function (res) {
          return res.data.group;
        });
      }

      return addedGroup;
    }).then(function (groupResult) {
      if (clients.length === 0) {
        return groupResult;
      }

      return groupServices.getGroup({
        groupID: groupResult._id
      }).then(function (res) {
        return res.data.group;
      });
    }).then(function (getResult) {
      dispatch({
        type: types.GET_GROUP_SUCCESS,
        group: getResult
      });
      return getResult;
    })["catch"](function (error) {
      catchError(error, dispatch, types.ADD_GROUP_FAILURE);
    });
  };
};
export var editGroup = function editGroup(group) {
  return function (dispatch, getState) {
    dispatch({
      type: types.ACTION_REQUEST
    });

    var groups = _objectSpread({}, getState().groups.groups);

    return groupServices.editGroup(group).then(function (result) {
      if (groups[group._id]) groups[group._id] = group;
      dispatch({
        type: types.EDIT_GROUP_SUCCESS,
        groups: groups
      });
      return result.data; // if (cb !== undefined) cb(result.data);
    })["catch"](function (error) {
      return catchError(error, dispatch, types.EDIT_GROUP_FAILURE);
    });
  };
};
export function removeClientInGroup(params) {
  return function (dispatch) {
    dispatch({
      type: types.ACTION_REQUEST
    });
    groupServices.removeClientInGroup(params).then(function (result) {
      return dispatch({
        type: types.REMOVE_CLIENT_IN_GROUP_SUCCESS,
        group: result.data.group
      });
    })["catch"](function (error) {
      return catchError(error, dispatch, types.REMOVE_CLIENT_IN_GROUP_FAILURE);
    });
  };
}
export var editGroupAddClients = function editGroupAddClients(group) {
  return function (dispatch) {
    dispatch({
      type: types.ACTION_REQUEST
    });
    return groupServices.editGroupAddClients(group).then(function (result) {
      dispatch({
        type: types.EDIT_GROUP_ADD_CLIENTS_SUCCESS,
        group: result.data.group
      });
      return result.data;
    })["catch"](function (error) {
      return catchError(error, dispatch, types.EDIT_GROUP_ADD_CLIENTS_FAILURE);
    });
  };
};
export var editGroupEditClient = function editGroupEditClient(groupID, client) {
  return function (dispatch) {
    dispatch({
      type: types.ACTION_REQUEST
    });
    return groupServices.editGroupEditClient(groupID, client).then(function (result) {
      dispatch({
        type: types.EDIT_GROUP_EDIT_CLIENT_SUCCESS
      });
      return result.data;
    })["catch"](function (error) {
      return catchError(error, dispatch, types.EDIT_GROUP_EDIT_CLIENT_FAILURE);
    });
  };
};
export var editGroupEditClientAddUsers = function editGroupEditClientAddUsers(group, client) {
  return function (dispatch) {
    dispatch({
      type: types.ACTION_REQUEST
    });
    return groupServices.editGroupEditClientAddUsers(group, client).then(function (result) {
      dispatch({
        type: types.EDIT_GROUP_EDIT_CLIENT_ADD_USERS_SUCCESS
      });
      return result.data;
    })["catch"](function (error) {
      return catchError(error, dispatch, types.EDIT_GROUP_EDIT_CLIENT_ADD_USERS_FAILURE);
    });
  };
};
export var editGroupEditClientEditUser = function editGroupEditClientEditUser(group, client, user) {
  return function (dispatch) {
    dispatch({
      type: types.ACTION_REQUEST
    });
    return groupServices.editGroupEditClientEditUser(group, client, user).then(function (result) {
      dispatch({
        type: types.EDIT_GROUP_EDIT_CLIENT_EDIT_USER_SUCCESS
      });
      return result.data;
    })["catch"](function (error) {
      return catchError(error, dispatch, types.EDIT_GROUP_EDIT_CLIENT_EDIT_USER_FAILURE);
    });
  };
};
export var deleteGroup = function deleteGroup(groupID) {
  return function (dispatch, getState) {
    var groups = _objectSpread({}, getState().groups.groups);

    dispatch({
      type: types.ACTION_REQUEST
    });
    return groupServices.deleteGroup(groupID).then(function (result) {
      delete groups[groupID];
      dispatch({
        type: types.DELETE_GROUP_SUCCESS,
        groups: groups
      });
      return result.data;
    })["catch"](function (error) {
      catchError(error, dispatch, types.DELETE_GROUP_FAILURE);
    });
  };
};
export var changeGroupLogo = function changeGroupLogo(file) {
  return function (dispatch, getState) {
    var companyID = getState().main.company._id;

    dispatch({
      type: types.ACTION_REQUEST
    });
    return mainServices.createSignedRequest(companyID).then(function (result) {
      var headers = {
        'x-amz-acl': 'public-read',
        'Content-Type': 'image/png'
      };
      return axios.put(result.data.signed_request, file, {
        headers: headers
      }).then(function () {
        return result;
      })["catch"](function (error) {
        throw error;
      });
    }).then(function (result) {
      dispatch({
        type: types.UPLOAD_FILE_SUCCESS
      });
      return result.data.url;
    })["catch"](function (error) {
      catchError(error, dispatch, types.CHANGE_COMPANY_LOGO_FAILURE);
    });
  };
};