import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import * as types from "../core/actionTypes";
var initialState = {
  list: []
};

var workOrder = function workOrder() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case types.WORK_ORDER_GET_PDF_REQUEST:
    case types.WORK_ORDER_GET_ALL_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        list: {
          loading: true
        }
      });

    case types.WORK_ORDER_GET_ALL_SUCCESS:
    case types.WORK_ORDER_GET_ALL_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        list: action.payload
      });

    case types.LOGOUT:
      {
        return initialState;
      }

    case types.WORK_ORDER_GET_PDF_FAILURE:
      return _objectSpread(_objectSpread({}, state), {
        error: action.error
      });

    case types.WORK_ORDER_GET_PDF_SUCCESS:
    case types.WORK_ORDER_UPDATE_SUCCESS:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          list: state.list.map(function (workOrder) {
            if (action.payload && workOrder._id === action.payload._id) {
              workOrder = action.payload;
            }

            return workOrder;
          })
        });
      }

    default:
      return _objectSpread(_objectSpread({}, state), {}, {
        success: false,
        error: null
      });
  }
};

export default workOrder;