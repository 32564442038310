import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import * as types from "../core/actionTypes";
import initialState from "./initialState";
import { calculateTimer } from "../core/common";
export default function timerReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState.timerReducer;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  var timerObject;

  switch (action.type) {
    case types.TIMER_RUNNING:
      timerObject = calculateTimer(state.services, state.stages, state.lastStage);
      return _objectSpread(_objectSpread({}, state), {
        totalTimeMS: timerObject.totalTimeMS,
        totalStageMS: timerObject.totalStageMS
      });

    case types.TIMER_INIT:
      timerObject = calculateTimer(action.payload.services, action.payload.stages, action.payload.lastStage);
      return _objectSpread(_objectSpread({}, state), {
        totalTimeMS: timerObject.totalTimeMS,
        services: timerObject.services,
        totalStageMS: timerObject.totalStageMS,
        stages: action.payload.stages,
        lastStage: timerObject.lastStage
      });

    case types.TIMER_UPDATE_SERVICE_RUNNING:
      timerObject = calculateTimer(action.payload.services, action.payload.stages, state.lastStage);
      return _objectSpread(_objectSpread({}, state), {
        totalTimeMS: timerObject.totalTimeMS,
        services: action.payload.services,
        stages: action.payload.stages
      });

    default:
      var newState = _.cloneDeep(state);

      return newState;
  }
}
;