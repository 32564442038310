import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { cloneDeep, assign, forEach } from 'lodash';
import * as types from "../core/actionTypes";
import initialState from "./initialState"; // IMPORTANT: Note that with Redux, state should NEVER be changed.
// State is considered immutable. Instead,
// create a copy of the state passed and set new values on the copy.
// Note that I'm using Object.assign to create a copy of current state
// and update values on the copy.

export default function clientReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState.clientReducer;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  var defaultState = cloneDeep(initialState.clientReducer);

  switch (action.type) {
    case types.GET_CLIENTS_SUCCESS:
      {
        return assign({}, state, {
          clients: action.clients,
          totalClients: action.totalClients
        });
      }

    case types.GET_CLIENT_DETAIL_SUCCESS:
      {
        return assign({}, state, {
          client: action.client
        });
      }

    case types.UPDATE_CLIENT_SUCCESS:
      {
        return assign({}, state, {
          client: action.client,
          editSuccess: true
        });
      }

    case types.REQUEST_CLIENTS_FAILURE:
      {
        // const newState = handleCRUDAction(state, action);
        return defaultState;
      }

    case types.ADD_CLIENT_SUCCESS:
      {
        return assign({}, state, {
          addSuccess: true
        });
      }

    case types.RESET_STATE:
      {
        var stateClone = assign({}, state);
        forEach(action.fields, function (field) {
          stateClone[field] = defaultState[field];
        });
        return stateClone;
      }

    case types.RESET_ERROR:
      {
        return assign({}, state, {
          error: {}
        });
      }

    case types.REST_EDIT_SUCCESS:
      {
        return assign({}, state, {
          editSuccess: false
        });
      }

    case types.REST_ADD_SUCCESS:
      {
        return assign({}, state, {
          addSuccess: false
        });
      }

    case types.EDIT_CLIENT_SUCCESS:
    case types.EDIT_CLIENT_FAILURE:
      {
        return assign({}, state, {
          client: action.client,
          editSuccess: true
        });
      }

    case types.DISABLE_CLIENT_FAILURE:
    case types.ENABLE_CLIENT_FAILURE:
      {
        return assign({}, state, {
          loading: false
        });
      }

    case types.GET_REMINDERS_SUCCESS:
    case types.GET_REMINDERS_FAILURE:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          reminders: action.payload
        });
      }

    case types.DISABLE_CLIENT_SUCCESS:
    case types.ENABLE_CLIENT_SUCCESS:
      {
        return assign({}, state, {
          client: action.client
        });
      }

    case types.CREATE_REMINDER_REQUEST:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          reminder: {
            loading: true
          }
        });
      }

    case types.CREATE_REMINDER_SUCCESS:
    case types.CREATE_REMINDER_FAILURE:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          reminder: action.payload
        });
      }

    case types.UPDATE_REMINDER_REQUEST:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          reminder: {
            loading: true
          }
        });
      }

    case types.UPDATE_REMINDER_SUCCESS:
    case types.UPDATE_REMINDER_FAILURE:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          reminder: action.payload
        });
      }

    case types.GET_REGARDING_VEHICLE_SUCCESS:
    case types.GET_REGARDING_VEHICLE_FAILURE:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          regardingVehicles: action.payload
        });
      }

    case types.LOGOUT:
      {
        return defaultState;
      }

    default:
      return state;
  }
}