import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

var useYupForm = function useYupForm(schema, defaultValues) {
  return useForm({
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
    mode: 'onChange',
    reValidateMode: 'onChange'
  });
};

export default useYupForm;