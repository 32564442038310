import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import _ from 'lodash';
import * as types from "../core/actionTypes";
var initialState = {
  costOfGoods: [{
    title: '',
    value: ''
  }],
  expenseAccounts: [{
    title: '',
    value: ''
  }],
  qbClasses: [{
    title: '',
    value: ''
  }],
  taxCodes: [{
    title: '',
    value: ''
  }],
  qbWarningModal: false,
  companyInfo: {
    country: 'US'
  },
  connectedQB: false
};

var quickbooks = function quickbooks() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case types.GET_QB_COST_OF_GOODS_FAILURE:
      {
        return _objectSpread(_objectSpread({}, state), {
          error: action.error
        });
      }

    case types.GET_QB_COST_OF_GOODS_SUCCESS:
      {
        return _objectSpread(_objectSpread({}, state), {
          costOfGoods: action.payload
        });
      }

    case types.GET_QB_TAX_CODES_SUCCESS:
      {
        return _objectSpread(_objectSpread({}, state), {
          taxCodes: action.payload
        });
      }

    case types.GET_QB_COMPANY_INFO_SUCCESS:
      {
        return _objectSpread(_objectSpread({}, state), {
          companyInfo: action.payload
        });
      }

    case types.GET_QB_COMPANY_INFO_FAILURE:
      {
        return _objectSpread(_objectSpread({}, state), {
          error: action.error
        });
      }

    case types.GET_EXPENSE_ACCOUNTS_FAILURE:
      {
        return _objectSpread(_objectSpread({}, state), {
          error: action.error
        });
      }

    case types.GET_EXPENSE_ACCOUNTS_SUCCESS:
      {
        return _objectSpread(_objectSpread({}, state), {
          expenseAccounts: action.payload
        });
      }

    case types.GET_CLASSES_FAILURE:
      {
        return _objectSpread(_objectSpread({}, state), {
          error: action.error
        });
      }

    case types.GET_CLASSES_SUCCESS:
      {
        return _objectSpread(_objectSpread({}, state), {
          qbClasses: action.payload
        });
      }

    case types.SYNC_QB_FAILURE:
      {
        return _objectSpread(_objectSpread({}, state), {
          error: action.error
        });
      }

    case types.SYNC_QB_SUCCESS:
      {
        return _objectSpread(_objectSpread(_objectSpread({}, state), {
          connectedQB: true
        }), {
          success: true
        });
      }

    case types.QB_REFRESH_TOKEN_EXPIRED:
      {
        return _objectSpread(_objectSpread({}, state), {
          connectedQB: false
        });
      }

    case types.DISCONNECT_QB_SUCCESS:
      {
        return _objectSpread(_objectSpread({}, state), {
          connectedQB: action.connectedQB
        });
      }

    case types.RESET_ERROR:
      {
        return _objectSpread(_objectSpread({}, state), {
          error: {}
        });
      }

    case types.DUPLICATE_INVOICE_FAILURE:
      {
        return _objectSpread(_objectSpread({}, state), {
          qbWarningModal: true
        });
      }

    case types.GET_INVOICES_SUCCESS:
      {
        return _objectSpread(_objectSpread({}, state), {
          connectedQB: action.connectedQB
        });
      }

    case types.HIDE_DUPLICATE_WARNING_MODAL:
      {
        return _objectSpread(_objectSpread({}, state), {
          qbWarningModal: false
        });
      }

    case types.ADD_TO_QB_SUCCESS:
      {
        return _objectSpread(_objectSpread({}, state), {
          qbWarningModal: false
        });
      }

    case types.LOAD_COMPANY:
      {
        var accessToken = _.get(action.company, 'quickBooks.accessToken');

        var realmID = _.get(action.company, 'quickBooks.realmID');

        var refreshNotExpired = _.get(action.company, 'quickBooks.refreshTokenExpiresIn') - Math.ceil(new Date().getTime() / 1000) > 0;
        var connectedQB = !!(realmID && accessToken) && refreshNotExpired;
        return _objectSpread(_objectSpread({}, state), {
          connectedQB: connectedQB
        });
      }

    case types.LOGOUT:
      {
        return initialState;
      }

    default:
      return state;
  }
};

export default quickbooks;