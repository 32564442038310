import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";

/* global _ */
import { USER_ROLES, USER_STATUS, PERMISSION } from "../../../../core/constants";
import _ from 'lodash';
var optionsRole = [{
  value: 'All',
  label: 'All'
}].concat(_toConsumableArray(_.map(USER_ROLES, function (name) {
  return {
    value: name,
    label: name
  };
})));
var optionsStatus = [{
  value: 'All',
  label: 'All'
}].concat(_toConsumableArray(_.map(USER_STATUS, function (name) {
  return {
    value: name,
    label: name
  };
})));
var optionsAccess = [{
  value: 'All',
  label: 'All'
}].concat(_toConsumableArray(_.map(Object.keys(PERMISSION), function (name) {
  var n = name.toLowerCase().charAt(0).toUpperCase() + name.slice(1);
  return {
    value: n,
    label: n
  };
})));
export { optionsRole, optionsStatus, optionsAccess };