import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import exportReducer from "./exportReducer";
import main from "./mainReducer";
import matrix from "./matrixReducer";
import payLoad from "./payLoadReducer";
import user from "./userReducer";
import client from "./clientReducer";
import reporting from "./reportingReducer";
import report from "./reportReducer";
import payable from "./payableReducer";
import invoice from "./invoiceReducer";
import estimate from "./estimateReducer";
import estimateDetail from "./estimateDetailReducer";
import workOrder from "./workOrderReducer";
import quickbooks from "./quickbooksReducer";
import groups from "./groupsReducer";
import plaid from "./plaidReducer";
import payment from "./paymentReducer";
import cogs from "./cogsReducer";
import workflow from "./workflowReducer";
import stage from "./stageReducer";
import time from "./timerReducer";
export default function createRootReducer(history) {
  return combineReducers({
    router: connectRouter(history),
    main: main,
    matrix: matrix,
    payLoad: payLoad,
    user: user,
    client: client,
    payable: payable,
    reporting: reporting,
    report: report,
    invoice: invoice,
    estimate: estimate,
    workOrder: workOrder,
    quickbooks: quickbooks,
    groups: groups,
    plaid: plaid,
    "export": exportReducer,
    payment: payment,
    cogs: cogs,
    workflow: workflow,
    stage: stage,
    time: time,
    estimateDetail: estimateDetail
  });
}