import { history } from "../store/configureStore";
import * as types from "./actionTypes";
import { unAuthorized } from "../actions/mainActions";
export function createErrorObject(message, status) {
  var error = {};
  error.data = {};
  error.success = false;
  error.message = message;
  error.status = status;
  error.data.message = message;
  error.data.status = status;
  return error;
}
export function checkError(data, status) {
  var error = {
    message: ''
  };
  error.message = data.message || '';
  error.status = status;
  return error;
}
export function catchError(error, dispatch, type) {
  var errorResponse = error;

  if (!(errorResponse && errorResponse.data && errorResponse.data.message)) {
    errorResponse = createErrorObject(errorResponse, 400);
  }

  var _errorResponse = errorResponse,
      data = _errorResponse.data;

  if (data.message === 'Expired company subscription, please login to the admin portal and renew your subscription to continue.') {
    // return dispatch(history.push('/subscription?subscription=expired'));
    return dispatch({
      type: types.INACTIVE_COMPANY,
      error: checkError(data, errorResponse.status)
    });
  }

  if (errorResponse.status === 401) {
    localStorage.removeItem('access_token');
    localStorage.removeItem('currency');
    if (window.location.pathname !== '/login' && data.message !== 'Invalid password') window.location.reload();

    try {
      if (!errorResponse.data.err.message) throw new Error();
      if (errorResponse.data.err.message === 'Session expired') return unAuthorized(dispatch);
    } catch (e) {// empty
    } // localStorage.removeItem('access_token');
    // localStorage.removeItem('currency');

  }

  return dispatch({
    type: type,
    error: checkError(data, errorResponse.status)
  });
}