import HttpRequest from "../utils/HttpRequest";
import * as routesUtils from "../utils/RoutesUtils";
import { API } from "../core/app.routes";
export function disconnectQB() {
  var path = routesUtils.getApiPath(API.DISCONNECT_QB);
  return HttpRequest.post(path, {});
}
export function connectQB() {
  var path = routesUtils.getApiPath(API.ADD_TO_QB);
  return HttpRequest.post(path, {});
}
export function loadCompany() {
  var path = routesUtils.getApiPath(API.COMPANY);
  return HttpRequest.get(path);
}
export function loadCompanies(params) {
  var path = routesUtils.getApiPath(API.COMPANIES, params);
  return HttpRequest.get(path);
}
export function addChildCompanies() {
  var path = routesUtils.getApiPath(API.INVITEURL);
  return HttpRequest.get(path);
}
export function updateSessionWithCompany(params) {
  var path = routesUtils.getApiPath(API.COMPANIES_DETAIL_SESSION, params);
  return HttpRequest.post(path);
}
export function loadCompanyDetail(params) {
  var path = routesUtils.getApiPath(API.COMPANIES_DETAIL, params);
  return HttpRequest.get(path);
}
export function loadSubscription() {
  var path = routesUtils.getApiPath(API.SUBSCRIPTION);
  return HttpRequest.get(path);
}
export function getIncomingInvoice(params) {
  var path = routesUtils.getApiPath(API.INCOMING_INVOICE, params);
  return HttpRequest.get(path);
}
export function editCompany(company) {
  var params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var path = routesUtils.getApiPath(API.COMPANY, params);
  return HttpRequest.put(path, company);
}
export function editStripe(stripe, params) {
  var path = routesUtils.getApiPath(API.STRIPE, params);
  return HttpRequest.put(path, stripe);
}
export function createSignedRequest(id) {
  var path = routesUtils.getApiPath(API.SIGN_S3, {
    companyID: id
  });
  return HttpRequest.post(path, {});
}
export function updatePayment(id, params) {
  var path = routesUtils.getApiPath(API.UPDATE_PAYMENT, params);
  return HttpRequest.put(path, {
    id: id
  });
}
export function addNewPayment(id) {
  var params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var path = routesUtils.getApiPath(API.ADD_NEW_PAYMENT, params);
  return HttpRequest.post(path, {
    id: id
  });
}
export function removeCard(id, params) {
  var path = routesUtils.getApiPath(API.REMOVE_SOURCE, params);
  return HttpRequest["delete"](path, {
    id: id
  });
}
export function getSignedRequest(client) {
  var path = routesUtils.getApiPath(API.IMPORT_CSV);
  return HttpRequest.post(path, client);
}
export function uploadFile(file, signedRequest) {
  return HttpRequest.putAmzn(file, signedRequest);
}
export function importCSV(fileName, client) {
  var path = routesUtils.getApiPath(API.POST_IMPORT_CSV);
  return HttpRequest.post(path, {
    fileName: fileName,
    client: client
  }); // Do I need to send the file name?
}
export function uploadCSV(file, signedRequest) {
  return HttpRequest.putAmzn(file, signedRequest);
}
export function getTableauTicket() {
  var path = routesUtils.getApiPath(API.TABLEAU_SSO);
  return HttpRequest.get(path);
}
export function getJobId(id) {
  var path = routesUtils.getApiPath(API.GET_JOB);
  return HttpRequest.get(path + id);
} // move to qbServices

export function connectToQuickBooks(companyID) {
  var path = routesUtils.getApiPath(API.CONNECT_TO_QUICKBOOKS, {
    companyID: companyID
  });
  return HttpRequest.post(path, {});
}
export function oath2Callback(data, companyID) {
  var path = routesUtils.getApiPath(API.OATH2_CALLBACK, {
    companyID: companyID
  });
  return HttpRequest.post(path, data);
}
export function disconnectFromQuickBooks(companyID) {
  var path = routesUtils.getApiPath(API.DISCONNECT_FROM_QUICKBOOKS, {
    companyID: companyID
  });
  return HttpRequest.post(path, {});
}
export function wisetackSignUp(companyID) {
  var path = routesUtils.getApiPath(API.WISETACK_SIGNUP, {
    companyID: companyID
  });
  return HttpRequest.post(path, {});
}