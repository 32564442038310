import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import objectAssign from 'object-assign';
import _ from 'lodash';
import * as types from "../core/actionTypes"; // import {necessaryDataIsProvidedToCalculateSavings, calculateSavings} from '../utils/fuelSavings';

import initialState from "./initialState";
import { handleCRUDAction } from "../core/common"; // IMPORTANT: Note that with Redux, state should NEVER be changed.
// State is considered immutable. Instead,
// create a copy of the state passed and set new values on the copy.
// Note that I'm using Object.assign to create a copy of current state
// and update values on the copy.

export default function mainReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState.mainReducer;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  var newState;

  var defaultState = _.cloneDeep(initialState.mainReducer);

  switch (action.type) {
    case types.USER_NOT_ADMIN:
      {
        return _.assign({}, state, {
          isNotAdmin: true
        });
      }

    case types.LOGIN_SUCCESS:
      {
        newState = handleCRUDAction(state, action);

        if (newState.success) {
          newState.success = false;
          newState.currentUser = action.currentUser;
          newState.company = action.company;
          newState.error = {};
        }

        return newState;
      }

    case types.SIGNUP_SUCCESS:
    case types.SIGNUP_FAILURE:
      {
        newState = handleCRUDAction(state, action);
        return newState;
      }

    case types.LOGIN_FAILURE:
      {
        return _.assign({}, state, {
          error: action.error
        });
      }

    case types.USER_NOT_ADMIN_REQUEST_FAILURE:
      {
        return _.assign({}, state, {
          error: {
            message: 'User is not admin'
          }
        });
      }

    case types.LOGOUT:
      {
        return defaultState;
      }

    case types.UNAUTHORIZED_FAILURE:
      {
        if (!localStorage.getItem('new_user')) {
          newState = objectAssign({}, state, {
            currentUser: {},
            company: {},
            error: action.type
          });
        } else {
          newState = state;
        }

        return newState;
      }

    case types.INACTIVE_COMPANY:
      {
        newState = _objectSpread(_objectSpread({}, state), {
          error: {
            message: 'INACTIVE_COMPANY'
          }
        });
        return newState;
      }

    case types.CHECK_EMAIL:
      {
        return _.assign({}, state, {
          isValidEmail: !action.error,
          emailIsExists: !!action.error
        });
      }

    case types.LOAD_COMPANY:
      {
        newState = handleCRUDAction(state, action);

        if (newState.success) {
          newState.success = false;
          newState.company = action.company;
          newState.currentUser = action.currentUser;
          window.company = action.company;
        }

        return newState;
      }

    case types.SWITCH_COMPANY_SUCCESS:
      {
        newState = handleCRUDAction(state, action);
        newState.success = true;
        newState.company = action.company;
        window.company = action.company;
        return newState;
      }

    case types.SWITCH_COMPANY_FAILURE:
      {
        return _objectSpread(_objectSpread({}, state), {
          loading: false
        });
      }

    case types.SYNC_QB_SUCCESS:
      {
        return _objectSpread(_objectSpread({}, state), {
          company: action.company
        });
      }

    case types.DISCONNECT_QB_SUCCESS:
      {
        return _objectSpread(_objectSpread({}, state), {
          company: action.company
        });
      }

    case types.EDIT_COMPANY_SUCCESS:
    case types.EDIT_COMPANY_FAILURE:
      {
        newState = handleCRUDAction(state, action);

        if (newState.success) {
          newState.company = action.company;
          window.company = action.company;
        }

        return newState;
      }

    case types.LOAD_SUBSCRIPTION_FAILURE:
    case types.LOAD_SUBSCRIPTION_SUCCESS:
      {
        newState = handleCRUDAction(state, action);

        if (newState.success) {
          newState.success = false;
          newState.subscription = action.subscription;
        }

        return newState;
      }

    case types.PURCHASE_CREDITS_SUCCESS:
      {
        return _.assign({}, state, {
          company: action.company
        });
      }

    case types.UPDATE_SUBSCRIPTION_FAILURE:
    case types.UPDATE_SUBSCRIPTION_SUCCESS:
      {
        newState = handleCRUDAction(state, action);

        if (newState.success) {
          newState.subscription = action.subscription;
        }

        return newState;
      }

    case types.UPDATE_PAYMENT_FAILURE:
    case types.UPDATE_PAYMENT_SUCCESS:
      {
        newState = handleCRUDAction(state, action);

        if (newState.success) {
          newState.subscription = action.subscription;
        }

        return newState;
      }

    case types.GET_PRICE_A_DENT:
      {
        newState = handleCRUDAction(state, action);

        if (newState.success) {
          newState.success = false;
          newState.priceADent = action.priceADent;
        }

        return newState;
      }

    case types.UPDATE_PRICE_A_DENT_FAILURE:
    case types.UPDATE_PRICE_A_DENT_SUCCESS:
      {
        newState = handleCRUDAction(state, action);

        if (newState.success) {
          newState.priceADent = action.priceADent;
        }

        return newState;
      }

    case types.GET_SIGNED_REQUEST_FAILURE:
    case types.GET_SIGNED_REQUEST_SUCCESS:
      {
        newState = handleCRUDAction(state, action);

        if (newState.success) {
          newState.signedResponse = action.signedResponse;
        }

        return newState;
      }

    case types.UPLOAD_FILE_FAILURE:
    case types.UPLOAD_FILE_SUCCESS:
      {
        newState = handleCRUDAction(state, action);

        if (newState.success) {
          newState.uploadedFile = action.uploadedFile;
        }

        return newState;
      }

    case types.IMPORT_CSV_SUCCESS:
      {
        newState = handleCRUDAction(state, action);

        if (newState.success) {
          newState.importedFile = action.importedFile;
        }

        return newState;
      }

    case types.RESET_IMPORT_CSV:
      {
        return _.assign({}, state, {
          uploadedFile: false,
          importedFile: false,
          signedResponse: {}
        });
      }

    case types.RESET_ERROR:
      {
        return _.assign({}, state, {
          error: {}
        });
      }

    case types.RESET_STATE:
      {
        var stateClone = _.assign({}, state);

        _.forEach(action.fields, function (field) {
          stateClone[field] = defaultState[field];
        });

        return stateClone;
      }

    case types.PUBLIC_TOKEN_FAILURE:
      {
        return _objectSpread(_objectSpread({}, state), {
          error: action.error
        });
      }

    case types.GET_STRIPE_LOGIN_LINK_FAILURE:
      {
        return _objectSpread(_objectSpread({}, state), {
          error: action.error
        });
      }

    case types.PUBLIC_TOKEN_SUCCESS:
      {
        return _objectSpread(_objectSpread({}, state), {
          success: true
        });
      }

    case types.COMPLETE_STRIPE_OAUTH:
      {
        return state;
      }

    case types.GET_STRIPE_LOGIN_LINK:
      {
        return state;
      }

    case types.UPDATE_SERVICES_USER_SUCCESS:
      {
        return _objectSpread(_objectSpread({}, state), {
          loading: false
        });
      }

    case types.GET_INCOMING_INVOICE_SUCCESS:
      {
        return _objectSpread(_objectSpread({}, state), {
          incomingInvoice: action.incomingInvoice
        });
      }

    case types.UPDATE_SERVICES_USER_FAILURE:
      {
        return _objectSpread(_objectSpread({}, state), {
          error: action.error,
          loading: false
        });
      }

    default:
      return _objectSpread(_objectSpread({}, state), {}, {
        success: false,
        error: null
      });
  }
}