import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk'; // import { logger } from 'redux-logger';

import { createLogger } from 'redux-logger';
import { createBrowserHistory } from 'history';
import createRootReducer from "../reducers";
export var history = createBrowserHistory();
var rootReducer = createRootReducer(history);
var logger = createLogger({
  collapsed: true
});

var configureStore = function configureStore() {
  var store = createStore(rootReducer, applyMiddleware(thunk));

  if (process.env.NODE_ENV !== 'production') {
    store = createStore(rootReducer, applyMiddleware(thunk, logger));
  }

  return store;
};

export default configureStore;