import * as types from "../core/actionTypes";
export var timeIncrease = function timeIncrease() {
  return function (dispatch) {
    dispatch({
      type: types.TIMER_RUNNING
    });
  };
};
export var initTime = function initTime(services, stages, lastStage) {
  return function (dispatch) {
    dispatch({
      type: types.TIMER_INIT,
      payload: {
        services: services,
        stages: stages,
        lastStage: lastStage
      }
    });
  };
};