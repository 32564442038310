import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import _ from 'lodash';
import * as types from "../core/actionTypes";
import initialState from "./initialState";

var plaid = function plaid() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState.plaid;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  var defaultState = _.cloneDeep(initialState.plaid);

  switch (action.type) {
    case types.PUBLIC_TOKEN_SUCCESS:
      {
        return _objectSpread(_objectSpread({}, state), {
          error: {}
        });
      }
    // case types.PUBLIC_TOKEN_FAILURE: {
    // 	return { ...state, ...{ error: action.error } };
    // }

    case types.RESET_ERROR:
      {
        return _objectSpread(_objectSpread({}, state), {
          error: {}
        });
      }

    case types.LOGOUT:
      {
        return defaultState;
      }

    default:
      return state;
  }
};

export default plaid;