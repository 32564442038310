export function roundNumber(numberToRound, numberOfDecimalPlaces) {
  if (numberToRound === 0) {
    return 0;
  }

  if (!numberToRound) {
    return '';
  }

  var scrubbedNumber = numberToRound.toString().replace('$', '').replace(',', '');
  return Math.round(scrubbedNumber * Math.pow(10, numberOfDecimalPlaces)) / Math.pow(10, numberOfDecimalPlaces);
}
export function formatMoney(n, c, d, t) {
  var c = isNaN(c = Math.abs(c)) ? 2 : c,
      d = d == undefined ? "." : d,
      t = t == undefined ? "," : t,
      s = n < 0 ? "-" : "",
      i = String(parseInt(n = Math.abs(Number(n) || 0).toFixed(c))),
      j = (j = i.length) > 3 ? j % 3 : 0;
  return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
}
; // adds array of values passed.

export function addArray(values) {
  var total = values.reduce(function (previousValue, currentValue) {
    return previousValue + parseInt(convertToPennies(currentValue), 10); // do math in pennies to assure accuracy.
  }, 0);
  return total / 100; // convert back into dollars
}
export function convertToPennies(value) {
  if (value === 0) {
    return 0;
  }

  var dollarValue = parseFloat(value);
  dollarValue = roundNumber(dollarValue, 2); // round to 2 decimal places.

  var dollarValueContainsDecimal = dollarValue.toString().indexOf('.') !== -1;
  return dollarValueContainsDecimal ? parseInt(dollarValue.toString().replace('.', ''), 10) : parseInt(dollarValue, 10) * 100;
}