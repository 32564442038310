import _typeof from "@babel/runtime/helpers/typeof";
import moment from 'moment';
import { DATE_TIME, CURRENCYS } from "../core/constants";
export function getFormattedDateTime() {
  var date = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : new Date();
  return "".concat(date.getMonth() + 1, "/").concat(date.getDate(), " ").concat(date.getHours(), ":").concat(padLeadingZero(date.getMinutes()), ":").concat(padLeadingZero(date.getSeconds()));
}
export function padLeadingZero(value) {
  return value > 9 ? value : "0".concat(value);
}
export function formatToUTCOffset(date) {
  if (_typeof(date) == 'object') {
    return moment(date).format();
  }

  return undefined;
}
export function formatToLocalTime(date) {
  return moment(date).format(DATE_TIME.US);
}
export function formatToLocalTimeShort(date) {
  return moment(date).format('MM/DD/YY');
}
export function formatDate(date, local) {
  return local === CURRENCYS.USD ? moment(date).format('LL') : moment(date).format('D MMMM YYYY');
}