export var initStripeInputFields = {
  cardNumber: {
    elementType: 'cardNumber',
    error: undefined,
    value: undefined,
    empty: true,
    complete: false,
    brand: ''
  },
  cardExpiry: {
    elementType: 'cardExpiry',
    error: undefined,
    value: undefined,
    empty: true,
    complete: false
  },
  cardCvc: {
    elementType: 'cardCvc',
    error: undefined,
    value: undefined,
    empty: true,
    complete: false
  }
};
export var initInputFields = {
  name: '',
  postalCode: '',
  emailAddress: '',
  phoneNumber: '',
  notes: ''
};
export var paymentOptions = [{
  value: 'cash',
  title: 'Cash'
}, {
  value: 'check',
  title: 'Check'
}, {
  value: 'creditcard',
  title: 'Offline Credit Card'
}, {
  value: 'other',
  title: 'Other'
}];
export var inputDictionary = {
  emailAddress: 'Email',
  name: 'Name',
  phoneNumber: 'Phone Number',
  postalCode: 'Postal Code'
};
export var stripeCardDictionary = {
  cardNumber: 'Card Number',
  cardExpiry: 'MM/YY',
  cardCvc: 'CCV'
};