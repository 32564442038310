import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import _ from 'lodash';
import * as types from "../core/actionTypes"; // import {necessaryDataIsProvidedToCalculateSavings, calculateSavings} from '../utils/fuelSavings';

import initialState from "./initialState";
import { handleCRUDAction } from "../core/common"; // IMPORTANT: Note that with Redux, state should NEVER be changed.
// State is considered immutable. Instead,
// create a copy of the state passed and set new values on the copy.
// Note that I'm using Object.assign to create a copy of current state
// and update values on the copy.

export default function userReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState.userReducer;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  var newState;

  var defaultState = _.cloneDeep(initialState.userReducer);

  state = _objectSpread(_objectSpread({}, state), {}, {
    editSuccess: false,
    addSuccess: false,
    error: null
  });

  switch (action.type) {
    case types.GET_USERS_SUCCESS:
      {
        var user = _objectSpread(_objectSpread({}, state.user), {
          users: action.users
        }); // return _.assign({}, state, { users: action.users })


        return _objectSpread(_objectSpread({}, state), user);
      }

    case types.GET_ASSIGNABLE_USERS_SUCCESS:
      {
        return _.assign({}, state, {
          assignableUsers: action.assignableUsers
        });
      }

    case types.GET_CURRENT_USER_SUCCESS:
      {
        var _user = _objectSpread(_objectSpread({}, state.user), {
          currentUser: action.currentUser
        });

        return _objectSpread(_objectSpread({}, state), _user); // return _.assign({}, state, { currentUser: action.currentUser })
      }

    case types.GET_USER_DETAIL_SUCCESS:
      {
        return _.assign({}, state, {
          user: action.user
        });
      }

    case types.ADD_USER_SUCCESS:
      {
        newState = handleCRUDAction(state, action);
        return _objectSpread(_objectSpread({}, newState), {}, {
          addSuccess: true
        });
      }

    case types.UPDATE_USER_SUCCESS:
      {
        newState = handleCRUDAction(state, action);
        return _objectSpread(_objectSpread({}, newState), {}, {
          editSuccess: true
        });
      }

    case types.RESET_STATE:
      {
        var stateClone = _.assign({}, state);

        _.forEach(action.fields, function (field) {
          stateClone[field] = defaultState[field];
        });

        return stateClone;
      }

    case types.REQUEST_USER_FAILURE:
    case types.ADD_USER_FAILURE:
      {
        return _.assign({}, state, {
          error: action.error
        });
      }

    case types.LOGOUT:
      {
        return defaultState;
      }

    case types.RESET_ERROR:
    case types.REST_EDIT_SUCCESS:
    default:
      return _objectSpread(_objectSpread({}, state), {}, {
        addSuccess: false,
        editSuccess: false,
        error: null
      });
  }
}