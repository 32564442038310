import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import * as types from "../core/actionTypes";
import initialState from "./initialState";

var stageReducer = function stageReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState.stagesReducer;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  var _ref = action.payload || {
    list: []
  },
      list = _ref.list;

  switch (action.type) {
    case types.STAGE_SET_VEHICLES:
      return _objectSpread(_objectSpread({}, state), {}, {
        vehicles: state.vehicles.concat(list)
      });

    case types.STAGE_UPDATE_VEHICLE_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        vehicles: state.vehicles.map(function (vehicle) {
          if (vehicle._id === action.payload.vehicle._id) {
            vehicle = action.payload.vehicle;
          }

          return vehicle;
        })
      });

    case types.STAGE_GET_ESTIMATE_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        estimates: state.estimates.concat(list)
      });

    case types.STAGE_UPDATE_ESTIMATE_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        estimates: state.estimates.map(function (estimate) {
          if (estimate._id === action.payload.estimate._id) {
            estimate = action.payload.estimate;
          }

          return estimate;
        })
      });

    case types.STAGE_GET_WORK_ORDER_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        workOrders: state.workOrders.concat(list)
      });

    case types.STAGE_UPDATE_WORK_ODER_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        workOrders: state.workOrders.map(function (workOrder) {
          if (workOrder._id === action.payload.workOrder._id) {
            workOrder = action.payload.workOrder;
          }

          return workOrder;
        })
      });

    case types.STAGE_GET_INVOICE_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        invoices: state.invoices.concat(list)
      });

    case types.STAGE_UPDATE_INVOICE_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        invoices: state.invoices.map(function (invoice) {
          if (invoice._id === action.payload.invoice._id) {
            invoice = action.payload.invoice;
          }

          return invoice;
        })
      });

    case types.STAGE_SET_VEHICLE_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        vehicle: action.payload.vehicle
      });

    case types.RESET_STAGE:
      return _objectSpread(_objectSpread({}, state), {}, {
        estimates: [],
        workOrders: [],
        invoices: [],
        vehicle: {},
        vehicles: []
      });

    default:
      return _objectSpread(_objectSpread({}, state), {}, {
        error: null
      });
  }
};

export default stageReducer;