import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";

var _ref;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

export var SERVER_DATA = 'https://s3.amazonaws.com/mobiletechrxus/';
export var SERVER_PHOTO_DATA = 'https://mobiletechrxus.s3.amazonaws.com';
export var PAGE_SIZE = [10, 20, 50, 100];
export var PAGE_SIZE_DEFAULT = 100;
export var MAXIMUM_ACTIVE_USER_GETTING_STARTED_PLAN = 3;
export var STAGE_GROUPING = {
  ONE: 1,
  TWO: 2,
  THREE: 3
};
export var WORKFLOW_DOCTYPE = {
  INVOICE: 'invoice',
  ESTIMATE: 'estimate',
  WORKORDER: 'workOrder'
};
export var METHOD_TYPES = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE'
};
export var FILTER_DATE_RANGE = {
  Today: 'Today',
  Yesterday: 'Yesterday',
  ThisWeek: 'This Week',
  LastWeek: 'Last Week',
  ThisMonth: 'This Month',
  LastMonth: 'Last Month',
  ThisYear: 'This Year',
  LastYear: 'Last Year',
  AllTime: 'All Time',
  Custom: 'Custom',
  TODAY: 'TODAY',
  YESTERDAY: 'YESTERDAY',
  THISWEEK: 'THIS WEEK',
  LASTWEEK: 'LAST WEEK',
  THISMONTH: 'THIS MONTH',
  LASTMONTH: 'LAST MONTH',
  THISYEAR: 'THIS YEAR',
  LASTYEAR: 'LAST YEAR',
  ALLTIME: 'ALL TIME',
  CUSTOM: 'CUSTOM'
};
export var FILTER_CLIENT_STATUS = {
  ALL: 'ALL',
  RETAIL: 'RETAIL',
  WHOLESALE: 'WHOLESALE'
};
export var FILTER_USER_STATUS = {
  ALL: 'ALL',
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE'
};
export var FILTER_PAYMENT_STATUS = {
  ALL: 'ALL',
  PAID: 'PAID',
  UNPAID: 'UNPAID',
  PARTIAL: 'PARTIAL'
};
export var FILTER_STATUS = {
  ALL: 'ALL',
  OPEN: 'OPEN',
  CLOSED: 'CLOSED'
};
export var FILTER_PURCHASE_ORDER = {
  ALL: 'ALL',
  YES: 'YES PO',
  NO: 'NO PO'
};
export var CATEGORY_USER = {
  ALL: 'ALL',
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE'
};
export var CATEGORY_CLIENT = {
  ALL: 'ALL',
  RETAIL: 'RETAIL',
  WHOLESALE: 'WHOLESALE'
};
export var CURRENCYS = {
  USD: 'usd',
  GBP: 'gbp',
  CAD: 'cad',
  AUD: 'aud',
  NZD: 'nzd',
  MXN: 'mxn',
  EUR: 'eur',
  ZAR: 'zar',
  CHF: 'chf',
  BRL: 'brl',
  TRY: 'try',
  DOP: 'dop',
  SEK: 'sek',
  SAR: 'sar',
  AED: 'aed'
}; // export const LANGUAGE = {
// 	US: 'english(us)',
// 	UK: 'uk',
// 	'Spanish (es)': 'Spanish(es)',
// 	'French Canada (fr-ca)': 'French-Canada(fr-ca)',
// };

export var LANGUAGE = {
  'English (US)': 'english(us)',
  'English (UK)': 'english(uk)',
  'Spanish (es)': 'spanish',
  'French Canada (fr-ca)': 'french canadian'
};
export var PERMISSION = {
  LIMITED: 4,
  HAIL_LIMITED: 3,
  VIEW_ONLY: 0,
  BASIC: 1,
  MANAGER: 2,
  ESTIMATOR: 5
};
export var PRICING_METHOD = {
  PER_VEHICLE: {
    name: 'Per Vehicle',
    value: 4
  },
  FLAT_FEE: {
    name: 'Flat Fee',
    value: 0
  },
  PER_FOOT: {
    name: 'Per Foot',
    value: 2
  },
  BY_THE_HOUR: {
    name: 'By The Hour',
    value: 3
  }
};
export var USER_STATUS = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive'
};
export var ALL = 'All';
export var USER_ROLES = {
  ADMIN: 'Admin',
  TECH: 'Tech',
  SUBCONTRACTOR: 'Subcontractor'
};
export var PAY_TYPE = {
  PER_VEHICLE: 0,
  NET_PERCENT: 1,
  GROSS_PAY: 2,
  SALARY: 3
};
export var USER_TYPE = {
  SUBCONTRACTOR: 'Subcontractor',
  NEW_USER: 'NewUser'
};
export var STATUS = {
  ENABLED: 'enabled',
  DISABLED: 'disabled'
};
export var REPORT_TYPE = {
  SALE: 'sale',
  PAYABLE: 'payable',
  SERVICES: 'services'
};
export var DATE_TIME = {
  US: 'MM/DD/YYYY'
};
export var BUY_CREDITS = [{
  number: 1,
  price: 15
}, {
  number: 5,
  price: 70
}, {
  number: 10,
  price: 130
}, {
  number: 15,
  price: 180
}, {
  number: 25,
  price: 275
}, {
  number: 50,
  price: 500
}];
export var DECIMALS = 2;
export var TAB_DICTIONARY = {
  packages: 0,
  services: 1
};
export var SORT_BY = {
  ESTIMATE: 'estimate',
  CLIENT: 'client.name',
  DATE: 'dateCreated',
  STATUS: 'status',
  INVOICE: 'number',
  VEHICLE_INFO: 'vehicle info',
  TOTAL: 'total',
  RECEIVED: 'payment.amount',
  QB_ONLINE: 'inQB',
  EXPORTED: 'exported',
  WORK_ORDER: 'number',
  AMOUNT: 'amount'
};
export var NEW_MISC_ITEM = {
  name: 'New item',
  amount: 0
};
export var TIER = {
  GETTING_STARTED: 'gettingStarted',
  STANDARD: 'standard',
  PRO: 'pro',
  ENTERPRISE: 'enterprise',
  CARADAS: 'caradas',
  PAUSE_BILLING: 'pause_billing'
};
export var SUBSCRIPTION_ID = {
  MTRX_SEMI_ANNUAL: 'MTRx_semi_annual',
  GBP_ANNUAL_FP: 'GBP-annual-fp',
  MTRX_YEARLY: 'MTRX Yearly',
  MTRX_EU_EURO_V2: 'mtrx_eu_euro_v2',
  MTRX_EU_CHF_V2: 'mtrx_eu_chf_v2',
  MTRX_UE_GBP_V2: 'mtrx_eu_gbp_v2',
  MTRX_EU_GBP: 'mtrx_eu_gbp',
  MTRX_EU_CHF: 'mtrx_eu_chf',
  MTRX_EU_EURO: 'mtrx_eu_euro',
  MTRX_US_V2: 'mtrx_us_v2',
  YEARLY: 'yearly',
  MONTHLY: 'monthly',
  EURO_ANNUAL: 'euro_annual',
  EURO_MONTHLY: 'euro_monthly',
  UK_MONTHLY: 'uk_monthly',
  PREMIUM_ANNUAL: 'premium_annual',
  PREMIUM_MONTHLY: 'premium_monthly',
  PRO_ANNUAL: 'pro_annual',
  PRO_MONTHLY: 'pro_monthly',
  BASE_ANNUAL: 'base_annual',
  BASE_MONTHLY: 'base_monthly',
  PAUSE_BILLING: 'pause_billing',
  EURO_PAUSE_BILLING: 'euro_pause_billing',
  PREMIUM_MONTHLY_BF: 'premium_monthly_bf',
  PREMIUM_ANNUAL_BF: 'plan_G7paJJIh7Aae5l',
  DETAIL_ANNUAL: 'detailing_annual',
  DETAIL_MONTHLY: 'detailing_monthly',
  GLASS_MONTHLY: 'glass_monthly',
  GLASS_ANNUAL: 'glass_annual',
  PAINT_MONTHLY: 'paint_monthly',
  PAINT_ANNUAL: 'paint_annual',
  WHEELS_MONTHLY: 'wheels_monthly',
  WHEELS_ANNUAL: 'wheels_annual',
  TINT_MONTHLY: 'tint_monthly',
  TINT_ANNUAL: 'tint_annual',
  INTERIOR_MONTHLY: 'interior_monthly',
  INTERIOR_ANNUAL: 'interior_annual',
  BLACKFRIDAY_MONTHLY: 'blackfriday_pro_monthly',
  BLACKFRIDAY_ANNUAL: 'blackfriday_pro_annual',
  // Depricated in 2024 Price Change
  // GETTING_STARTED_MONTHLY: 'getting_started_monthly',
  // GETTING_STARTED_ANNUAL: 'getting_started_annual',
  // STANDARD_MONTHLY: 'standard_monthly',
  // STANDARD_ANNUAL: 'standard_annual',
  // NEW_PRO_ANNUAL: 'new_pro_annual',
  // 2024 Price Change
  GETTING_STARTED_MONTHLY: 'getting_started_monthly_2',
  GETTING_STARTED_ANNUAL: 'getting_started_annual_2',
  STANDARD_MONTHLY: 'standard_monthly_2',
  STANDARD_ANNUAL: 'standard_annual_2',
  NEW_PRO_ANNUAL: 'new_pro_annual_2',
  NEW_PRO_MONTHLY: 'new_pro_monthly'
};
export var SUBSCRIPTION_ID_WEIGHT = {
  base_monthly: 0,
  base_annual: 1,
  detail_monthly: 0,
  detail_yearly: 1,
  pro_monthly: 2,
  pro_annual: 3,
  premium_monthly: 4,
  premium_annual: 5,
  premium_monthly_bf: 6,
  plan_G7paJJIh7Aae5l: 7
}; // ADD detailing_annual 300
// detailing_monthly 30

export var SUBSCRIPTION_DICTIONARY = {
  base_monthly: {
    value: 0,
    name: 'base_monthly'
  },
  base_annual: {
    value: 1,
    name: 'base_annual'
  },
  detail_monthly: {
    value: 0,
    name: 'detailing_monthly'
  },
  detailing_annual: {
    value: 1,
    name: 'detailing_annual'
  },
  pro_monthly: {
    value: 2,
    name: 'pro_monthly'
  },
  pro_annual: {
    value: 3,
    name: 'pro_annual'
  },
  premium_monthly: {
    value: 4,
    name: 'premium_monthly'
  },
  premium_annual: {
    value: 5,
    name: 'premium_annual'
  },
  premium_monthly_bf: {
    value: 6,
    name: 'premium_monthly_bf'
  },
  plan_G7paJJIh7Aae5l: {
    value: 7,
    name: 'plan_G7paJJIh7Aae5l'
  }
}; // export const SUBSCRIPTION_ID = {
// 	MTRX_SEMI_ANNUAL: 'MTRx_semi_annual',
// 	GBP_ANNUAL_FP: 'GBP-annual-fp',
// 	MTRX_YEARLY: 'MTRX Yearly',
// 	MTRX_EU_EURO_V2: 'mtrx_eu_euro_v2',
// 	MTRX_EU_CHF_V2: 'mtrx_eu_chf_v2',
// 	MTRX_UE_GBP_V2: 'mtrx_eu_gbp_v2',
// 	MTRX_EU_GBP: 'mtrx_eu_gbp',
// 	MTRX_EU_CHF: 'mtrx_eu_chf',
// 	MTRX_EU_EURO: 'mtrx_eu_euro',
// 	MTRX_US_V2: 'mtrx_us_v2',
// 	YEARLY: 'yearly',
// 	MONTHLY: 'monthly',
// 	EURO_ANNUAL: {
// 		value: 'euro_annual',
// 		title: 'Euro Annual'
// 	},
// 	EURO_MONTHLY: {
// 		value: 'plan_FfMbr7vhheDQH8',
// 		title: 'Euro Monthly'
// 	},
// 	PREMIUM_ANNUAL: {
// 		value: 'premium_annual',
// 		title: 'Premium Annual'
// 	},
// 	PREMIUM_MONTHLY: {
// 		value: 'premium_monthly',
// 		title: 'Premium Monthly'
// 	},
// 	PRO_ANNUAL: {
// 		value: 'pro_annual',
// 		title: 'Pro Annual'
// 	},
// 	PRO_MONTHLY: {
// 		value: 'pro_monthly',
// 		title: 'Pro Monthly'
// 	},
// 	BASE_ANNUAL: {
// 		value: 'base_annual',
// 		title: 'Base Annual'
// 	},
// 	BASE_MONTHLY: {
// 		value: 'base_monthly',
// 		title: 'Base Monthly'
// 	},
// };

export var PROMOTION_CODE = {
  // BLACKFRIDAY50: 'BLACKFRIDAY50',
  HAILSEASON21: 'HAILSEASON21'
};
export var SUBSCRIPTION_STATUS = {
  ACTIVE: 'active',
  TRIALING: 'trialing',
  PAST_DUE: 'past_due',
  PAUSE: 'pause'
};
export var PACKAGE_TABS = {
  MUTIPLE_SERVICE: 'Mutiple Services',
  PDR: 'PDR',
  DETAIL: 'Detail',
  PAINT: 'Paint',
  WHEELS: 'Wheels',
  GLASS: 'Glass',
  INTERIOR: 'Interior',
  TINT: 'Tint' // UK: 'uk',

};
export var PRICE_A_DENT_STATUS = {
  IS_VIEW: 0,
  IS_EDIT: 1,
  IS_NEW: 2
};
export var DEFAULT_PRICING_METHOD = {
  FLAT_FEE: 0,
  PER_FOOT: 2,
  PER_HOUR: 3,
  PER_VEHICLE: 4
};
export var NEW_PRICE_A_DENT = {
  isDefault: false,
  measurement: 0,
  data: [{
    depth: [{
      name: 'Shallow (0.01-.25mm)',
      description: 'Shallow depth dent',
      title: 'Shallow Depth Description',
      value: 0
    }, {
      name: 'Medium (0.26-.75mm)',
      description: 'Medium depth dent',
      title: 'Medium Depth Description',
      value: 0
    }, {
      name: 'Deep (.76mm +)',
      description: 'Deep depth dent',
      title: 'Deep Depth Description',
      value: 0
    }],
    length: [{
      timestamp: new Date().getTime(),
      name: '',
      value: ''
    }]
  }],
  addons: [],
  dents: []
};
export var PAYABLE_STATUS = {
  ALL: 'all',
  PAID: 'paid',
  UNPAID: 'unpaid' // PARTIAL: 'partial'

};
export var INVOICE_PAY_STATUS = {
  ALL: 'all',
  PAID: 'paid',
  UNPAID: 'unpaid',
  PARTIAL: 'partial'
};
export var hailDefaultMatrixOptions = [{
  label: 'US',
  options: [{
    value: 'american_family_us',
    title: 'American Family'
  }, {
    value: 'CSI',
    title: 'CSI'
  }, {
    value: 'Dent_Concepts',
    title: 'Dent Concepts'
  }, {
    value: 'dwiz_us',
    title: 'Dent Wizard'
  }, {
    value: 'dsmart_us',
    title: 'Dentsmart'
  }, {
    value: 'mtrx_drp_us',
    title: 'DRP'
  }, {
    value: 'drp_extended_us',
    title: 'DRP Extended'
  }, {
    value: 'Erie_us',
    title: 'Erie'
  }, {
    value: 'farm_us',
    title: 'Farmers'
  }, {
    value: 'gco_us',
    title: 'Geico'
  }, {
    value: 'lmut_us',
    title: 'Liberty Mutual'
  }, {
    value: 'links_us',
    title: 'PDR Linx'
  }, {
    value: 'nationwide',
    title: 'Nationwide'
  }, {
    value: 'prog_us',
    title: 'Progressive'
  }, {
    value: 'specialty',
    title: 'Specialty'
  }, {
    value: 'mtrx_retail_us',
    title: 'Standard'
  }, {
    value: 'sfarm_us',
    title: 'State Farm'
  }]
}, {
  label: 'Canada',
  options: [{
    value: 'astate_ca',
    title: 'AllState'
  }, {
    value: 'AllState (AB)',
    title: 'AllState (AB)'
  }, {
    value: 'Aviva',
    title: 'Aviva'
  }, {
    value: 'can_dir_ca',
    title: 'Canadian Direct'
  }, {
    value: 'cert_ca',
    title: 'Certas'
  }, {
    value: 'co_operators_ca',
    title: 'Co-operators'
  }, {
    value: 'eco_ca',
    title: 'Economical'
  }, {
    value: 'Erie',
    title: 'Erie'
  }, {
    value: 'tact_ca',
    title: 'Intact'
  }, {
    value: 'sfarm_ca',
    title: 'State Farm'
  }, {
    value: 'trav_ca',
    title: 'Travelers'
  }, {
    value: 'wawa_ca',
    title: 'Wawanesa'
  }]
}, {
  label: 'New Zealand',
  options: [{
    value: 'hail_associates_nz',
    title: 'Hail Associates'
  }]
}, {
  label: 'User Submitted (US)',
  options: [{
    value: 'AS Dent Shop',
    title: 'AS Dent Shop'
  }, {
    value: 'KCL',
    title: 'KCL'
  }, {
    value: 'TDW',
    title: 'TDW'
  }]
}, {
  label: 'Australia',
  options: [{
    value: 'Ultra',
    title: 'Ultra'
  }]
}];
export var wheelsFields = [{
  field: 'cosmeticRepair',
  text: 'Cosmetic Repair'
}, {
  field: 'remanufactured',
  text: 'Remanufactured'
}, {
  field: 'straighten',
  text: 'Straighten'
}, {
  field: 'backsideRepair',
  text: 'Backside Repair'
}, {
  field: 'crackRepair',
  text: 'Crack Repair'
}, {
  field: 'powderCoat',
  text: 'Powder Coat'
}, {
  field: 'powderCoatTmpsSleeve',
  text: 'Powder Coat TPMS Sleeve'
}, {
  field: 'customPaint',
  text: 'Custom Paint'
}, {
  field: 'hyperFinish',
  text: 'Hyper Finish'
}, {
  field: 'marchineFinish',
  text: 'Machine Finish'
}, {
  field: 'polish',
  text: 'Polish'
}, {
  field: 'specialFinish',
  text: 'Special Finish'
}, {
  field: 'twoPieceWheel',
  text: 'Two Piece Wheel'
}, {
  field: 'threePieceWheel',
  text: 'Three Piece Wheel'
}, {
  field: 'paintCenterCaps',
  text: 'Paint Center Caps'
}, {
  field: 'paintHubcaps',
  text: 'Paint Hubcaps'
}, {
  field: 'paintCallipers',
  text: 'Paint Callipers'
}, {
  field: 'rechromed',
  text: 'Re Chromed'
}, {
  field: 'hubcapRepair',
  text: 'Hubcap Repair'
}, {
  field: 'mountAndBalance',
  text: 'Mount And Balance'
}, {
  field: 'resetTMPS',
  text: 'TPMS Reset'
}, {
  field: 'ceramicCoating',
  text: 'Ceramic Coating'
}, {
  field: 'tireDisposalFee',
  text: 'Tire disposal fee'
}];
export var paintAndBodyFields = [{
  field: 'paintPricing',
  text: 'Default Pricing Type',
  options: [{
    value: 0,
    title: 'Flat Pricing'
  }, {
    value: 1,
    title: 'Panel Pricing'
  }, {
    value: 2,
    title: 'Item Pricing'
  }, {
    value: 3,
    title: 'Labor Pricing'
  }]
}, {
  field: 'flatRate',
  text: 'Flat Fee'
} //   {field: 'laborRatePaint'},
//   {field: 'laborRatePaintBody'},
//   {field: 'ratePaintMaterials'}
];
export var interiorFields = [{
  field: 'interiorPricing',
  text: 'Default Pricing Type',
  options: [{
    value: 0,
    title: 'Flat Pricing'
  }, {
    value: 1,
    title: 'Item Pricing'
  }]
}, {
  field: 'flatRate',
  text: 'Flat Fee'
}];
export var detailFields = [{
  field: 'laborRate',
  text: 'Labor Rate (Detail)'
}];
export var pdrFields = [{
  field: 'pdrPricing',
  text: 'Default Pricing Type',
  options: [{
    value: 0,
    title: 'Flat Pricing'
  }, {
    value: 1,
    title: 'Panel Pricing'
  }]
}, {
  field: 'flatFeeAll',
  text: 'Flat Fee'
}, {
  field: 'panelPrice',
  text: 'Panel Price'
}];
export var hailFields = [// {
// 	field: 'defaultMatrix',
// 	text: 'Default Matrix',
// 	optgroups: hailDefaultMatrixOptions.map((group) => {
// 		return { label: group.label };
// 	}),
// 	options: [].concat(...hailDefaultMatrixOptions.map((group) => {
// 		return group.options.map((obj) => {
// 			return { ...obj, optgroup: group.label };
// 		});
// 	})),
// },
{
  field: 'markUpOSDents',
  text: 'MarkUp OS Dent',
  options: [{
    value: false,
    title: 'Don\'t Include In The Base Total'
  }, {
    value: true,
    title: 'Include In The Base Total'
  }]
}, {
  field: 'laborRateHail',
  text: 'Labor Rate (Hail)',
  isTop: true
}];
export var clientHailFields = [{
  field: 'default',
  text: 'Default Matrix',
  optgroups: hailDefaultMatrixOptions.map(function (group) {
    return {
      label: group.label
    };
  }),
  options: (_ref = []).concat.apply(_ref, _toConsumableArray(hailDefaultMatrixOptions.map(function (group) {
    return group.options.map(function (obj) {
      return _objectSpread(_objectSpread({}, obj), {}, {
        optgroup: group.label
      });
    });
  })))
}, {
  field: 'markUpOSDents',
  text: 'MarkUp OS Dent',
  options: [{
    value: false,
    title: 'Don\'t Include In The Base Total'
  }, {
    value: true,
    title: 'Include In The Base Total'
  }]
}, {
  field: 'laborRateHail',
  text: 'Labor Rate (Hail)',
  isTop: true
}];
export var priceADentFields = [{
  field: 'default',
  text: 'Default Matrix'
}];
export var hailLabelDictionary = {
  us: 'US',
  canada: 'Canada',
  'new zealand': 'New Zealand',
  user: 'User Submitted (US)',
  australia: 'Australia',
  'united kingdom': 'United Kingdom'
};
export var removeInstallFields = [{
  field: 'laborRateRI',
  text: 'Default Pricing Type',
  options: [{
    value: 0,
    title: 'Labor Pricing'
  }, {
    value: 1,
    title: 'Item Pricing'
  }]
}];
export var glassFields = [{
  field: 'glassPricing',
  text: 'Default Pricing Type',
  options: [{
    value: 0,
    title: 'Flat Pricing'
  }, {
    value: 1,
    title: 'Panel Pricing'
  }, {
    value: 2,
    title: 'Repair Pricing'
  }]
}, {
  field: 'firstRepair',
  text: 'First Repair'
}, {
  field: 'additionalRepairs',
  text: 'Additional Repairs'
}];
export var tintFields = [{
  field: 'tintPricing',
  text: 'Default Pricing Type',
  options: [{
    value: 0,
    title: 'Flat Pricing'
  }, {
    value: 1,
    title: 'Panel Pricing'
  }]
}];
export var VEHICLE_TYPE = {
  CAR: 'car',
  TRUCK: 'truck',
  SUV: 'suv',
  MOTORCYCLE: 'motorcycle',
  RV: 'rv',
  BUS: 'bus',
  WATERCRAFT: 'watercraft',
  AIRCRAFT: 'aircraft',
  ATV: 'atv'
};
export var PDF_TEMPLATE = {
  BASIC: 'standard',
  ELITE: 'elite'
};
export var initializeRules = {
  invoices: {
    stock: {
      active: false
    },
    severity: {
      active: false
    },
    purchaseOrder: {
      active: false
    },
    vin: {
      active: false
    },
    signature: {
      active: false
    },
    dateOfLoss: {
      active: false
    }
  },
  estimates: {
    stock: {
      active: false
    },
    severity: {
      active: false
    },
    vin: {
      active: false
    },
    signature: {
      active: false
    },
    dateOfLoss: {
      active: false
    }
  }
};
export var PLAID_KEYS = {
  public_key: '223bd63a450389f11d9722869fde9f'
};
export var COUNTRIES_DROPDOWN = [{
  value: 'Abkhazia',
  title: 'Abkhazia'
}, {
  value: 'Afghanistan',
  title: 'Afghanistan'
}, {
  value: 'Aland Islands',
  title: 'Aland Islands'
}, {
  value: 'Albania',
  title: 'Albania'
}, {
  value: 'Algeria',
  title: 'Algeria'
}, {
  value: 'American Samoa',
  title: 'American Samoa'
}, {
  value: 'Andorra',
  title: 'Andorra'
}, {
  value: 'Angola',
  title: 'Angola'
}, {
  value: 'Anguilla',
  title: 'Anguilla'
}, {
  value: 'Antigua And Barbuda',
  title: 'Antigua And Barbuda'
}, {
  value: 'Argentina',
  title: 'Argentina'
}, {
  value: 'Armenia',
  title: 'Armenia'
}, {
  value: 'Aruba',
  title: 'Aruba'
}, {
  value: 'Australia',
  title: 'Australia'
}, {
  value: 'Austria',
  title: 'Austria'
}, {
  value: 'Azerbaijan',
  title: 'Azerbaijan'
}, {
  value: 'Azores Islands',
  title: 'Azores Islands'
}, {
  value: 'Bahamas',
  title: 'Bahamas'
}, {
  value: 'Bahrain',
  title: 'Bahrain'
}, {
  value: 'Balearic Islands',
  title: 'Balearic Islands'
}, {
  value: 'Bangladesh',
  title: 'Bangladesh'
}, {
  value: 'Barbados',
  title: 'Barbados'
}, {
  value: 'Basque Country',
  title: 'Basque Country'
}, {
  value: 'Belarus',
  title: 'Belarus'
}, {
  value: 'Belgium',
  title: 'Belgium'
}, {
  value: 'Belize',
  title: 'Belize'
}, {
  value: 'Benin',
  title: 'Benin'
}, {
  value: 'Bermuda',
  title: 'Bermuda'
}, {
  value: 'Bhutan 1',
  title: 'Bhutan 1'
}, {
  value: 'Bhutan',
  title: 'Bhutan'
}, {
  value: 'Bolivia',
  title: 'Bolivia'
}, {
  value: 'Bonaire',
  title: 'Bonaire'
}, {
  value: 'Bosnia And Herzegovina',
  title: 'Bosnia And Herzegovina'
}, {
  value: 'Botswana',
  title: 'Botswana'
}, {
  value: 'Brazil',
  title: 'Brazil'
}, {
  value: 'British Columbia',
  title: 'British Columbia'
}, {
  value: 'British Indian Ocean Territory',
  title: 'British Indian Ocean Territory'
}, {
  value: 'British Virgin Islands',
  title: 'British Virgin Islands'
}, {
  value: 'Brunei',
  title: 'Brunei'
}, {
  value: 'Bulgaria',
  title: 'Bulgaria'
}, {
  value: 'Burkina Faso',
  title: 'Burkina Faso'
}, {
  value: 'Burundi',
  title: 'Burundi'
}, {
  value: 'Cambodia',
  title: 'Cambodia'
}, {
  value: 'Cameroon',
  title: 'Cameroon'
}, {
  value: 'Canada',
  title: 'Canada'
}, {
  value: 'Canary Islands',
  title: 'Canary Islands'
}, {
  value: 'Cape Verde',
  title: 'Cape Verde'
}, {
  value: 'Cayman Islands',
  title: 'Cayman Islands'
}, {
  value: 'Central African Republic',
  title: 'Central African Republic'
}, {
  value: 'Ceuta',
  title: 'Ceuta'
}, {
  value: 'Chad',
  title: 'Chad'
}, {
  value: 'Chile',
  title: 'Chile'
}, {
  value: 'China',
  title: 'China'
}, {
  value: 'Christmas Island',
  title: 'Christmas Island'
}, {
  value: 'Cocos Island',
  title: 'Cocos Island'
}, {
  value: 'Colombia',
  title: 'Colombia'
}, {
  value: 'Comoros',
  title: 'Comoros'
}, {
  value: 'Cook Islands',
  title: 'Cook Islands'
}, {
  value: 'Corsica',
  title: 'Corsica'
}, {
  value: 'Costa Rica',
  title: 'Costa Rica'
}, {
  value: 'Croatia',
  title: 'Croatia'
}, {
  value: 'Cuba',
  title: 'Cuba'
}, {
  value: 'Curacao',
  title: 'Curacao'
}, {
  value: 'Cyprus',
  title: 'Cyprus'
}, {
  value: 'Czech Republic',
  title: 'Czech Republic'
}, {
  value: 'Democratic Republic Of Congo',
  title: 'Democratic Republic Of Congo'
}, {
  value: 'Denmark',
  title: 'Denmark'
}, {
  value: 'Djibouti',
  title: 'Djibouti'
}, {
  value: 'Dominica',
  title: 'Dominica'
}, {
  value: 'Dominican Republic',
  title: 'Dominican Republic'
}, {
  value: 'East Timor',
  title: 'East Timor'
}, {
  value: 'Ecuador',
  title: 'Ecuador'
}, {
  value: 'Egypt',
  title: 'Egypt'
}, {
  value: 'England',
  title: 'England'
}, {
  value: 'Equatorial Guinea',
  title: 'Equatorial Guinea'
}, {
  value: 'Eritrea',
  title: 'Eritrea'
}, {
  value: 'Estonia',
  title: 'Estonia'
}, {
  value: 'Ethiopia',
  title: 'Ethiopia'
}, {
  value: 'European Union',
  title: 'European Union'
}, {
  value: 'Falkland Islands',
  title: 'Falkland Islands'
}, {
  value: 'Faroe Islands',
  title: 'Faroe Islands'
}, {
  value: 'Fiji',
  title: 'Fiji'
}, {
  value: 'Finland',
  title: 'Finland'
}, {
  value: 'France',
  title: 'France'
}, {
  value: 'French Polynesia',
  title: 'French Polynesia'
}, {
  value: 'Gabon',
  title: 'Gabon'
}, {
  value: 'Galapagos Islands',
  title: 'Galapagos Islands'
}, {
  value: 'Gambia',
  title: 'Gambia'
}, {
  value: 'Georgia',
  title: 'Georgia'
}, {
  value: 'Germany',
  title: 'Germany'
}, {
  value: 'Ghana',
  title: 'Ghana'
}, {
  value: 'Gibraltar',
  title: 'Gibraltar'
}, {
  value: 'Greece',
  title: 'Greece'
}, {
  value: 'Greenland',
  title: 'Greenland'
}, {
  value: 'Grenada',
  title: 'Grenada'
}, {
  value: 'Guam',
  title: 'Guam'
}, {
  value: 'Guatemala',
  title: 'Guatemala'
}, {
  value: 'Guernsey',
  title: 'Guernsey'
}, {
  value: 'Guinea Bissau',
  title: 'Guinea Bissau'
}, {
  value: 'Guinea',
  title: 'Guinea'
}, {
  value: 'Guyana',
  title: 'Guyana'
}, {
  value: 'Haiti',
  title: 'Haiti'
}, {
  value: 'Hawaii',
  title: 'Hawaii'
}, {
  value: 'Honduras',
  title: 'Honduras'
}, {
  value: 'Hong Kong',
  title: 'Hong Kong'
}, {
  value: 'Hungary',
  title: 'Hungary'
}, {
  value: 'Iceland',
  title: 'Iceland'
}, {
  value: 'India',
  title: 'India'
}, {
  value: 'Indonesia',
  title: 'Indonesia'
}, {
  value: 'Iran',
  title: 'Iran'
}, {
  value: 'Iraq',
  title: 'Iraq'
}, {
  value: 'Ireland',
  title: 'Ireland'
}, {
  value: 'Isle Of Man',
  title: 'Isle Of Man'
}, {
  value: 'Israel',
  title: 'Israel'
}, {
  value: 'Italy',
  title: 'Italy'
}, {
  value: 'Ivory Coast',
  title: 'Ivory Coast'
}, {
  value: 'Jamaica',
  title: 'Jamaica'
}, {
  value: 'Japan',
  title: 'Japan'
}, {
  value: 'Jersey',
  title: 'Jersey'
}, {
  value: 'Jordan',
  title: 'Jordan'
}, {
  value: 'Kazakhstan',
  title: 'Kazakhstan'
}, {
  value: 'Kenya',
  title: 'Kenya'
}, {
  value: 'Kiribati',
  title: 'Kiribati'
}, {
  value: 'Kosovo',
  title: 'Kosovo'
}, {
  value: 'Kuwait',
  title: 'Kuwait'
}, {
  value: 'Kyrgyzstan',
  title: 'Kyrgyzstan'
}, {
  value: 'Laos',
  title: 'Laos'
}, {
  value: 'Latvia',
  title: 'Latvia'
}, {
  value: 'Lebanon',
  title: 'Lebanon'
}, {
  value: 'Lesotho',
  title: 'Lesotho'
}, {
  value: 'Liberia',
  title: 'Liberia'
}, {
  value: 'Libya',
  title: 'Libya'
}, {
  value: 'Liechtenstein',
  title: 'Liechtenstein'
}, {
  value: 'Lithuania',
  title: 'Lithuania'
}, {
  value: 'Luxembourg',
  title: 'Luxembourg'
}, {
  value: 'Macao',
  title: 'Macao'
}, {
  value: 'Madagascar',
  title: 'Madagascar'
}, {
  value: 'Madeira',
  title: 'Madeira'
}, {
  value: 'Malawi',
  title: 'Malawi'
}, {
  value: 'Malaysia',
  title: 'Malaysia'
}, {
  value: 'Maldives',
  title: 'Maldives'
}, {
  value: 'Mali',
  title: 'Mali'
}, {
  value: 'Malta',
  title: 'Malta'
}, {
  value: 'Marshall Island',
  title: 'Marshall Island'
}, {
  value: 'Martinique',
  title: 'Martinique'
}, {
  value: 'Mauritania',
  title: 'Mauritania'
}, {
  value: 'Mauritius',
  title: 'Mauritius'
}, {
  value: 'Melilla',
  title: 'Melilla'
}, {
  value: 'Mexico',
  title: 'Mexico'
}, {
  value: 'Micronesia',
  title: 'Micronesia'
}, {
  value: 'Moldova',
  title: 'Moldova'
}, {
  value: 'Monaco',
  title: 'Monaco'
}, {
  value: 'Mongolia',
  title: 'Mongolia'
}, {
  value: 'Montenegro',
  title: 'Montenegro'
}, {
  value: 'Montserrat',
  title: 'Montserrat'
}, {
  value: 'Morocco',
  title: 'Morocco'
}, {
  value: 'Mozambique',
  title: 'Mozambique'
}, {
  value: 'Myanmar',
  title: 'Myanmar'
}, {
  value: 'Namibia',
  title: 'Namibia'
}, {
  value: 'Nato',
  title: 'Nato'
}, {
  value: 'Nauru',
  title: 'Nauru'
}, {
  value: 'Nepal',
  title: 'Nepal'
}, {
  value: 'Netherlands',
  title: 'Netherlands'
}, {
  value: 'New Zealand',
  title: 'New Zealand'
}, {
  value: 'Nicaragua',
  title: 'Nicaragua'
}, {
  value: 'Niger',
  title: 'Niger'
}, {
  value: 'Nigeria',
  title: 'Nigeria'
}, {
  value: 'Niue',
  title: 'Niue'
}, {
  value: 'Norfolk Island',
  title: 'Norfolk Island'
}, {
  value: 'North Korea',
  title: 'North Korea'
}, {
  value: 'Northen Cyprus',
  title: 'Northen Cyprus'
}, {
  value: 'Northern Marianas Islands',
  title: 'Northern Marianas Islands'
}, {
  value: 'Norway',
  title: 'Norway'
}, {
  value: 'Oman',
  title: 'Oman'
}, {
  value: 'Orkney Islands',
  title: 'Orkney Islands'
}, {
  value: 'Ossetia',
  title: 'Ossetia'
}, {
  value: 'Pakistan',
  title: 'Pakistan'
}, {
  value: 'Palau',
  title: 'Palau'
}, {
  value: 'Palestine',
  title: 'Palestine'
}, {
  value: 'Panama',
  title: 'Panama'
}, {
  value: 'Papua New Guinea',
  title: 'Papua New Guinea'
}, {
  value: 'Paraguay',
  title: 'Paraguay'
}, {
  value: 'Peru',
  title: 'Peru'
}, {
  value: 'Philippines',
  title: 'Philippines'
}, {
  value: 'Pitcairn Islands',
  title: 'Pitcairn Islands'
}, {
  value: 'Portugal',
  title: 'Portugal'
}, {
  value: 'Puerto Rico',
  title: 'Puerto Rico'
}, {
  value: 'Qatar',
  title: 'Qatar'
}, {
  value: 'Rapa Nui',
  title: 'Rapa Nui'
}, {
  value: 'Republic Of Macedonia',
  title: 'Republic Of Macedonia'
}, {
  value: 'Republic Of Poland',
  title: 'Republic Of Poland'
}, {
  value: 'Republic Of The Congo',
  title: 'Republic Of The Congo'
}, {
  value: 'Romania',
  title: 'Romania'
}, {
  value: 'Russia',
  title: 'Russia'
}, {
  value: 'Rwanda',
  title: 'Rwanda'
}, {
  value: 'Saba Island',
  title: 'Saba Island'
}, {
  value: 'Saint Kitts And Nevis',
  title: 'Saint Kitts And Nevis'
}, {
  value: 'Salvador',
  title: 'Salvador'
}, {
  value: 'Samoa',
  title: 'Samoa'
}, {
  value: 'San Marino',
  title: 'San Marino'
}, {
  value: 'Sao Tome And Principe',
  title: 'Sao Tome And Principe'
}, {
  value: 'Sardinia',
  title: 'Sardinia'
}, {
  value: 'Saudi Arabia',
  title: 'Saudi Arabia'
}, {
  value: 'Scotland',
  title: 'Scotland'
}, {
  value: 'Senegal',
  title: 'Senegal'
}, {
  value: 'Serbia',
  title: 'Serbia'
}, {
  value: 'Seychelles',
  title: 'Seychelles'
}, {
  value: 'Sierra Leone',
  title: 'Sierra Leone'
}, {
  value: 'Singapore',
  title: 'Singapore'
}, {
  value: 'Sint Eustatius',
  title: 'Sint Eustatius'
}, {
  value: 'Sint Maarten',
  title: 'Sint Maarten'
}, {
  value: 'Slovakia',
  title: 'Slovakia'
}, {
  value: 'Slovenia',
  title: 'Slovenia'
}, {
  value: 'Solomon Islands',
  title: 'Solomon Islands'
}, {
  value: 'Somalia',
  title: 'Somalia'
}, {
  value: 'Somaliland',
  title: 'Somaliland'
}, {
  value: 'South Africa',
  title: 'South Africa'
}, {
  value: 'South Korea',
  title: 'South Korea'
}, {
  value: 'South Sudan',
  title: 'South Sudan'
}, {
  value: 'Spain',
  title: 'Spain'
}, {
  value: 'Sri Lanka',
  title: 'Sri Lanka'
}, {
  value: 'St Barts',
  title: 'St Barts'
}, {
  value: 'St Lucia',
  title: 'St Lucia'
}, {
  value: 'St Vincent And The Grenadines',
  title: 'St Vincent And The Grenadines'
}, {
  value: 'Sudan',
  title: 'Sudan'
}, {
  value: 'Suriname',
  title: 'Suriname'
}, {
  value: 'Swaziland',
  title: 'Swaziland'
}, {
  value: 'Sweden',
  title: 'Sweden'
}, {
  value: 'Switzerland',
  title: 'Switzerland'
}, {
  value: 'Syria',
  title: 'Syria'
}, {
  value: 'Taiwan',
  title: 'Taiwan'
}, {
  value: 'Tajikistan',
  title: 'Tajikistan'
}, {
  value: 'Tanzania',
  title: 'Tanzania'
}, {
  value: 'Thailand',
  title: 'Thailand'
}, {
  value: 'Tibet',
  title: 'Tibet'
}, {
  value: 'Togo',
  title: 'Togo'
}, {
  value: 'Tokelau',
  title: 'Tokelau'
}, {
  value: 'Tonga',
  title: 'Tonga'
}, {
  value: 'Transnistria',
  title: 'Transnistria'
}, {
  value: 'Trinidad And Tobago',
  title: 'Trinidad And Tobago'
}, {
  value: 'Tunisia',
  title: 'Tunisia'
}, {
  value: 'Turkey',
  title: 'Turkey'
}, {
  value: 'Turkmenistan',
  title: 'Turkmenistan'
}, {
  value: 'Turks And Caicos',
  title: 'Turks And Caicos'
}, {
  value: 'Tuvalu',
  title: 'Tuvalu'
}, {
  value: 'Uganda',
  title: 'Uganda'
}, {
  value: 'Ukraine',
  title: 'Ukraine'
}, {
  value: 'United Arab Emirates',
  title: 'United Arab Emirates'
}, {
  value: 'United Kingdom',
  title: 'United Kingdom'
}, {
  value: 'United Nations',
  title: 'United Nations'
}, {
  value: 'United States',
  title: 'United States'
}, {
  value: 'Uruguay',
  title: 'Uruguay'
}, {
  value: 'Uzbekistn',
  title: 'Uzbekistn'
}, {
  value: 'Vanuatu',
  title: 'Vanuatu'
}, {
  value: 'Vatican City',
  title: 'Vatican City'
}, {
  value: 'Venezuela',
  title: 'Venezuela'
}, {
  value: 'Vietnam',
  title: 'Vietnam'
}, {
  value: 'Virgin Islands',
  title: 'Virgin Islands'
}, {
  value: 'Wales',
  title: 'Wales'
}, {
  value: 'Western Sahara',
  title: 'Western Sahara'
}, {
  value: 'Yemen',
  title: 'Yemen'
}, {
  value: 'Zambia',
  title: 'Zambia'
}, {
  value: 'Zimbabwe',
  title: 'Zimbabwe'
}];
export var STATES = ['Alabama', 'Alaska', 'American Samoa', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut', 'Delaware', 'District of Columbia', 'Federated States of Micronesia', 'Florida', 'Georgia', 'Guam', 'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana', 'Maine', 'Marshall Islands', 'Maryland', 'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire', 'New Jersey', 'New Mexico', 'New York', 'North Carolina', 'North Dakota', 'Northern Mariana Islands', 'Ohio', 'Oklahoma', 'Oregon', 'Palau', 'Pennsylvania', 'Puerto Rico', 'Rhode Island', 'South Carolina', 'South Dakota', 'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virgin Island', 'Virginia', 'Washington', 'West Virginia', 'Wisconsin', 'Wyoming'];
export var STATES_SHORT_NAME = ['AL', 'AK', 'AS', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC', 'FM', 'FL', 'GA', 'GU', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MH', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'MP', 'OH', 'OK', 'OR', 'PW', 'PA', 'PR', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VI', 'VA', 'WA', 'WV', 'WI', 'WY'];
export var STRIPE_COUNTRIES_CODES = [{
  title: 'Australia',
  value: 'AU'
}, {
  title: 'Austria',
  value: 'AT'
}, {
  title: 'Belgium',
  value: 'BE'
}, {
  title: 'Bulgaria',
  value: 'BG'
}, {
  title: 'Brazil ',
  value: 'BR'
}, {
  title: 'Canada',
  value: 'CA'
}, {
  title: 'Cyprus',
  value: 'CY'
}, {
  title: 'Czech Republic',
  value: 'CZ'
}, {
  title: 'Denmark',
  value: 'DK'
}, {
  title: 'Estonia',
  value: 'EE'
}, {
  title: 'Finland',
  value: 'FI'
}, {
  title: 'France',
  value: 'FR'
}, {
  title: 'Germany',
  value: 'DE'
}, {
  title: 'Greece',
  value: 'GR'
}, {
  title: 'Hong Kong',
  value: 'HK'
}, {
  title: 'Hungary',
  value: 'HU'
}, {
  title: 'India',
  value: 'IN'
}, {
  title: 'Ireland',
  value: 'IE'
}, {
  title: 'Italy',
  value: 'IT'
}, {
  title: 'Japan',
  value: 'JP'
}, {
  title: 'Latvia',
  value: 'LV'
}, {
  title: 'Lithuania',
  value: 'LT'
}, {
  title: 'Luxembourg',
  value: 'LU'
}, {
  title: 'Malaysia',
  value: 'MY'
}, {
  title: 'Malta',
  value: 'MT'
}, {
  title: 'Mexico ',
  value: 'MX'
}, {
  title: 'Netherlands',
  value: 'NL'
}, {
  title: 'New Zealand',
  value: 'NZ'
}, {
  title: 'Norway',
  value: 'NO'
}, {
  title: 'Poland',
  value: 'PL'
}, {
  title: 'Portugal',
  value: 'PT'
}, {
  title: 'Romania',
  value: 'RO'
}, {
  title: 'Singapore',
  value: 'SG'
}, {
  title: 'Slovakia',
  value: 'SK'
}, {
  title: 'Slovenia',
  value: 'SI'
}, {
  title: 'Spain',
  value: 'ES'
}, {
  title: 'Sweden',
  value: 'SE'
}, {
  title: 'Switzerland',
  value: 'CH'
}, {
  title: 'United Kingdom',
  value: 'GB'
}, {
  title: 'United States',
  value: 'US'
}];
export var COUNTRIES_CODES = [{
  value: 'AF',
  title: 'Afghanistan'
}, {
  value: 'AL',
  title: 'Albania'
}, {
  value: 'DZ',
  title: 'Algeria'
}, {
  value: 'AS',
  title: 'American Samoa'
}, {
  value: 'AD',
  title: 'Andorra'
}, {
  value: 'AO',
  title: 'Angola'
}, {
  value: 'AI',
  title: 'Anguilla'
}, {
  value: 'AG',
  title: 'Antigua And Barbuda'
}, {
  value: 'AR',
  title: 'Argentina'
}, {
  value: 'AM',
  title: 'Armenia'
}, {
  value: 'AW',
  title: 'Aruba'
}, {
  value: 'AU',
  title: 'Australia'
}, {
  value: 'AT',
  title: 'Austria'
}, {
  value: 'AZ',
  title: 'Azerbaijan'
}, {
  value: 'BS',
  title: 'Bahamas'
}, {
  value: 'BH',
  title: 'Bahrain'
}, {
  value: 'BD',
  title: 'Bangladesh'
}, {
  value: 'BB',
  title: 'Barbados'
}, {
  value: 'BY',
  title: 'Belarus'
}, {
  value: 'BE',
  title: 'Belgium'
}, {
  value: 'BZ',
  title: 'Belize'
}, {
  value: 'BJ',
  title: 'Benin'
}, {
  value: 'BM',
  title: 'Bermuda'
}, {
  value: 'BT',
  title: 'Bhutan'
}, {
  value: 'BO',
  title: 'Bolivia'
}, {
  value: 'BQ',
  title: 'Bonaire'
}, {
  value: 'BA',
  title: 'Bosnia And Herzegovina'
}, {
  value: 'BW',
  title: 'Botswana'
}, {
  value: 'BR',
  title: 'Brazil'
}, {
  value: 'IO',
  title: 'British Indian Ocean Territory'
}, {
  value: 'BN',
  title: 'Brunei'
}, {
  value: 'BG',
  title: 'Bulgaria'
}, {
  value: 'BF',
  title: 'Burkina Faso'
}, {
  value: 'BI',
  title: 'Burundi'
}, {
  value: 'CV',
  title: 'Cape Verde'
}, {
  value: 'KH',
  title: 'Cambodia'
}, {
  value: 'CM',
  title: 'Cameroon'
}, {
  value: 'CA',
  title: 'Canada'
}, {
  value: 'KY',
  title: 'Cayman Islands'
}, {
  value: 'CF',
  title: 'Central African Republic'
}, {
  value: 'TD',
  title: 'Chad'
}, {
  value: 'CL',
  title: 'Chile'
}, {
  value: 'CN',
  title: 'China'
}, {
  value: 'CX',
  title: 'Christmas Island'
}, {
  value: 'CC',
  title: 'Cocos Islands'
}, {
  value: 'CO',
  title: 'Colombia'
}, {
  value: 'KM',
  title: 'Comoros'
}, {
  value: 'CK',
  title: 'Cook Island'
}, {
  value: 'CR',
  title: 'Costa Rica'
}, {
  value: 'HR',
  title: 'Croatia'
}, {
  value: 'CU',
  title: 'Cuba'
}, {
  value: 'CW',
  title: 'Curacao'
}, {
  value: 'CY',
  title: 'Cyprus'
}, {
  value: 'CZ',
  title: 'Czech Republic'
}, {
  value: 'DK',
  title: 'Denmark'
}, {
  value: 'DJ',
  title: 'Djibouti'
}, {
  value: 'DM',
  title: 'Dominica'
}, {
  value: 'DO',
  title: 'Dominican Republic'
}, {
  value: 'EC',
  title: 'Ecuador'
}, {
  value: 'EG',
  title: 'Egypt'
}, {
  value: 'GQ',
  title: 'Equatorial Guinea'
}, {
  value: 'ER',
  title: 'Eritrea'
}, {
  value: 'EE',
  title: 'Estonia'
}, {
  value: 'ET',
  title: 'Ethiopia'
}, {
  value: 'FK',
  title: 'Falkland Islands'
}, {
  value: 'FO',
  title: 'Faroe Islands'
}, {
  value: 'FJ',
  title: 'Fiji'
}, {
  value: 'FI',
  title: 'Finland'
}, {
  value: 'FR',
  title: 'France'
}, {
  value: 'PF',
  title: 'French Polynesia'
}, {
  value: 'GA',
  title: 'Gabon'
}, {
  value: 'GM',
  title: 'Gambia'
}, {
  value: 'GE',
  title: 'Georgia'
}, {
  value: 'DE',
  title: 'Germany'
}, {
  value: 'GH',
  title: 'Ghana'
}, {
  value: 'GI',
  title: 'Gibraltar'
}, {
  value: 'GR',
  title: 'Greece'
}, {
  value: 'GL',
  title: 'Greenland'
}, {
  value: 'GD',
  title: 'Grenada'
}, {
  value: 'GU',
  title: 'Guam'
}, {
  value: 'GT',
  title: 'Guatemala'
}, {
  value: 'GG',
  title: 'Guernsey'
}, {
  value: 'GN',
  title: 'Guinea'
}, {
  value: 'GW',
  title: 'Guinea Bissau'
}, {
  value: 'GY',
  title: 'Guyana'
}, {
  value: 'HT',
  title: 'Haiti'
}, {
  value: 'HN',
  title: 'Honduras'
}, {
  value: 'HK',
  title: 'Hong Kong'
}, {
  value: 'HU',
  title: 'Hungary'
}, {
  value: 'IS',
  title: 'Iceland'
}, {
  value: 'IN',
  title: 'India'
}, {
  value: 'ID',
  title: 'Indonesia'
}, {
  value: 'IR',
  title: 'Iran'
}, {
  value: 'IQ',
  title: 'Iraq'
}, {
  value: 'IE',
  title: 'Ireland'
}, {
  value: 'IM',
  title: 'Isle Of Man'
}, {
  value: 'IL',
  title: 'Israel'
}, {
  value: 'IT',
  title: 'Italy'
}, {
  value: 'JM',
  title: 'Jamaica'
}, {
  value: 'JP',
  title: 'Japan'
}, {
  value: 'JE',
  title: 'Jersey'
}, {
  value: 'JO',
  title: 'Jordan'
}, {
  value: 'KZ',
  title: 'Kazakhstan'
}, {
  value: 'KE',
  title: 'Kenya'
}, {
  value: 'KI',
  title: 'Kiribati'
}, {
  value: 'KW',
  title: 'Kuwait'
}, {
  value: 'KG',
  title: 'Kyrgyzstan'
}, {
  value: 'LA',
  title: 'Laos'
}, {
  value: 'LV',
  title: 'Latvia'
}, {
  value: 'LB',
  title: 'Lebanon'
}, {
  value: 'LS',
  title: 'Lesotho'
}, {
  value: 'LR',
  title: 'Liberia'
}, {
  value: 'LY',
  title: 'Libya'
}, {
  value: 'LI',
  title: 'Liechtenstein'
}, {
  value: 'LT',
  title: 'Lithuania'
}, {
  value: 'LU',
  title: 'Luxembourg'
}, {
  value: 'MO',
  title: 'Macao'
}, {
  value: 'MG',
  title: 'Madagascar'
}, {
  value: 'MW',
  title: 'Malawi'
}, {
  value: 'MY',
  title: 'Malaysia'
}, {
  value: 'MV',
  title: 'Maldives'
}, {
  value: 'ML',
  title: 'Mali'
}, {
  value: 'MT',
  title: 'Malta'
}, {
  value: 'MH',
  title: 'Marshall Islands'
}, {
  value: 'MQ',
  title: 'Martinique'
}, {
  value: 'MR',
  title: 'Mauritania'
}, {
  value: 'MU',
  title: 'Mauritius'
}, {
  value: 'YT',
  title: 'Mayotte'
}, {
  value: 'MX',
  title: 'Mexico'
}, {
  value: 'FM',
  title: 'Micronesia'
}, {
  value: 'MD',
  title: 'Moldova, Republic of'
}, {
  value: 'MC',
  title: 'Monaco'
}, {
  value: 'MN',
  title: 'Mongolia'
}, {
  value: 'ME',
  title: 'Montenegro'
}, {
  value: 'MS',
  title: 'Montserrat'
}, {
  value: 'MA',
  title: 'Morocco'
}, {
  value: 'MZ',
  title: 'Mozambique'
}, {
  value: 'MM',
  title: 'Myanmar'
}, {
  value: 'NA',
  title: 'Namibia'
}, {
  value: 'NR',
  title: 'Nauru'
}, {
  value: 'NP',
  title: 'Nepal'
}, {
  value: 'NL',
  title: 'Netherlands'
}, {
  value: 'NC',
  title: 'New Caledonia'
}, {
  value: 'NZ',
  title: 'New Zealand'
}, {
  value: 'NI',
  title: 'Nicaragua'
}, {
  value: 'NE',
  title: 'Niger'
}, {
  value: 'NG',
  title: 'Nigeria'
}, {
  value: 'NU',
  title: 'Niue'
}, {
  value: 'NF',
  title: 'Norfolk Island'
}, {
  value: 'KP',
  title: 'North Korea'
}, {
  value: 'MP',
  title: 'Northern Marianas Islands'
}, {
  value: 'NO',
  title: 'Norway'
}, {
  value: 'OM',
  title: 'Oman'
}, {
  value: 'PK',
  title: 'Pakistan'
}, {
  value: 'PW',
  title: 'Palau'
}, {
  value: 'PS',
  title: 'Palestine'
}, {
  value: 'PA',
  title: 'Panama'
}, {
  value: 'PG',
  title: 'Papua New Guinea'
}, {
  value: 'PY',
  title: 'Paraguay'
}, {
  value: 'PE',
  title: 'Peru'
}, {
  value: 'PH',
  title: 'Philippines'
}, {
  value: 'PN',
  title: 'Pitcairn Islands'
}, {
  value: 'PT',
  title: 'Portugal'
}, {
  value: 'PR',
  title: 'Puerto Rico'
}, {
  value: 'QA',
  title: 'Qatar'
}, {
  value: 'RE',
  title: 'Réunion'
}, {
  value: 'MK',
  title: 'Republic Of Macedonia'
}, {
  value: 'PL',
  title: 'Republic Of Poland'
}, {
  value: 'CD',
  title: 'Republic Of The Congo'
}, {
  value: 'RO',
  title: 'Romania'
}, {
  value: 'RU',
  title: 'Russia'
}, {
  value: 'RW',
  title: 'Rwanda'
}, {
  value: 'BL',
  title: 'Saint Barts'
}, {
  value: 'SH',
  title: 'Saint Helena, Ascension and Tristan da Cunha'
}, {
  value: 'KN',
  title: 'Saint Kitts and Nevis'
}, {
  value: 'SV',
  title: 'Salvador'
}, {
  value: 'LC',
  title: 'Saint Lucia'
}, {
  value: 'VC',
  title: 'Saint Vincent and the Grenadines'
}, {
  value: 'WS',
  title: 'Samoa'
}, {
  value: 'SM',
  title: 'San Marino'
}, {
  value: 'ST',
  title: 'Sao Tome And Principe'
}, {
  value: 'SA',
  title: 'Saudi Arabia'
}, {
  value: 'SN',
  title: 'Senegal'
}, {
  value: 'RS',
  title: 'Serbia'
}, {
  value: 'SC',
  title: 'Seychelles'
}, {
  value: 'SL',
  title: 'Sierra Leone'
}, {
  value: 'SG',
  title: 'Singapore'
}, {
  value: 'SX',
  title: 'Sint Maarten'
}, {
  value: 'SK',
  title: 'Slovakia'
}, {
  value: 'SI',
  title: 'Slovenia'
}, {
  value: 'SB',
  title: 'Solomon Islands'
}, {
  value: 'SO',
  title: 'Somalia'
}, {
  value: 'ZA',
  title: 'South Africa'
}, {
  value: 'KR',
  title: 'South Korea'
}, {
  value: 'SS',
  title: 'South Sudan'
}, {
  value: 'ES',
  title: 'Spain'
}, {
  value: 'LK',
  title: 'Sri Lanka'
}, {
  value: 'SD',
  title: 'Sudan'
}, {
  value: 'SR',
  title: 'Suriname'
}, {
  value: 'SE',
  title: 'Sweden'
}, {
  value: 'CH',
  title: 'Switzerland'
}, {
  value: 'TW',
  title: 'Taiwan'
}, {
  value: 'TJ',
  title: 'Tajikistan'
}, {
  value: 'TZ',
  title: 'Tanzania'
}, {
  value: 'TH',
  title: 'Thailand'
}, {
  value: 'TG',
  title: 'Togo'
}, {
  value: 'TK',
  title: 'Tokelau'
}, {
  value: 'TO',
  title: 'Tonga'
}, {
  value: 'TT',
  title: 'Trinidad And Tobago'
}, {
  value: 'TN',
  title: 'Tunisia'
}, {
  value: 'TR',
  title: 'Turkey'
}, {
  value: 'TM',
  title: 'Turkmenistan'
}, {
  value: 'TC',
  title: 'Turks And Caicos'
}, {
  value: 'TV',
  title: 'Tuvalu'
}, {
  value: 'UG',
  title: 'Uganda'
}, {
  value: 'UA',
  title: 'Ukraine'
}, {
  value: 'AE',
  title: 'United Arab Emirates'
}, {
  value: 'GB',
  title: 'United Kingdom'
}, {
  value: 'US',
  title: 'United States'
}, {
  value: 'UY',
  title: 'Uruguay'
}, {
  value: 'UZ',
  title: 'Uzbekistan'
}, {
  value: 'VU',
  title: 'Vanuatu'
}, {
  value: 'VE',
  title: 'Venezuela'
}, {
  value: 'VN',
  title: 'Viet Nam'
}, {
  value: 'VI',
  title: 'Virgin Islands (U.S.)'
}, {
  value: 'EH',
  title: 'Western Sahara'
}, {
  value: 'YE',
  title: 'Yemen'
}, {
  value: 'ZM',
  title: 'Zambia'
}, {
  value: 'ZW',
  title: 'Zimbabwe'
}];
export var REMINDER_ALERT_DEFAULT = {
  name: 'Reminder',
  method: 'text',
  description: 'Sent at a set time before an event.',
  sendBefore: '1 day before',
  autoSend: true,
  templateBase: 'reminder',
  emailSubject: '',
  value: 'Reminder: Hi {CLIENT_NAME}! Your appointment with {COMPANY_NAME} is on {DATE_TIME}. If you have a conflict, please call {COMPANY_PHONE}.'
};
export var CUSTOM_ALERT_DEFAULT = {
  name: '',
  method: 'text',
  description: 'Does not auto send.',
  templateBase: 'custom',
  emailSubject: '',
  value: 'This is the default alert that asks your client to write a review after an invoice is closed. You can easily change it from the invoice view in the app.'
};
export var ALERTS_DEFAULT = [{
  name: 'Scheduled Event',
  method: 'text',
  description: 'Sent when a new event is created.',
  sendBefore: '',
  autoSend: false,
  templateBase: 'event',
  emailSubject: '',
  value: 'Hi {CLIENT_NAME}! Your appointment with {COMPANY_NAME} has been scheduled for {DATE_TIME}. If you have a conflict, please call {COMPANY_PHONE}.'
}, _objectSpread(_objectSpread({}, REMINDER_ALERT_DEFAULT), {}, {
  autoSend: false
}), {
  name: 'Rate/ Review',
  method: 'text',
  description: 'Sent when an invoice for a retail customer is marked as paid.',
  sendBefore: '',
  autoSend: false,
  templateBase: 'review',
  emailSubject: '',
  message: 'Hi {CLIENT_NAME}! Thank you for choosing {COMPANY_NAME}, please take a moment to review the service you received, and let us know how your experience with {COMPANY_NAME} has been.'
}];
export var ALERTS_METHOD_OPTIONS = [{
  value: 'text',
  title: 'Text'
}, {
  value: 'email',
  title: 'Email'
}];
export var ALERTS_SEND_TIME_OPTIONS = [{
  value: '',
  title: 'Immediately',
  detail: 'Will send immediately when event is saved'
}, {
  value: '1 hour before',
  title: '1 hour before'
}, {
  value: '2 hour before',
  title: '2 hour before'
}, {
  value: '1 day before',
  title: '1 day before'
}, {
  value: '2 days before',
  title: '2 days before'
}, {
  value: '1 week before',
  title: '1 week before'
}, {
  value: '2 weeks before',
  title: '2 weeks before'
}];
export var COUNTRY_DIGITCODES_DROPDOWN = function () {
  return COUNTRIES_CODES.map(function (country) {
    return {
      value: country.value,
      title: country.title
    };
  });
}();
export var STATES_SUBSCRIPTION_DROPDOWN = function () {
  var listOfStates = [];
  STATES.forEach(function (state) {
    listOfStates.push({
      value: state,
      title: state
    });
  });
  return listOfStates;
}();
export var STATES_SHORTNAME_DROPDOWN = function () {
  var listOfStates = [];
  STATES.forEach(function (state, index) {
    listOfStates.push({
      value: STATES_SHORT_NAME[index],
      title: state
    });
  });
  return listOfStates;
}();
export var BRAND_MASK = {
  'diners-club_14': '9999 9999 9999 99',
  'american-express': '9999 999999 99999'
};
export var checkListCaradas = [{
  name: 'Review Customer’s Work Order, understand what ADAS is affected from repair (damaged, moved, removed, or replaced)',
  itemType: 'checkbox',
  status: false,
  value: false,
  subItems: [{
    name: 'Damaged',
    itemType: 'radio',
    value: false,
    status: false
  }, {
    name: 'Moved',
    itemType: 'radio',
    value: false,
    status: false
  }, {
    name: 'Removed',
    itemType: 'radio',
    value: false,
    status: false
  }, {
    name: 'Replaced',
    itemType: 'radio',
    value: false,
    status: false
  }]
}, {
  name: 'Fill/Verify full tank of fuel (placement of appropriate weights, if required)',
  itemType: 'checkbox',
  status: false,
  value: false,
  subItems: []
}, {
  name: 'Correct/Verify LF tire pressure',
  itemType: 'paragraph',
  status: false,
  value: false,
  subItems: []
}, {
  name: 'Take photo of LF tire',
  itemType: 'checkbox',
  status: false,
  value: false,
  subItems: []
}, {
  name: 'Correct/Verify LR tire pressure',
  itemType: 'paragraph',
  status: false,
  value: false,
  subItems: []
}, {
  name: 'Take photo of LR tire',
  itemType: 'checkbox',
  status: false,
  value: false,
  subItems: []
}, {
  name: 'Correct/Verify RF tire pressure',
  itemType: 'paragraph',
  status: false,
  value: false,
  subItems: []
}, {
  name: 'Take photo of RF tire',
  itemType: 'checkbox',
  status: false,
  value: false,
  subItems: []
}, {
  name: 'Take photo of RR tire',
  itemType: 'checkbox',
  status: false,
  value: false,
  subItems: []
}, {
  name: 'Complete Pre-Calibration Health Check Scan',
  itemType: 'checkbox',
  status: false,
  value: false,
  subItems: []
}, {
  name: 'Empty/Verify all vehicle contents',
  itemType: 'checkbox',
  status: false,
  value: false,
  subItems: []
}, {
  name: 'Compete Alignment Quick Check, save report to file (forward facing calibrations)',
  itemType: 'checkbox',
  status: false,
  value: false,
  subItems: []
}, {
  name: 'Complete Calibration, save report to file',
  itemType: 'checkbox',
  status: false,
  value: false,
  subItems: []
}, {
  name: 'Test Drive, verify all Vehicle Safety Systems function as engineered',
  itemType: 'checkbox',
  status: false,
  value: false,
  subItems: []
}, {
  name: 'Complete Post Calibration Health Check Scan, save report to file',
  itemType: 'checkbox',
  status: false,
  value: false,
  subItems: []
}, {
  name: 'Attach wheel alignment and calibration/scan reports',
  itemType: 'checkbox',
  status: false,
  value: false,
  subItems: []
}];
export var VEHICLE_STATUS = {
  APPROVED: 'approved',
  DECLINED: 'declined',
  UNDECIDED: 'undecided'
};