import { get } from 'lodash';
import { SERVER_DATA } from "../../../core/constants";

var formatUrl = function formatUrl(url) {
  var newUrl = url && url.charAt(0) === '/' ? url.substring(1, url.length) : url;
  return "".concat(SERVER_DATA).concat(newUrl);
};

export var getPhotos = function getPhotos() {
  var photos = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  return (photos || []).map(function (_ref) {
    var name = _ref.name,
        url = _ref.url;
    return {
      fileName: name,
      path: formatUrl(url)
    };
  });
};
export var getPhotosCaradas = function getPhotosCaradas() {
  var vehicles = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  if (vehicles.length <= 0) return [];
  var photos = [];
  vehicles.forEach(function (_ref2) {
    var carADAS = _ref2.carADAS;
    get(carADAS, 'photos', []).forEach(function (_ref3) {
      var name = _ref3.name,
          url = _ref3.url;
      photos.push({
        fileName: name,
        path: formatUrl(url)
      });
    });
  });
  return photos;
};
export var getDocuments = function getDocuments(vehicles) {
  if (vehicles.length <= 0) return [];
  var documents = [];
  vehicles.forEach(function (_ref4) {
    var carADAS = _ref4.carADAS;
    var vehicleDocument = get(carADAS, 'documents', []);
    vehicleDocument.forEach(function (doc) {
      (doc.files || []).forEach(function (_ref5) {
        var fileURL = _ref5.fileURL;
        documents.push({
          fileName: doc.name,
          path: formatUrl(fileURL)
        });
      });
    });
  });
  return documents;
};