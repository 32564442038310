import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import HttpRequest from "../../utils/HttpRequest";
import * as routesUtils from "../../utils/RoutesUtils";
import { API } from "../../core/app.routes";
var API_CALL_GET_COST_OF_GOODS = {
  call: 'getCostOfGoodsAccounts'
};
var API_CALL_GET_EXPENSE = {
  call: 'getExpenseAccounts'
};
var API_CALL_GET_CLASSES = {
  call: 'getClasses'
};
var API_CALL_GET_TAX_CODES = {
  call: 'getTaxCodes'
};
var API_CALL_GET_COMPANY_INFO = {
  call: 'getCompanyInfo'
};
/**
 * @function getQuickBookSettings
 * @param {object} qbQueryParam - quickBooks query parameter
 * @param {object} companyID - Company ID
 * @returns {Promise} - Promise Object returned by the server
 */

var getQuickBookSettings = function getQuickBookSettings(qbQueryParam, companyID) {
  var path = routesUtils.getApiPath(API.QUICKBOOKS_SETTINGS, _objectSpread(_objectSpread({}, qbQueryParam), companyID));
  return HttpRequest.get(path);
};

export { API_CALL_GET_COST_OF_GOODS, API_CALL_GET_EXPENSE, API_CALL_GET_CLASSES, API_CALL_GET_TAX_CODES, API_CALL_GET_COMPANY_INFO };
export default getQuickBookSettings;