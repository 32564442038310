import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

/* eslint-disable arrow-body-style */
import moment from 'moment';
var styleColumnBar = {
  backgroundColor: '#00ABF8',
  borderColor: '#00ABF8',
  borderWidth: 1
};
export var chart = {
  options: {
    type: 'bar',
    data: {
      labels: [],
      datasets: [_objectSpread({
        data: []
      }, styleColumnBar)]
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        display: false
      },
      scales: {
        xAxes: [{
          barPercentage: 0.25,
          gridLines: {
            display: false
          }
        }],
        yAxes: [{
          ticks: {
            beginAtZero: true,
            padding: 10
          },
          gridLines: {
            drawBorder: false
          }
        }]
      }
    }
  }
};

var renderDate = function renderDate(records) {
  var item = {
    label: '',
    value: 0
  };
  records.forEach(function (record) {
    if (item.label === '') {
      var dateCreated = new Date(record.dateCreated);
      item.label = dateCreated.getDate();
    }

    item.value += record.total;
  });
  return [item];
};

var renderWeek = function renderWeek(records) {
  var items = [{
    id: 0,
    label: 'SUN',
    value: 0
  }, {
    id: 1,
    label: 'MON',
    value: 0
  }, {
    id: 2,
    label: 'TUE',
    value: 0
  }, {
    id: 3,
    label: 'WED',
    value: 0
  }, {
    id: 4,
    label: 'THU',
    value: 0
  }, {
    id: 5,
    label: 'FRI',
    value: 0
  }, {
    id: 6,
    label: 'SAT',
    value: 0
  }];
  records.forEach(function (record) {
    var dateCreated = new Date(record.dateCreated);
    var day = dateCreated.getDay();
    var item = items.find(function (i) {
      return i.id === day;
    });
    if (item) item.value += record.total;
  });
  return items;
};

var renderMonth = function renderMonth(records) {
  var items = [];

  for (var i = 1; i < 32; i++) {
    items.push({
      id: i,
      label: i,
      value: 0
    });
  }

  records.forEach(function (record) {
    var dateCreated = new Date(record.dateCreated);
    var date = dateCreated.getDate();
    var item = items.find(function (i) {
      return i.id === date;
    });
    item.value += record.total;
  });
  return items;
};

var renderYear = function renderYear(records) {
  var items = [{
    id: 0,
    label: 'JAN',
    value: 0
  }, {
    id: 1,
    label: 'FEB',
    value: 0
  }, {
    id: 2,
    label: 'MAR',
    value: 0
  }, {
    id: 3,
    label: 'APR',
    value: 0
  }, {
    id: 4,
    label: 'MAY',
    value: 0
  }, {
    id: 5,
    label: 'JUN',
    value: 0
  }, {
    id: 6,
    label: 'JUL',
    value: 0
  }, {
    id: 7,
    label: 'AUG',
    value: 0
  }, {
    id: 8,
    label: 'SEP',
    value: 0
  }, {
    id: 9,
    label: 'OCT',
    value: 0
  }, {
    id: 10,
    label: 'NOV',
    value: 0
  }, {
    id: 11,
    label: 'DEC',
    value: 0
  }];
  records.forEach(function (record) {
    var dateCreated = new Date(record.dateCreated);
    var month = moment(dateCreated).get('month');
    var item = items.find(function (i) {
      return i.id === month;
    });
    item.value += record.total;
  });
  return items;
};

var renderAllTime = function renderAllTime(records) {
  var items = [{
    id: 2015,
    label: '2015',
    value: 0
  }, {
    id: 2016,
    label: '2016',
    value: 0
  }, {
    id: 2017,
    label: '2017',
    value: 0
  }, {
    id: 2018,
    label: '2018',
    value: 0
  }, {
    id: 2019,
    label: '2019',
    value: 0
  }];
  var fiveTotal = 0;
  var sixTotal = 0;
  var sevenTotal = 0;
  var eightTotal = 0;
  var nineTotal = 0;
  records.forEach(function (record) {
    var dateCreated = new Date(record.dateCreated);
    var year = moment(dateCreated).get('year');

    switch (year) {
      case 2015:
        var fifthteen = items.find(function (item) {
          return item.id === year;
        });
        fiveTotal += record.total;
        fifthteen.value = fiveTotal;
        break;

      case 2016:
        var sixteen = items.find(function (item) {
          return item.id === year;
        });
        sixTotal += record.total;
        sixteen.value = sixTotal;
        break;

      case 2017:
        var seventeen = items.find(function (item) {
          return item.id === year;
        });
        sevenTotal += record.total;
        seventeen.value = sevenTotal;
        break;

      case 2018:
        var eighteen = items.find(function (item) {
          return item.id === year;
        });
        eightTotal += record.total;
        eighteen.value = eightTotal;
        break;

      case 2019:
        var nineteen = items.find(function (item) {
          return item.id === year;
        });
        nineTotal += record.total;
        nineteen.value = nineTotal;
        break;
    }
  });
  return items;
};

var renderCustom = function renderCustom(records) {
  var items = [];

  for (var i = 1; i < 32; i++) {
    items.push({
      id: i,
      label: i,
      value: 0
    });
  }

  records.forEach(function (record) {
    var dateCreated = new Date(record.dateCreated);
    var date = dateCreated.getDate();
    var item = items.find(function (i) {
      return i.id === date;
    });
    item.value += record.total;
  });
  return items;
};

export var formatDataToChart = function formatDataToChart(records, range) {
  switch (range) {
    case 1:
    case 2:
      return renderDate(records);

    case 3:
    case 4:
      return renderWeek(records);

    case 5:
    case 6:
      return renderMonth(records);

    case 7:
    case 8:
      return renderYear(records);

    case 9:
      return renderAllTime(records);

    case 10:
      return renderAllTime(records);

    default:
      return renderAllTime(records);
  }
};
export var convertDataToChart = function convertDataToChart(records, range) {
  var items = formatDataToChart(records, range);
  var data = {
    labels: [],
    datasets: [_objectSpread({
      data: []
    }, styleColumnBar)]
  };
  items.forEach(function (item) {
    if (item.value > 0) {
      data.labels.push(item.label);
      data.datasets.forEach(function (dataset) {
        dataset.data.push(item.value.toFixed(2));
      });
    }
  });
  return data;
};