import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import HttpRequest from "../utils/HttpRequest";
import * as routesUtils from "../utils/RoutesUtils";
import { API } from "../core/app.routes";
export function getInvoices(params) {
  var path = routesUtils.getApiPath(API.INVOICES);
  return HttpRequest.post(path, params);
}
export function getInvoice(params) {
  var path = routesUtils.getApiPath(API.INVOICE_DETAIL, params);
  return HttpRequest.get(path);
}
export function sendInvoiceMail(body) {
  var params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var path = routesUtils.getApiPath(API.SEND_INVOICE_EMAIL, params);
  return HttpRequest.post(path, body);
}
export function addToQB(invoice, params) {
  var path = routesUtils.getApiPath(API.ADD_TO_QB, params);
  return HttpRequest.post(path, invoice);
} // THIS DOESNT WORK!!!!!!! DO NOT CHANGE IT BACK!!!!!!!!!
// export function vendorBillQB(invoice, params) {
// 	const path = routesUtils.getApiPath(API.VENDOR_BILL_QB, {companyID: params.companyID});
// 	delete params.companyID;
// 	return HttpRequest.post(path, params);
// }
// THIS IS CORRECT. DO NOT REMOVE

export function vendorBillQB(invoice, params) {
  var path = routesUtils.getApiPath(API.VENDOR_BILL_QB, params);
  return HttpRequest.post(path, invoice);
}
export function exportInvoices(body) {
  var params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var path = routesUtils.getApiPath(API.EXPORT_INVOICES, params);
  return HttpRequest.post(path, body);
}
export function downloadPDF(invoiceID) {
  var params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var path = routesUtils.getApiPath(API.CALL_PRINTER, _objectSpread(_objectSpread(_objectSpread({}, {
    invoiceID: invoiceID
  }), params), {
    background: true
  }));
  return HttpRequest.get("".concat(path));
}
export function downloadPDFChecklist(invoiceID) {
  var params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var path = routesUtils.getApiPath(API.CALL_PRINTER_INVOICE_CHECKLIST, _objectSpread(_objectSpread(_objectSpread({}, {
    invoiceID: invoiceID
  }), params), {
    background: true
  }));
  return HttpRequest.get("".concat(path));
}
export function getJobId(id) {
  var path = routesUtils.getApiPath(API.GET_JOB);
  return HttpRequest.get(path + id);
}
export function editInvoice(invoice) {
  var path = routesUtils.getApiPath(API.INVOICE_DETAIL, {
    invoiceID: invoice._id
  });
  return HttpRequest.put(path, invoice);
}
export function pdfPolling(path) {
  return HttpRequest.get(path);
}
export function getEmails(params) {
  var path = routesUtils.getApiPath(API.EMAIL, params);
  return HttpRequest.get(path);
} // init func to fix lint because it was called from action

export function callPrinter(invoice, cb) {
  var err = null;
  var result = {};
  cb(err, result);
}
export function getInvoiceDetail(invoiceID) {
  var path = routesUtils.getApiPath(API.INVOICE_DETAIL, {
    invoiceID: invoiceID
  });
  return HttpRequest.get(path);
}