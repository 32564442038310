export default {
  payLoad: {
    loading: false
  },
  errorReducer: {},
  mainReducer: {
    currentUser: {},
    company: {},
    isValidEmail: false,
    emailIsExists: false,
    success: false,
    error: {},
    subscription: {},
    incomingInvoice: {},
    signedResponse: {},
    uploadedFile: false,
    importedFile: false
  },
  matrixReducer: {
    matrices: {}
  },
  userReducer: {
    users: [],
    assignableUsers: [],
    user: {},
    editSuccess: false
  },
  clientReducer: {
    clients: [],
    client: {},
    editSuccess: false,
    success: false,
    error: {},
    reminders: [],
    reminder: {},
    regardingVehicles: []
  },
  reportingReducer: {
    reports: [],
    success: false,
    error: {}
  },
  reportReducer: {
    reports: [],
    success: false,
    error: {}
  },
  paymentReducer: {
    payments: [],
    paymentSummary: {},
    success: false,
    error: {},
    cardReaderQuote: {},
    lendingView: true
  },
  invoiceReducer: {
    invoices: [],
    invoice: {},
    success: false,
    error: {}
  },
  estimateReducer: {
    estimates: [],
    success: false,
    error: {}
  },
  estimateDetailReducer: {
    estimate: {},
    client: {},
    success: false,
    error: {}
  },
  exportReducer: {
    exports: [],
    success: false,
    error: {}
  },
  payableReducer: {
    payables: [],
    success: false,
    error: {}
  },
  groupsReducer: {
    groups: {},
    group: {},
    success: false,
    error: {}
  },
  plaid: {
    accessToken: '',
    itemId: ''
  },
  cogsReducer: {
    cogs: {
      items: []
    }
  },
  workflowReducer: {
    workflow: {
      services: []
    },
    isShowModalNewestData: false,
    messageType: 1
  },
  stagesReducer: {
    vehicles: [],
    estimates: [],
    invoices: [],
    workOrders: [],
    vehicle: {}
  },
  timerReducer: {
    totalStageMS: 0,
    totalTimeMS: 0,
    services: [],
    stages: [],
    lastStage: ''
  }
};