import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { ACTION_REQUEST, PUBLIC_TOKEN_SUCCESS, PUBLIC_TOKEN_FAILURE } from "../core/actionTypes";
import { catchError } from "../core/error.handler";
import * as plaidServices from "../services/plaidServices";
export var authPlaid = function authPlaid(body) {
  return function (dispatch, getState) {
    var company = _objectSpread({}, getState().main.company);

    dispatch({
      type: ACTION_REQUEST
    });
    return plaidServices.authPlaid(body, company._id).then(function (result) {
      dispatch({
        type: PUBLIC_TOKEN_SUCCESS // accessToken: result.data.access_token,
        // itemId: result.data.item_id,

      });
      return result;
    })["catch"](function (error) {
      catchError(error, dispatch, PUBLIC_TOKEN_FAILURE);
    });
  };
};