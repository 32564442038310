import _ from 'lodash';
import * as types from "../core/actionTypes"; // import {necessaryDataIsProvidedToCalculateSavings, calculateSavings} from '../utils/fuelSavings';

import initialState from "./initialState";
import { handleCRUDAction } from "../core/common"; // IMPORTANT: Note that with Redux, state should NEVER be changed.
// State is considered immutable. Instead,
// create a copy of the state passed and set new values on the copy.
// Note that I'm using Object.assign to create a copy of current state
// and update values on the copy.

export default function reportingReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState.reportingReducer;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  var newState;

  var defaultState = _.cloneDeep(initialState.reportingReducer);

  switch (action.type) {
    case types.GET_REPORTS_SUCCESS:
      {
        if (action.redirect) {
          return _.assign({}, state, {
            reports: action.reports,
            redirect: action.redirect
          });
        }

        return _.assign({}, state, {
          reports: action.reports
        });
      }

    case types.GET_REPORTS_FAILURE:
      {
        newState = handleCRUDAction(state, action);
        return newState;
      }

    case types.SEND_EMAIL_SUCCESS:
      {
        return _.assign({}, state, {
          emailSuccess: true
        });
      }

    case types.SEND_EMAIL_FAILURE:
      {
        newState = handleCRUDAction(state, action);
        return newState;
      }
    //-----common-------//

    case types.RESET_STATE:
      {
        var stateClone = _.assign({}, state);

        _.forEach(action.fields, function (field) {
          stateClone[field] = defaultState[field];
        });

        return stateClone;
      }

    case types.RESET_ERROR:
      {
        return _.assign({}, state, {
          error: {}
        });
      }

    case types.LOGOUT:
      {
        return defaultState;
      }

    default:
      return state;
  }
}