import { API, URL } from "../core/app.routes";
import config from "../core/config";
/**
 * pathsCollection: URL or API. See core/common/app.routes.js for details
 */

function getPathTemplate(route, pathsCollection) {
  if (route.parent) {
    var path = "".concat(getPathTemplate(pathsCollection[route.parent], pathsCollection), "/").concat(route.path);
    return path;
  }

  return route.path;
}
/**
 * pathsCollection: URL or API. See core/common/app.routes.js for details
 */


function getPath(route, params, pathsCollection) {
  var path = getPathTemplate(route, pathsCollection);
  var queryArray = [];
  var filterArray = [];
  var selectArray = [];
  var routeParams = [];
  var rParams = path.match(/:\w+/g);

  if (rParams) {
    rParams.forEach(function (param) {
      routeParams.push(param.substring(1));
    });
  }

  if (params && Object.entries(params).length > 0) {
    Object.keys(params).forEach(function (key) {
      if (routeParams.indexOf(key) > -1) {
        path = path.replace(":".concat(key), params[key]);
      } else if (key === '_filter') {
        params[key].forEach(function (subKey) {
          filterArray.push("filter[".concat(subKey.key, "]=").concat(subKey.value));
        });
        queryArray.push(filterArray.join('&')); // } else if (key === 'select') {
        // 	params[key].forEach((subKey) => {
        // 		selectArray.push(`select[${subKey.key}]=${subKey.value}`);
        // 	});

        queryArray.push(selectArray.join('&'));
      } else {
        queryArray.push("".concat(key, "=").concat(encodeURIComponent(params[key])));
      }
    });
    path += queryArray.length ? "?".concat(queryArray.join('&')) : '';
  }

  return path;
}

export function getFullApiPath(route, params) {
  return config.mtbAPI + getPath(route, params, API);
}
export function getApiPath(route, params) {
  return getPath(route, params, API);
}
export function getUrlPath(route, params) {
  return getPath(route, params, URL);
}