/* eslint-disable import/prefer-default-export */
import * as types from "../core/actionTypes";
import * as cogsServices from "../services/cogsServices";
import { catchError } from "../core/error.handler";
export var getCogs = function getCogs() {
  return function (dispatch) {
    dispatch({
      type: types.COGS_GET_REQUEST
    });
    cogsServices.getCogs().then(function (result) {
      return dispatch({
        type: types.COGS_GET_SUCCESS,
        payload: result.data
      });
    })["catch"](function (error) {
      return catchError(error, dispatch, types.COGS_GET_FAILURE);
    });
  };
};
export var updateCogs = function updateCogs(cogs) {
  return function (dispatch) {
    dispatch({
      type: types.COGS_UPDATE_REQUEST
    });
    cogsServices.editCogs(cogs).then(function (result) {
      return dispatch({
        type: types.COGS_UPDATE_SUCCESS,
        payload: result.data
      });
    })["catch"](function (error) {
      return catchError(error, dispatch, types.COGS_UPDATE_FAILURE);
    });
  };
};
export var addCogs = function addCogs(cogs) {
  return function (dispatch) {
    dispatch({
      type: types.COGS_ADD_REQUEST
    });
    cogsServices.addCogs(cogs).then(function (result) {
      return dispatch({
        type: types.COGS_ADD_SUCCESS,
        payload: result.data
      });
    })["catch"](function (error) {
      return catchError(error, dispatch, types.COGS_ADD_FAILURE);
    });
  };
};