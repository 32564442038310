import HttpRequest from "../utils/HttpRequest";
import * as routesUtils from "../utils/RoutesUtils";
import { API } from "../core/app.routes";
export function getExports(params) {
  var path = routesUtils.getApiPath(API.EXPORTS, params);
  return HttpRequest.get(path);
}
export function purchaseCredits(data) {
  var path = routesUtils.getApiPath(API.ADD_EXPORT);
  return HttpRequest.post(path, data);
}