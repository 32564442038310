import { useContext } from 'react';
import { ReactReduxContext } from 'react-redux';
export var useCurrentUser = function useCurrentUser() {
  var _useContext = useContext(ReactReduxContext),
      store = _useContext.store;

  var _store$getState = store.getState(),
      currentUser = _store$getState.main.currentUser;

  return {
    currentUser: currentUser
  };
};
export default useCurrentUser;