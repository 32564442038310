import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import * as types from "../core/actionTypes";
import { catchError } from "../core/error.handler";
import * as matrixServices from "../services/matrixServices";
import * as mainServices from "../services/mainServices";
export var jobPolling = function jobPolling(jobID, allowedAttempts) {
  var milliseconds = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 3000;
  var attempts = 0;
  return new Promise(function (resolve, reject) {
    var polling = setInterval(function () {
      attempts++;
      return mainServices.getJobId(jobID).then(function (response) {
        // Stop polling after certain number of attemps
        if (attempts > allowedAttempts) {
          clearInterval(polling);
          reject(new Error());
        } // Document is ready when server sets result.data.job.status == false


        if (!response.data.job.status) {
          clearInterval(polling);
          resolve(response);
        }

        return response;
      });
    }, milliseconds);
  });
};
export function getMatrices(params, cb) {
  return function (dispatch) {
    dispatch({
      type: types.ACTION_REQUEST
    });
    matrixServices.getMatrices(params).then(function (result) {
      var matrices = {};

      if (Array.isArray(result.data)) {
        result.data.forEach(function (matrix) {
          matrices[matrix._id] = matrix;
        });
        dispatch({
          type: types.GET_MATRICES_SUCCESS,
          matrices: matrices
        });
        if (cb !== undefined) return cb(result.data);
        return true;
      }

      dispatch({
        type: types.GET_MATRIX_SUCCESS
      });
      if (cb !== undefined) return cb(result.data);
      return true;
    })["catch"](function (error) {
      return catchError(error, dispatch, types.GET_MATRICES_FAILURE);
    });
  };
}
export function deleteMatrix(matrix, cb) {
  return function (dispatch, getState) {
    var matrices = _objectSpread({}, getState().matrix.matrices);

    dispatch({
      type: types.ACTION_REQUEST
    });
    matrixServices.deleteMatrix(matrix._id).then(function (result) {
      delete matrices[matrix._id];
      dispatch({
        type: types.DELETE_MATRIX_SUCCESS,
        matrices: matrices
      });
      if (cb !== undefined) cb(result.data);
    })["catch"](function (error) {
      return catchError(error, dispatch, types.DELETE_MATRIX_FAILURE);
    });
  };
}
export function editMatrix(matrix, cb) {
  return function (dispatch) {
    dispatch({
      type: types.ACTION_REQUEST
    });
    matrixServices.editMatrix(matrix).then(function (result) {
      dispatch({
        type: types.EDIT_PRICE_A_DENT_MATRIX_SUCCESS
      });
      if (cb !== undefined) cb(result.data);
    })["catch"](function (error) {
      return catchError(error, dispatch, types.EDIT_PRICE_A_DENT_MATRIX_FAILURE);
    });
  };
}
export function uploadHailMatrix(matrixInfo, file) {
  return function (dispatch) {
    dispatch({
      type: types.ACTION_REQUEST
    });
    return matrixServices.prepareMatrix(matrixInfo).then(function (result) {
      var csvFile = _objectSpread({}, file);

      csvFile.name = result.data.Key;
      return mainServices.uploadCSV(file, result.data.signed_request).then(function () {
        return result;
      })["catch"](function (error) {
        throw error;
      });
    }).then(function (result) {
      return jobPolling(result.data.job, 10);
    }).then(function (response) {
      dispatch({
        type: types.UPLOAD_HAIL_MATRIX_SUCCESS
      });
      return response;
    })["catch"](function (error) {
      catchError(error, dispatch, types.UPLOAD_HAIL_MATRIX_FAILURE);
      throw error;
    });
  };
}
export function addPriceADentMatrix(matrix, cb) {
  return function (dispatch) {
    dispatch({
      type: types.ACTION_REQUEST
    });
    matrixServices.prepareMatrix(matrix).then(function (result) {
      dispatch({
        type: types.ADD_PRICE_A_DENT_MATRIX_SUCCESS
      });
      if (cb !== undefined) cb(result.data);
    })["catch"](function (error) {
      return catchError(error, dispatch, types.ADD_PRICE_A_DENT_MATRIX_FAILURE);
    });
  };
}