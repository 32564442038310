import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import _ from 'lodash';
import * as types from "../core/actionTypes";
import initialState from "./initialState";
import { handleCRUDAction } from "../core/common"; // IMPORTANT: Note that with Redux, state should NEVER be changed.
// State is considered immutable. Instead,
// create a copy of the state passed and set new values on the copy.
// Note that I'm using Object.assign to create a copy of current state
// and update values on the copy.

export default function estimateReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState.estimateReducer;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  var defaultState = _.cloneDeep(initialState.estimateReducer);

  switch (action.type) {
    case types.GET_LEADS_ESTIMATE_SUCCESS:
      {
        return _.assign({}, state, {
          lead: action.lead
        });
      }

    case types.GET_ESTIMATES_SUCCESS:
      {
        return _.assign({}, state, {
          estimates: action.estimates,
          total: action.total
        });
      }

    case types.GET_ESTIMATES_FAILURE:
    case types.UPDATE_ESTIMATE_FAILURE:
    case types.UPDATE_ESTIMATE_SUCCESS:
      {
        // const newState = handleCRUDAction(state, action);
        // return newState;
        return _objectSpread(_objectSpread({}, state), {}, {
          estimates: state.estimates.map(function (estimate) {
            if (action.payload && estimate._id === action.payload._id) {
              estimate = action.payload;
            }

            return estimate;
          })
        });
      }

    case types.ESTIMATE_DOWNLOAD_FAILURE:
    case types.ESTIMATE_DOWNLOAD_SUCCESS:
      {
        var newState = handleCRUDAction(state, action);

        if (newState.success) {
          newState.printable = action.printable;
        }

        return newState;
      }

    case types.RESET_STATE:
      {
        var stateClone = _.assign({}, state);

        _.forEach(action.fields, function (field) {
          stateClone[field] = defaultState[field];
        });

        return stateClone;
      }

    case types.EXPORT_ESTIMATES_VEHICLES_CSV_FAILURE:
    case types.EXPORT_ESTIMATES_VEHICLES_CSV_SUCCESS:
      {
        var _newState = handleCRUDAction(state, action);

        return _newState;
      }

    case types.EXPORT_ERAC_FAILURE:
      {
        var error = action.error;
        return _.assign({}, state, {
          error: error
        });
      }

    case types.SEND_ESTIMATE_EMAIL_SUCCESS:
    case types.SEND_ESTIMATE_EMAIL_FAILURE:
    case types.EXPORT_ERAC_SUCCESS:
      {
        var _newState2 = handleCRUDAction(state, action);

        return _newState2;
      }

    case types.RESET_ERROR:
      {
        return _.assign({}, state, {
          error: {}
        });
      }

    case types.LOGOUT:
      {
        return defaultState;
      }

    case types.EXPORT_ESTIMATES_CSV_FAILURE:
    case types.EXPORT_ESTIMATES_CSV_SUCCESS:
    default:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          success: false,
          error: null
        });
      }
  }
}