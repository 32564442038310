import HttpRequest from "../utils/HttpRequest";
import * as routesUtils from "../utils/RoutesUtils";
import { API } from "../core/app.routes";
export function getCogs() {
  var path = routesUtils.getApiPath(API.COGS);
  return HttpRequest.get("".concat(path));
}
export function editCogs(cogs) {
  var path = routesUtils.getApiPath(API.COGS);
  return HttpRequest.put(path, cogs);
}
export function addCogs(cogs) {
  var path = routesUtils.getApiPath(API.COGS);
  return HttpRequest.post(path, cogs);
}