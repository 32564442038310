import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import _ from 'lodash';
import * as types from "../core/actionTypes";
import initialState from "./initialState";
import { handleCRUDAction } from "../core/common"; // IMPORTANT: Note that with Redux, state should NEVER be changed.
// State is considered immutable. Instead,
// create a copy of the state passed and set new values on the copy.
// Note that I'm using Object.assign to create a copy of current state
// and update values on the copy.

export default function paymentReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState.paymentReducer;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  var defaultState = _.cloneDeep(initialState.paymentReducer);

  switch (action.type) {
    case types.GET_PAYMENTS_SUCCESS:
      {
        return _.assign({}, state, {
          payments: action.payments
        });
      }

    case types.GET_PAYMENT_SUCCESS:
      {
        return _.assign({}, state, {
          payment: action.payment
        });
      }

    case types.GET_PAYMENT_SUMMARY_SUCCESS:
      {
        return _.assign({}, state, {
          paymentSummary: action.paymentSummary
        });
      }

    case types.GET_PAYMENT_SUMMARY_FAILURE:
      {
        var newState = handleCRUDAction(state, action);
        return newState;
      }

    case types.RESET_PAYMENT:
      {
        return _objectSpread(_objectSpread({}, state), {
          payment: {}
        });
      }

    case types.GET_PAYMENTS_FAILURE:
      {
        var _newState = handleCRUDAction(state, action);

        return _newState;
      }

    case types.DELETE_PAYMENT_SUCCESS:
      {
        return _objectSpread(_objectSpread({}, state), {
          payments: action.payments
        });
      }

    case types.DELETE_PAYMENT_FAILURE:
      {
        return _objectSpread(_objectSpread({}, state), {
          error: action.error
        });
      }

    case types.STRIPE_IDENTITY_REQUEST_SUCCESS:
      {
        return _objectSpread(_objectSpread({}, state), {
          responseURL: action.url
        });
      }

    case types.STRIPE_CONNECT_SIGNUP_REQUEST_SUCCESS:
      {
        return _objectSpread(_objectSpread({}, state), {
          responseURL: action.url
        });
      }

    case types.QUOTE_CARD_READER_ORDER_SUCCESS:
      {
        return _objectSpread(_objectSpread({}, state), {
          cardReaderQuote: action.cardReaderQuote
        });
      }

    case types.HIDE_LENDING_VIEWS:
      {
        return _objectSpread(_objectSpread({}, state), {
          lendingView: false
        });
      }

    case types.RESET_STATE:
      {
        var stateClone = _.assign({}, state);

        _.forEach(action.fields, function (field) {
          stateClone[field] = defaultState[field];
        });

        return stateClone;
      }

    case types.RESET_PAYMENT_STATE:
      {
        return defaultState;
      }

    case types.RESET_ERROR:
      {
        return _objectSpread(_objectSpread({}, state), {
          error: {}
        });
      }

    case types.LOGOUT:
      {
        return defaultState;
      }

    default:
      return _objectSpread(_objectSpread({}, state), {}, {
        success: false,
        error: null
      });
  }
}