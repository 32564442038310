import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

// -------- server api -------------------//
var version = 'v6'; // -----------------------

export var DEFAULT_USER = {
  LABOR_RATE: '50',
  LABOR_RATE_RI: '50'
}; // -------- server read file pdf-------------------//

export var PAGE_SIZE = [10, 20, 50, 100];
export var PAGE_SIZE_DEFAULT = 100;
var defaults = {
  DEFAULT_USER: {
    LABOR_RATE: '50',
    LABOR_RATE_RI: '50'
  },
  PAGE_SIZE: [10, 20, 50, 100],
  PAGE_SIZE_DEFAULT: 100
};
var development = {
  stripeKey: 'pk_test_oD6WuvugThsKEU04kft0VQUl',
  stripeClientID: 'ca_FarQyp2V0BSRnNCuWrN0x9oufQJzv2hZ',
  mtbAPI: "https://techbooksusdev.herokuapp.com/api/".concat(version, "/") // mtbAPI: `http://localhost:5000/api/${version}/`,

};
var production = {
  stripeKey: 'pk_live_mOKW0Ixmf5dkosFCZQDHd3ok',
  stripeClientID: 'ca_FarQvKHpURWIEHUti9SAcFwzYJWP1HsX',
  mtbAPI: "https://app.mobiletechrx.com/api/".concat(version, "/")
};
var config = {
  development: _objectSpread(_objectSpread({}, development), defaults),
  production: _objectSpread(_objectSpread({}, production), defaults),
  local: _objectSpread(_objectSpread(_objectSpread({}, development), {
    mtbAPI: "http://localhost:3000/api/".concat(version, "/")
  }), defaults)
}[process.env.NODE_ENV || 'development'];
export default config;