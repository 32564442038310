export default {
  ENG: {
    MOBILE_TECH_RX_ADMIN_PORTAL: 'Mobile Tech RX Admin Portal',
    LOGIN: 'Login',
    LOGGING_IN: 'Logging in',
    MOBILE_TECH_RX: 'Mobile Tech RX',
    BACK_OFFICE_ADMIN_PORTAL_LOGIN: 'Back Office Admin Portal Login',
    EMAIL: 'Email',
    PASSWORD: 'Password',
    FORGOT_PASSWORD: 'Forgot Password'
  }
};