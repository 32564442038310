export var SERVICES_VALUES_ENUM = {
  removeInstall: 'Remove Install',
  parts: 'Parts',
  misc: 'Misc',
  pdr: 'PDR',
  hail: 'Hail',
  priceADent: 'Price A Dent',
  paintAndBody: 'Paint And Body',
  wheels: 'Wheels',
  detail: 'Detail',
  ppfVinyl: 'PPF/Vinyl',
  interior: 'Interior',
  glass: 'Glass',
  tint: 'Tint',
  carADAS: 'CarADAS',
  caradas: 'CarADAS',
  "package": 'Package'
};
export var SERVICES_KEYS_ENUM = {
  detail: 'detail',
  misc: 'misc',
  pdr: 'pdr',
  hail: 'hail',
  priceADent: 'priceADent',
  "package": 'package'
};