export var API = {
  LOGIN: {
    path: 'sessions/admin'
  },
  USERS: {
    path: 'users'
  },
  USERS_PAGINATION: {
    path: 'users'
  },
  USER_DETAIL: {
    path: 'users/:userId'
  },
  USER_REVERIFY: {
    path: 'users/:userId/reverify'
  },
  ADMIN_USER_DETAIL: {
    path: 'users/:userId'
  },
  SUBCONTRACTOR_DETAIL: {
    path: 'users/:userId/subcontracts/:subID/'
  },
  CHECK_EMAIL: {
    path: 'check_email?name=email'
  },
  SIGNUP: {
    path: 'signup'
  },
  CLIENTS: {
    path: 'clients'
  },
  ENABLE: {
    path: 'clients/:client_id/enable-client-portal'
  },
  DISABLE: {
    path: 'clients/:client_id/disable-client-portal'
  },
  CLIENT_DETAIL: {
    path: ':clientId',
    parent: 'CLIENTS'
  },
  CLIENT_S3: {
    path: 'clients/:clientId/sign_s3'
  },
  SUBCONTRACTS: {
    path: 'subcontracts'
  },
  REPORTING_GET_SALES_DATA: {
    path: 'reporting/sales'
  },
  REPORTING_SALES_CHART_DATA: {
    path: 'reporting/sales'
  },
  REPORTING_GET_CLIENT_DATA: {
    path: 'reporting/clients'
  },
  REPORTS: {
    path: 'reports'
  },
  SEND_REPORT_EMAIL: {
    path: 'email/reports'
  },
  DISCONNECT_QB: {
    path: 'admin/company/disconnectInQB'
  },
  QUICKBOOKS_SETTINGS: {
    path: 'company/:companyID/quickbooks/settings'
  },
  ADD_TO_QB: {
    path: 'company/:companyID/quickbooks/addToQB'
  },
  VENDOR_BILL_QB: {
    path: 'company/:companyID/quickbooks/vendorBillQB'
  },
  CONNECT_TO_QUICKBOOKS: {
    path: 'company/:companyID/quickbooks/connect'
  },
  DISCONNECT_FROM_QUICKBOOKS: {
    path: 'company/:companyID/quickbooks/disconnect'
  },
  OATH2_CALLBACK: {
    path: 'company/:companyID/quickbooks/oath2-callback'
  },
  WISETACK_SIGNUP: {
    path: 'company/:companyID/wisetack/account'
  },
  INVOICES: {
    path: 'search/invoices'
  },
  LEADS_ESTIMATE: {
    path: 'leads/:leadID/estimate'
  },
  ESTIMATES: {
    path: 'search/estimates'
  },
  ESTIMATE_DETAIL: {
    path: 'estimates/:estimateID'
  },
  EXPORT_INVOICES: {
    path: 'invoices/export'
  },
  EXPORT_ESTIMATES: {
    path: 'estimates/export'
  },
  EXPORT_ESTIMATES_VEHICLES_CSV: {
    path: 'estimates/vehicles-export-csv'
  },
  ERAC_MAKE_ZIP: {
    path: 'estimates/eracmakezip'
  },
  ERAC_CHECK_STATUS: {
    path: 'estimates/eraccheckstatus'
  },
  CALL_PRINTER: {
    path: 'pdf/invoice/:invoiceID'
  },
  CALL_PRINTER_INVOICE_CHECKLIST: {
    path: 'pdf/invoice/caradas/:invoiceID'
  },
  CALL_PRINTER_ESTIMATE: {
    path: 'pdf/estimate/:estimateID'
  },
  CALL_PRINTER_SUPPLEMENT_ESTIMATE: {
    path: 'pdf/supplement/:estimateID'
  },
  CALL_PRINTER_PART_ESTIMATE: {
    path: 'pdf/parts/:estimateID'
  },
  GET_PDF_REPORTS: {
    path: 'pdf/reports/'
  },
  GET_JOB: {
    path: 'jobs/'
  },
  EMAIL: {
    path: '/email'
  },
  SEND_INVOICE_EMAIL: {
    path: 'email/invoice'
  },
  SEND_ESTIMATE_EMAIL: {
    path: 'email/estimate'
  },
  INVOICE_DETAIL: {
    path: 'invoices/:invoiceID'
  },
  EDIT_VEHICLE_ESTIMATE: {
    path: 'estimates/:estimate_id/vehicles/:vehicle_id'
  },
  UPDATE_VEHICLE_ESTIMATE: {
    path: 'estimates/:estimateID/vehicles/:vehicleID'
  },
  EXPORTS: {
    path: 'estify'
  },
  INVITEURL: {
    path: 'companies/inviteurl'
  },
  COMPANY: {
    path: 'company'
  },
  COMPANIES: {
    path: 'companies'
  },
  COMPANIES_DETAIL_SESSION: {
    path: 'sessions/companies/:companyID'
  },
  COMPANIES_DETAIL: {
    path: 'companies/:companyID'
  },
  STRIPE: {
    path: 'company/:companyID/stripe'
  },
  STRIPE_OAUTH: {
    path: 'company/:companyID/stripe/oauth'
  },
  STRIPE_LOGIN: {
    path: 'company/:companyID/stripe/loginLink'
  },
  STRIPE_IDENTITY: {
    path: 'company/identityVerification'
  },
  STRIPE_CONNECT_SIGNUP: {
    path: 'company/connect/signup'
  },
  PAYMENTS: {
    path: 'payments'
  },
  PAYMENT_SUMMARY: {
    path: 'payments/summary'
  },
  PAYMENT_REFUND: {
    path: 'payments/:paymentID/refund'
  },
  EDIT_PAYMENT: {
    path: 'payments/:paymentID'
  },
  SEND_PAYMENT_RECEIPT: {
    path: 'payments/:paymentID/sendReceipt'
  },
  UPLOAD_PAYMENT_EVIDENCE: {
    path: 'payments/:paymentID/upload-evidence'
  },
  STRIPE_HARDWARE_ORDER: {
    path: 'payments/hardwareOrder'
  },
  STRIPE_HARDWARE_ORDER_CONFIRMATION: {
    path: 'payments/hardwareOrder/purchase' // path: 'payments/hardwareOrder/:orderID/confirm',

  },
  SUBSCRIPTION: {
    path: 'subscription',
    parent: 'COMPANY'
  },
  ADD_EXPORT: {
    path: 'add-export',
    parent: 'COMPANY'
  },
  SIGN_S3: {
    path: 'company/:companyID/sign_s3'
  },
  UPDATE_PAYMENT: {
    path: 'company/:companyID/stripe/updatePayment'
  },
  ADD_NEW_PAYMENT: {
    path: 'company/:companyID/stripe/addNewPayment'
  },
  REMOVE_SOURCE: {
    path: 'company/:companyID/stripe/removeSource'
  },
  IMPORT_CSV: {
    path: 'estimates/import-csv'
  },
  EDIT_MATRICES: {
    path: 'matrices/:matrixID'
  },
  MATRICES: {
    path: 'matrices'
  },
  COGS: {
    path: 'cogs'
  },
  WORK_FLOW: {
    path: 'workflow'
  },
  STAGE: {
    path: 'workflow/stage'
  },
  INCOMING_INVOICE: {
    path: 'company/subscription/upcomingInvoice'
  },
  INCOMING_ESTIMATE: {
    path: 'company/subscription/upcomingInvoice'
  },
  TABLEAU_SSO: {
    path: 'company/tableauSSO'
  },
  WORK_ORDER: {
    path: 'workorders/'
  },
  WORK_ORDER_GET_ALL: {
    path: 'search/workOrders'
  },
  WORK_ORDER_GET_PDF: {
    path: 'workorders/:workorder_id/pdf'
  },
  WORK_ORDER_GET_DETAIL: {
    path: 'workorders/:workorder_id'
  },
  WORK_ORDER_SEND_EMAIL: {
    path: 'email/workorder'
  },
  GROUPS: {
    path: 'groups'
  },
  EDIT_GROUP: {
    path: 'groups/:groupID'
  },
  EDIT_GROUP_ADD_CLIENTS: {
    path: 'groups/:groupID/clients'
  },
  EDIT_GROUP_EDIT_CLIENT: {
    path: 'groups/:groupID/clients/:clientID'
  },
  EDIT_GROUP_EDIT_CLIENT_ADD_USERS: {
    path: 'groups/:groupID/clients/:clientID/users'
  },
  EDIT_GROUP_EDIT_CLIENT_EDIT_USER: {
    path: 'groups/:groupID/clients/:clientID/users/:userID'
  },
  GET_REMINDERS: {
    path: 'reminders'
  },
  CREATE_REMINDER: {
    path: 'reminders'
  },
  UPDATE_REMINDER: {
    path: 'reminders/:reminderID'
  },
  DELETE_REMINDER: {
    path: 'reminders/:reminderID'
  },
  REGARDING_VEHICLE: {
    path: 'search/invoices'
  },
  PLAID_PUBLIC_TOKEN: {
    path: 'company/:companyID/plaid/public_token'
  },
  WORKFLOW: {
    path: 'workflow'
  }
};
export var URL = {
  HOME: {
    path: ''
  },
  LOGIN: {
    path: 'login'
  },
  SIGNUP: {
    path: 'signup'
  },
  CLIENTS: {
    path: 'clients'
  },
  CLIENT_DETAIL: {
    path: ':clientId',
    parent: 'CLIENTS'
  },
  INVOICES: {
    path: 'invoices'
  },
  ESTIMATES: {
    path: 'invoices'
  },
  USERS: {
    path: 'users'
  },
  PAYABLES: {
    path: 'payables'
  },
  EXPORTS: {
    path: 'exports'
  },
  REPORTS: {
    path: 'reports'
  },
  WORKFLOW: {
    path: 'workflow'
  }
};